import { useQuery } from "react-query";
import { queryClient } from "../libs/react-query";
import { Club } from "../model/club";
import { clubApi, memberApi } from "../services/client";
import { useUserController } from "./user-controller";

export const clubListKey: string = "clubList";

export const useClubController = () => {
  const { user } = useUserController();

  const { data: clubList, isLoading } = useQuery<Club[]>({
    queryKey: [clubListKey],
    queryFn: async () => {
      return await clubApi.get();
    },
    enabled: !!user,
  });

  const post = async (name: string, currency: string) => {
    const sanitizedName = name.trim().replace(/[<>]/g, "");

    await clubApi.post({
      name: sanitizedName,
      currency,
    });

    queryClient.invalidateQueries(clubListKey);
  };

  const getRoles = async (idClub: string) => {
    const member = await memberApi.getCurrentMember(idClub);
    return member?.user_role_list ?? [];
  };

  const { data: roles } = useQuery({
    queryKey: [clubListKey, "roles"],
    queryFn: () => getRoles(clubList?.[0]._id!),
    enabled: !!user && !!clubList && clubList.length > 0,
  });

  return {
    clubList,
    isLoading,
    post,
    roles,
  };
};

import { IconLoader2 } from "@tabler/icons-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useReportIdController } from "../../../../controller/report-id-controller";
import { useI18n } from "../../../../lang/i18n";
import { Report } from "../../../../model/report";

export const ReportActions = ({
  debounceLoading,
  data,
  setData,
  imageBytes,
}: {
  debounceLoading: boolean;
  data: Report;
  setData: (data: Report) => void;
  imageBytes: ArrayBuffer | null;
}) => {
  const { idClub, idReport } = useParams();
  const { t } = useI18n();
  const navigate = useNavigate();

  const controller = useReportIdController(idClub, idReport);
  const [loadingSave, setLoadingSave] = useState(false);

  const loading = debounceLoading || loadingSave;

  return (
    <div className="grid grid-cols-2 md:flex md:flex-wrap gap-2">
      <button
        className="w-full md:w-auto bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition-colors"
        data-variant="text"
        onClick={() => {
          const json = JSON.stringify(data);

          console.log(data);

          const blob = new Blob([json], { type: "application/json" });

          const url = URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.href = url;
          a.download = "report.json";
          a.click();

          URL.revokeObjectURL(url);
        }}
      >
        {t("general.export")}
      </button>

      <button
        className="w-full md:w-auto bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition-colors"
        data-variant="text"
        onClick={() => {
          const input = document.createElement("input");
          input.type = "file";
          input.accept = ".json";
          input.onchange = function () {
            const file = input.files?.[0];
            if (!file) {
              return;
            }
            const reader = new FileReader();
            reader.onload = function () {
              const json = reader.result as string;

              let parsed: Report = JSON.parse(json);
              parsed._id = data._id;
              parsed.id_user = data.id_user;

              setData(parsed);
            };
            reader.readAsText(file);
          };
          input.click();
        }}
      >
        {t("general.import")}
      </button>

      <button
        className="w-full md:w-auto bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition-colors"
        onClick={() => {
          const printWindow = window.open("", "_blank");
          if (!printWindow) return;

          const img = new Image();
          img.src = URL.createObjectURL(new Blob([imageBytes!]));

          printWindow.document.write(`
            <html>
              <head>
                <title>${data.name || "Report"}</title>
                <style>
                  @page {
                    size: A4;
                    margin: 0;
                  }
                  img {
                    width: 100%;
                    height: auto;
                    max-width: 210mm;
                    max-height: 297mm;
                    margin: auto;
                    display: block;
                  }
                </style>
              </head>
              <body>
                <img src="${img.src}" onload="window.print();window.close();" />
              </body>
            </html>
          `);
          printWindow.document.close();
        }}
      >
        {t("general.print")}
      </button>

      <button
        className="w-full md:w-auto bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition-colors"
        onClick={async () => {
          const img = new Image();
          img.src = URL.createObjectURL(new Blob([imageBytes!]));

          img.onload = async () => {
            const { default: JsPDF } = await import("jspdf");

            // Create PDF with A4 size
            const pdf = new JsPDF({
              orientation: "portrait",
              unit: "mm",
              format: "a4",
            });

            // Get PDF dimensions
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();

            // Calculate scaling to maintain aspect ratio while fitting to page
            const imgRatio = img.width / img.height;
            const pageRatio = pageWidth / pageHeight;

            let finalWidth = pageWidth;
            let finalHeight = pageHeight;

            if (imgRatio > pageRatio) {
              // Image is wider than page ratio
              finalHeight = pageWidth / imgRatio;
            } else {
              // Image is taller than page ratio
              finalWidth = pageHeight * imgRatio;
            }

            // Center the image on the page
            const xOffset = (pageWidth - finalWidth) / 2;
            const yOffset = (pageHeight - finalHeight) / 2;

            pdf.addImage(
              img.src,
              "JPEG",
              xOffset,
              yOffset,
              finalWidth,
              finalHeight
            );

            pdf.save("report.pdf");
          };
        }}
      >
        {t("general.download")}
      </button>

      <button
        className={`w-full md:w-auto bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg transition-colors ${
          loading ? "opacity-75 cursor-not-allowed" : ""
        }`}
        disabled={loading}
        onClick={() => {
          setLoadingSave(true);

          controller.save(data).finally(() => {
            setLoadingSave(false);
          });
        }}
      >
        {loading ? (
          <div className="flex items-center gap-2 justify-center">
            <IconLoader2 className="animate-spin h-5 w-5" />
          </div>
        ) : (
          t("general.save")
        )}
      </button>

      <button
        className="w-full md:w-auto bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg transition-colors"
        onClick={() => {
          const confirm = window.confirm("Are you sure?");
          if (confirm) {
            controller.delete().finally(() => {
              navigate(`/club/${idClub}/reports`);
            });
          }
        }}
      >
        {t("general.delete")}
      </button>
    </div>
  );
};

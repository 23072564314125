export const swaythlingJson = {
  type: "FRAME",
  name: "swaythling",
  width: 2480,
  height: 3507,
  x: -2067,
  y: 1979,
  fills: [],
  strokes: [],
  strokeWeight: 1,
  cornerRadius: 0,
  children: [
    {
      type: "GROUP",
      name: "Group 19",
      width: 2480,
      height: 3507,
      x: 0,
      y: 0,
      children: [
        {
          type: "RECTANGLE",
          name: "sway 2",
          width: 2480,
          height: 3507,
          x: 0,
          y: 0,
          fills: [
            {
              type: "IMAGE",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              scaleMode: "FILL",
              imageTransform: [
                [1, 0, 0],
                [0, 1, 0],
              ],
              scalingFactor: 0.5,
              rotation: 0,
              filters: {
                exposure: 0,
                contrast: 0,
                saturation: 0,
                temperature: 0,
                tint: 0,
                highlights: 0,
                shadows: 0,
              },
              imageHash: "0d7bb67bb8e7561eac36866cb060ebda20bd332f",
            },
          ],
          strokes: [],
          strokeWeight: 1,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc",
          width: 936,
          height: 46,
          x: 276,
          y: 650,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz",
          width: 936,
          height: 46,
          x: 1344,
          y: 650,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "balls",
          width: 431,
          height: 46,
          x: 1924,
          y: 579,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "serie",
          width: 408,
          height: 46,
          x: 1132,
          y: 482,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "date",
          width: 655,
          height: 46,
          x: 151,
          y: 352,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "place",
          width: 655,
          height: 46,
          x: 875,
          y: 350,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "referee_role",
          width: 408,
          height: 46,
          x: 615,
          y: 789,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "referee_name",
          width: 558,
          height: 46,
          x: 1430,
          y: 743,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "defib_yes",
          width: 82,
          height: 47,
          x: 1879,
          y: 1050,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "defib_no",
          width: 82,
          height: 47,
          x: 1970,
          y: 1050,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "table",
          width: 617,
          height: 46,
          x: 1121,
          y: 579,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_place",
          width: 617,
          height: 46,
          x: 1743,
          y: 482,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "address",
          width: 617,
          height: 46,
          x: 288,
          y: 578,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_name",
          width: 953,
          height: 34,
          x: 288,
          y: 1483,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_color",
          width: 925,
          height: 34,
          x: 316,
          y: 1529,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_time",
          width: 668,
          height: 34,
          x: 585,
          y: 1575,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_name",
          width: 856,
          height: 34,
          x: 1499,
          y: 1485,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_color",
          width: 856,
          height: 34,
          x: 1527,
          y: 1531,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_time",
          width: 590,
          height: 34,
          x: 1793,
          y: 1577,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_a",
          width: 870,
          height: 34,
          x: 124,
          y: 1681,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_a_card",
          width: 242,
          height: 34,
          x: 1011,
          y: 1681,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_b_card",
          width: 242,
          height: 34,
          x: 1011,
          y: 1732,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_c_card",
          width: 242,
          height: 34,
          x: 1011,
          y: 1783,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_abc_r1_card",
          width: 242,
          height: 34,
          x: 1011,
          y: 1882,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_abc_r2_card",
          width: 242,
          height: 34,
          x: 1011,
          y: 1933,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_captain_card",
          width: 242,
          height: 34,
          x: 1011,
          y: 1982,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_coach_card",
          width: 242,
          height: 34,
          x: 1011,
          y: 2033,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_medic_card",
          width: 242,
          height: 34,
          x: 1011,
          y: 2082,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_manager_card",
          width: 242,
          height: 34,
          x: 1011,
          y: 2132,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_x_card",
          width: 242,
          height: 34,
          x: 2163,
          y: 1682,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_y_card",
          width: 242,
          height: 34,
          x: 2163,
          y: 1733,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_z_card",
          width: 242,
          height: 34,
          x: 2163,
          y: 1784,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_xyz_r1_card",
          width: 242,
          height: 34,
          x: 2163,
          y: 1883,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_xyz_r2_card",
          width: 242,
          height: 34,
          x: 2163,
          y: 1934,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_captain_card",
          width: 242,
          height: 34,
          x: 2163,
          y: 1983,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_coach_card",
          width: 242,
          height: 34,
          x: 2163,
          y: 2034,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_medic_card",
          width: 242,
          height: 34,
          x: 2163,
          y: 2083,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_manager_card",
          width: 242,
          height: 34,
          x: 2163,
          y: 2133,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_b",
          width: 870,
          height: 34,
          x: 124,
          y: 1732,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_c",
          width: 870,
          height: 34,
          x: 124,
          y: 1783,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_x",
          width: 807,
          height: 34,
          x: 1339,
          y: 1681,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_y",
          width: 807,
          height: 34,
          x: 1339,
          y: 1732,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_z",
          width: 807,
          height: 34,
          x: 1339,
          y: 1783,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_abc_r1",
          width: 718,
          height: 34,
          x: 276,
          y: 1883,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_abc_r2",
          width: 718,
          height: 34,
          x: 276,
          y: 1934,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_captain_name",
          width: 718,
          height: 34,
          x: 276,
          y: 1983,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_coach_name",
          width: 718,
          height: 34,
          x: 276,
          y: 2033,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_medic_name",
          width: 718,
          height: 34,
          x: 276,
          y: 2082,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_manager_name",
          width: 718,
          height: 34,
          x: 276,
          y: 2133,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_start_time",
          width: 357,
          height: 34,
          x: 500,
          y: 2211,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_ax_a",
          width: 504,
          height: 38,
          x: 129,
          y: 2378,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_by_b",
          width: 504,
          height: 38,
          x: 129,
          y: 2433,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_cz_c",
          width: 504,
          height: 38,
          x: 129,
          y: 2486,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_bx_b",
          width: 504,
          height: 38,
          x: 129,
          y: 2539,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_az_a",
          width: 504,
          height: 38,
          x: 129,
          y: 2589,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_cy_c",
          width: 504,
          height: 38,
          x: 129,
          y: 2642,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_bz_b",
          width: 504,
          height: 38,
          x: 129,
          y: 2692,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_cx_c",
          width: 504,
          height: 38,
          x: 129,
          y: 2745,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_ay_a",
          width: 504,
          height: 38,
          x: 129,
          y: 2798,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_end_time",
          width: 504,
          height: 38,
          x: 628,
          y: 2901,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_win_score",
          width: 475,
          height: 38,
          x: 1901,
          y: 2970,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_win_by",
          width: 1295,
          height: 38,
          x: 455,
          y: 2970,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_ax_x",
          width: 472,
          height: 38,
          x: 723,
          y: 2377,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s1a",
          width: 71,
          height: 38,
          x: 1218,
          y: 2378,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s1x",
          width: 71,
          height: 38,
          x: 1309,
          y: 2378,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s2a",
          width: 71,
          height: 38,
          x: 1397,
          y: 2378,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s4a",
          width: 71,
          height: 38,
          x: 1750,
          y: 2378,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s4x",
          width: 71,
          height: 38,
          x: 1839,
          y: 2378,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s5a",
          width: 71,
          height: 38,
          x: 1929,
          y: 2378,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s5x",
          width: 71,
          height: 38,
          x: 2015,
          y: 2378,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_total_a",
          width: 71,
          height: 38,
          x: 2105,
          y: 2378,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_total_points_abc",
          width: 71,
          height: 38,
          x: 2105,
          y: 2853,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_total_points_xyz",
          width: 71,
          height: 38,
          x: 2195,
          y: 2853,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_total_x",
          width: 71,
          height: 38,
          x: 2195,
          y: 2378,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_win_a",
          width: 60,
          height: 38,
          x: 2282,
          y: 2378,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_win_x",
          width: 60,
          height: 38,
          x: 2355,
          y: 2378,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_total_win_abc",
          width: 60,
          height: 38,
          x: 2281,
          y: 2853,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_total_win_xyz",
          width: 60,
          height: 38,
          x: 2354,
          y: 2853,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s3x",
          width: 71,
          height: 38,
          x: 1663,
          y: 2378,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s3a",
          width: 71,
          height: 38,
          x: 1574,
          y: 2378,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s2x",
          width: 71,
          height: 38,
          x: 1486,
          y: 2378,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s1a",
          width: 71,
          height: 38,
          x: 1218,
          y: 2432,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s1x",
          width: 71,
          height: 38,
          x: 1308,
          y: 2432,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s2a",
          width: 71,
          height: 38,
          x: 1397,
          y: 2432,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s4a",
          width: 71,
          height: 38,
          x: 1750,
          y: 2432,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s4x",
          width: 71,
          height: 38,
          x: 1839,
          y: 2432,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s5a",
          width: 71,
          height: 38,
          x: 1929,
          y: 2432,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s5x",
          width: 71,
          height: 38,
          x: 2015,
          y: 2432,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_total_a",
          width: 71,
          height: 38,
          x: 2105,
          y: 2432,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_total_x",
          width: 71,
          height: 38,
          x: 2195,
          y: 2432,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_win_a",
          width: 60,
          height: 38,
          x: 2282,
          y: 2432,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_win_x",
          width: 60,
          height: 38,
          x: 2355,
          y: 2432,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s3x",
          width: 71,
          height: 38,
          x: 1663,
          y: 2432,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s3a",
          width: 71,
          height: 38,
          x: 1574,
          y: 2432,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s2x",
          width: 71,
          height: 38,
          x: 1486,
          y: 2432,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s1a",
          width: 71,
          height: 38,
          x: 1219,
          y: 2484,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s1x",
          width: 71,
          height: 38,
          x: 1309,
          y: 2484,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s2a",
          width: 71,
          height: 38,
          x: 1398,
          y: 2484,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s4a",
          width: 71,
          height: 38,
          x: 1751,
          y: 2484,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s4x",
          width: 71,
          height: 38,
          x: 1840,
          y: 2484,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s5a",
          width: 71,
          height: 38,
          x: 1930,
          y: 2484,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s5x",
          width: 71,
          height: 38,
          x: 2016,
          y: 2484,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_total_a",
          width: 71,
          height: 38,
          x: 2106,
          y: 2484,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_total_x",
          width: 71,
          height: 38,
          x: 2196,
          y: 2484,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_win_a",
          width: 60,
          height: 38,
          x: 2283,
          y: 2484,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_win_x",
          width: 60,
          height: 38,
          x: 2356,
          y: 2484,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s3x",
          width: 71,
          height: 38,
          x: 1664,
          y: 2484,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s3a",
          width: 71,
          height: 38,
          x: 1575,
          y: 2484,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s2x",
          width: 71,
          height: 38,
          x: 1487,
          y: 2484,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s1a",
          width: 71,
          height: 38,
          x: 1219,
          y: 2538,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s1x",
          width: 71,
          height: 38,
          x: 1309,
          y: 2538,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s2a",
          width: 71,
          height: 38,
          x: 1398,
          y: 2538,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s4a",
          width: 71,
          height: 38,
          x: 1751,
          y: 2538,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s4x",
          width: 71,
          height: 38,
          x: 1840,
          y: 2538,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s5a",
          width: 71,
          height: 38,
          x: 1930,
          y: 2538,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s5x",
          width: 71,
          height: 38,
          x: 2016,
          y: 2538,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_total_a",
          width: 71,
          height: 38,
          x: 2106,
          y: 2538,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_total_x",
          width: 71,
          height: 38,
          x: 2196,
          y: 2538,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_win_a",
          width: 60,
          height: 38,
          x: 2283,
          y: 2538,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_win_x",
          width: 60,
          height: 38,
          x: 2356,
          y: 2538,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s3x",
          width: 71,
          height: 38,
          x: 1664,
          y: 2538,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s3a",
          width: 71,
          height: 38,
          x: 1575,
          y: 2538,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s2x",
          width: 71,
          height: 38,
          x: 1487,
          y: 2538,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s1a",
          width: 71,
          height: 38,
          x: 1219,
          y: 2588,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s1x",
          width: 71,
          height: 38,
          x: 1309,
          y: 2588,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s2a",
          width: 71,
          height: 38,
          x: 1398,
          y: 2588,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s4a",
          width: 71,
          height: 38,
          x: 1751,
          y: 2588,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s4x",
          width: 71,
          height: 38,
          x: 1840,
          y: 2588,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s5a",
          width: 71,
          height: 38,
          x: 1930,
          y: 2588,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s5x",
          width: 71,
          height: 38,
          x: 2016,
          y: 2588,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_total_a",
          width: 71,
          height: 38,
          x: 2106,
          y: 2588,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_total_x",
          width: 71,
          height: 38,
          x: 2196,
          y: 2588,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_win_a",
          width: 60,
          height: 38,
          x: 2283,
          y: 2588,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_win_x",
          width: 60,
          height: 38,
          x: 2356,
          y: 2588,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s3x",
          width: 71,
          height: 38,
          x: 1664,
          y: 2588,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s3a",
          width: 71,
          height: 38,
          x: 1575,
          y: 2588,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s2x",
          width: 71,
          height: 38,
          x: 1487,
          y: 2588,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s1a",
          width: 71,
          height: 38,
          x: 1219,
          y: 2642,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s1x",
          width: 71,
          height: 38,
          x: 1309,
          y: 2642,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s2a",
          width: 71,
          height: 38,
          x: 1398,
          y: 2642,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s4a",
          width: 71,
          height: 38,
          x: 1751,
          y: 2642,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s4x",
          width: 71,
          height: 38,
          x: 1840,
          y: 2642,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s5a",
          width: 71,
          height: 38,
          x: 1930,
          y: 2642,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s5x",
          width: 71,
          height: 38,
          x: 2016,
          y: 2642,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_total_a",
          width: 71,
          height: 38,
          x: 2106,
          y: 2642,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_total_x",
          width: 71,
          height: 38,
          x: 2196,
          y: 2642,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_win_a",
          width: 60,
          height: 38,
          x: 2283,
          y: 2642,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_win_x",
          width: 60,
          height: 38,
          x: 2356,
          y: 2642,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s3x",
          width: 71,
          height: 38,
          x: 1664,
          y: 2642,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s3a",
          width: 71,
          height: 38,
          x: 1575,
          y: 2642,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s2x",
          width: 71,
          height: 38,
          x: 1487,
          y: 2642,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bz_s1a",
          width: 71,
          height: 38,
          x: 1218,
          y: 2692,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bz_s1x",
          width: 71,
          height: 38,
          x: 1308,
          y: 2692,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bz_s2a",
          width: 71,
          height: 38,
          x: 1397,
          y: 2692,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bz_s4a",
          width: 71,
          height: 38,
          x: 1750,
          y: 2692,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bz_s4x",
          width: 71,
          height: 38,
          x: 1839,
          y: 2692,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bz_s5a",
          width: 71,
          height: 38,
          x: 1929,
          y: 2692,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bz_s5x",
          width: 71,
          height: 38,
          x: 2015,
          y: 2692,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bz_total_a",
          width: 71,
          height: 38,
          x: 2105,
          y: 2692,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bz_total_x",
          width: 71,
          height: 38,
          x: 2195,
          y: 2692,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bz_win_a",
          width: 60,
          height: 38,
          x: 2282,
          y: 2692,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bz_win_x",
          width: 60,
          height: 38,
          x: 2355,
          y: 2692,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bz_s3x",
          width: 71,
          height: 38,
          x: 1663,
          y: 2692,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bz_s3a",
          width: 71,
          height: 38,
          x: 1574,
          y: 2692,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bz_s2x",
          width: 71,
          height: 38,
          x: 1486,
          y: 2692,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ay_s1a",
          width: 71,
          height: 38,
          x: 1219,
          y: 2797,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ay_s1x",
          width: 71,
          height: 38,
          x: 1309,
          y: 2797,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ay_s2a",
          width: 71,
          height: 38,
          x: 1398,
          y: 2797,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ay_s4a",
          width: 71,
          height: 38,
          x: 1751,
          y: 2797,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ay_s4x",
          width: 71,
          height: 38,
          x: 1840,
          y: 2797,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ay_s5a",
          width: 71,
          height: 38,
          x: 1930,
          y: 2797,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ay_s5x",
          width: 71,
          height: 38,
          x: 2016,
          y: 2797,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ay_total_a",
          width: 71,
          height: 38,
          x: 2106,
          y: 2797,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ay_total_x",
          width: 71,
          height: 38,
          x: 2196,
          y: 2797,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ay_win_a",
          width: 60,
          height: 38,
          x: 2283,
          y: 2797,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ay_win_x",
          width: 60,
          height: 38,
          x: 2356,
          y: 2797,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ay_s3x",
          width: 71,
          height: 38,
          x: 1664,
          y: 2797,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ay_s3a",
          width: 71,
          height: 38,
          x: 1575,
          y: 2797,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ay_s2x",
          width: 71,
          height: 38,
          x: 1487,
          y: 2797,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cx_s1a",
          width: 71,
          height: 38,
          x: 1218,
          y: 2746,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cx_s1x",
          width: 71,
          height: 38,
          x: 1308,
          y: 2746,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cx_s2a",
          width: 71,
          height: 38,
          x: 1397,
          y: 2746,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cx_s4a",
          width: 71,
          height: 38,
          x: 1750,
          y: 2746,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cx_s4x",
          width: 71,
          height: 38,
          x: 1839,
          y: 2746,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cx_s5a",
          width: 71,
          height: 38,
          x: 1929,
          y: 2746,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cx_s5x",
          width: 71,
          height: 38,
          x: 2015,
          y: 2746,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cx_total_a",
          width: 71,
          height: 38,
          x: 2105,
          y: 2746,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cx_total_x",
          width: 71,
          height: 38,
          x: 2195,
          y: 2746,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cx_win_a",
          width: 60,
          height: 38,
          x: 2282,
          y: 2746,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cx_win_x",
          width: 60,
          height: 38,
          x: 2355,
          y: 2746,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cx_s3x",
          width: 71,
          height: 38,
          x: 1663,
          y: 2746,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cx_s3a",
          width: 71,
          height: 38,
          x: 1574,
          y: 2746,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cx_s2x",
          width: 71,
          height: 38,
          x: 1486,
          y: 2746,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_by_y",
          width: 472,
          height: 38,
          x: 723,
          y: 2432,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_cz_z",
          width: 472,
          height: 38,
          x: 723,
          y: 2485,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_bx_x",
          width: 472,
          height: 38,
          x: 723,
          y: 2538,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_az_z",
          width: 472,
          height: 38,
          x: 723,
          y: 2588,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_cy_y",
          width: 472,
          height: 38,
          x: 723,
          y: 2641,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_bz_z",
          width: 472,
          height: 38,
          x: 723,
          y: 2691,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_cx_x",
          width: 472,
          height: 38,
          x: 723,
          y: 2744,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_ay_y",
          width: 472,
          height: 38,
          x: 723,
          y: 2797,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_xyz_r1",
          width: 663,
          height: 34,
          x: 1483,
          y: 1882,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_xyz_r2",
          width: 663,
          height: 34,
          x: 1483,
          y: 1933,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_captain_name",
          width: 663,
          height: 34,
          x: 1483,
          y: 1982,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_coach_name",
          width: 663,
          height: 34,
          x: 1483,
          y: 2032,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_medic_name",
          width: 663,
          height: 34,
          x: 1483,
          y: 2081,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_manager_name",
          width: 663,
          height: 34,
          x: 1483,
          y: 2132,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "referee_sign",
          width: 603,
          height: 79,
          x: 1062,
          y: 828,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "defib_boss_sign",
          width: 603,
          height: 79,
          x: 1138,
          y: 1284,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "defib_operator_name",
          width: 603,
          height: 32,
          x: 585,
          y: 1251,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "defib_referee_sign",
          width: 603,
          height: 79,
          x: 513,
          y: 1093,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "captain_away_sign",
          width: 613,
          height: 71,
          x: 625,
          y: 912,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "captain_home_sign",
          width: 567,
          height: 71,
          x: 1828,
          y: 912,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_cz_c",
          width: 504,
          height: 38,
          x: 129,
          y: 2486,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_sign",
          width: 639,
          height: 80,
          x: 464,
          y: 3033,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_sign",
          width: 639,
          height: 80,
          x: 1710,
          y: 3034,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "referee_name_end",
          width: 639,
          height: 80,
          x: 536,
          y: 3132,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "referee_sign_end",
          width: 639,
          height: 80,
          x: 1632,
          y: 3133,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
      ],
    },
  ],
  layoutMode: "NONE",
  primaryAxisSizingMode: "AUTO",
  counterAxisSizingMode: "FIXED",
  primaryAxisAlignItems: "MIN",
  counterAxisAlignItems: "MIN",
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: 0,
  itemSpacing: 0,
};

export const reportSwaythlingKeys = swaythlingJson.children[0].children.map(
  (child) => child.name
);

import { useQuery } from "react-query";
import { queryClient } from "../libs/react-query";
import { Training, TrainingMember } from "../model/training";
import { trainingApi } from "../services/client";

export const useTrainingIdController = (
  id_club: string | undefined,
  id_training: string | undefined
) => {
  const { data: training } = useQuery<Training>({
    queryKey: ["training", id_training],
    queryFn: async () => {
      return await trainingApi.getById(id_club!, id_training!);
    },
    enabled: !!id_club && !!id_training,
  });

  const save = async (training: Partial<Training>) => {
    await trainingApi.patchById(id_club!, id_training!, training);
    queryClient.invalidateQueries(["training"]);
  };

  const saveAll = async (training: Partial<Training>) => {
    if (!id_club || !id_training) return;
    await trainingApi.updatePeriodicTraining(id_club, id_training, training);
    queryClient.invalidateQueries(["training"]);
  };

  const delete_ = async () => {
    await trainingApi.deleteById(id_club!, id_training!);
    queryClient.invalidateQueries(["training"]);
  };

  const addMember = async (
    email: string | null | undefined,
    firstName: string | null | undefined,
    lastName: string | null | undefined
  ) => {
    const trainingMember: TrainingMember = {
      email: email,
      first_name: firstName,
      last_name: lastName,
    };

    await trainingApi.addMember(id_club!, id_training!, trainingMember);
    queryClient.invalidateQueries(["training"]);
  };

  const removeMember = async (trainingMember: TrainingMember) => {
    await trainingApi.removeMember(id_club!, id_training!, trainingMember);
    queryClient.invalidateQueries(["training"]);
  };

  return {
    save,
    saveAll,
    training,
    delete: delete_,
    addMember,
    removeMember,
  };
};

import { IconChevronDown, IconDownload } from "@tabler/icons-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDataController } from "../../../../../controller/data-controller";
import { useI18n } from "../../../../../lang/i18n";
import { Member } from "../../../../../model/member";

export default function DataRegisteredMembers() {
  const { idClub } = useParams();
  const { t } = useI18n();

  const [showRegisteredMembers, setShowRegisteredMembers] = useState(true);

  const { registeredMembers, exportRegisteredMembersCsv } =
    useDataController(idClub);

  const [sortColumn, setSortColumn] = useState<string>("");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const getSortIndicator = (column: string) => {
    if (sortColumn !== column) return "↕";
    return sortDirection === "asc" ? "↑" : "↓";
  };

  const sortedRegisteredMembers = registeredMembers
    ? [...registeredMembers].sort((a, b) => {
        if (!sortColumn) return 0;

        const aValue = a[sortColumn as keyof Member];
        const bValue = b[sortColumn as keyof Member];

        if (aValue === bValue) return 0;
        if (aValue === null || aValue === undefined) return 1;
        if (bValue === null || bValue === undefined) return -1;

        const comparison = aValue < bValue ? -1 : 1;
        return sortDirection === "asc" ? comparison : -comparison;
      })
    : [];

  return (
    <div className="generic-card">
      <div className="generic-card-header">
        <h2 className="generic-card-title">{t("data.registered-members")}</h2>
        <div className="flex items-center gap-2">
          <button
            onClick={exportRegisteredMembersCsv}
            className="p-2 rounded-full transition-colors hover:bg-gray-200/50 dark:hover:bg-gray-700/50"
            title={t("data.export-to-csv")}
          >
            <IconDownload className="h-5 w-5 sm:h-6 sm:w-6 text-gray-500" />
          </button>
          <button
            onClick={() => setShowRegisteredMembers(!showRegisteredMembers)}
            className="p-1 rounded-full transition-transform duration-200 hover:bg-gray-200/50 dark:hover:bg-gray-700/50"
            style={{
              transform: showRegisteredMembers
                ? "rotate(0deg)"
                : "rotate(-180deg)",
            }}
          >
            <IconChevronDown className="h-5 w-5 sm:h-6 sm:w-6 text-gray-500" />
          </button>
        </div>
      </div>

      {showRegisteredMembers && (
        <div className="generic-card-body">
          {registeredMembers && registeredMembers.length > 0 ? (
            <div className="space-y-4">
              <table className="min-w-full divide-y ">
                <thead>
                  <tr>
                    <th
                      className="px-3 sm:px-6 py-2 sm:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-800/50"
                      onClick={() => handleSort("email")}
                    >
                      {t("general.email")} {getSortIndicator("email")}
                    </th>
                    <th
                      className="px-3 sm:px-6 py-2 sm:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-800/50"
                      onClick={() => handleSort("first_name")}
                    >
                      {t("general.first-name")} {getSortIndicator("first_name")}
                    </th>
                    <th
                      className="px-3 sm:px-6 py-2 sm:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-800/50"
                      onClick={() => handleSort("last_name")}
                    >
                      {t("general.last-name")} {getSortIndicator("last_name")}
                    </th>
                    <th
                      className="px-3 sm:px-6 py-2 sm:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-800/50"
                      onClick={() => handleSort("virt_registered")}
                    >
                      {t("data.registered")}{" "}
                      {getSortIndicator("virt_registered")}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y">
                  {sortedRegisteredMembers.map((member) => (
                    <tr
                      key={member._id}
                      className="hover:bg-gray-50 dark:hover:bg-gray-800/50"
                    >
                      <td className="px-3 sm:px-6 py-2 sm:py-4 whitespace-nowrap text-sm">
                        {member.email}
                      </td>
                      <td className="px-3 sm:px-6 py-2 sm:py-4 whitespace-nowrap text-sm">
                        {member.first_name}
                      </td>
                      <td className="px-3 sm:px-6 py-2 sm:py-4 whitespace-nowrap text-sm">
                        {member.last_name}
                      </td>
                      <td className="px-3 sm:px-6 py-2 sm:py-4 whitespace-nowrap text-sm flex items-center gap-2">
                        <div
                          className={`w-2.5 h-2.5 rounded-full ${
                            member.virt_registered
                              ? "bg-green-500"
                              : "bg-red-500"
                          }`}
                        ></div>
                        {member.virt_registered
                          ? t("general.yes")
                          : t("general.no")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="text-center text-gray-500 py-6 sm:py-8 text-sm sm:text-base">
              {t("data.no-registered-members")}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

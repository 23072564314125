import { IconEdit, IconLoader2 } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useMemberController } from "../../../../controller/member-controller";
import { useTrainingIdController } from "../../../../controller/training-id-controller";
import { useI18n } from "../../../../lang/i18n";
import { DateUtils } from "../../../../libs/date";

import {
  IconCalendar,
  IconClock,
  IconMapPin,
  IconNote,
  IconSearch,
  IconUsers,
} from "@tabler/icons-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { UserRole } from "../../../../model/member";
import { TrainingMember } from "../../../../model/training";
import Visible from "../../component/visible";

export default function TrainingPage() {
  const { idClub, idTraining } = useParams();
  const { t } = useI18n();
  const trainingIdController = useTrainingIdController(idClub, idTraining);
  const { currentMember } = useMemberController(idClub);
  const training = trainingIdController.training;
  const isOwnerOrAdmin =
    currentMember?.user_role_list.includes(UserRole.owner) ||
    currentMember?.user_role_list.includes(UserRole.super_admin) ||
    currentMember?.user_role_list.includes(UserRole.admin);

  if (!training) {
    return <div></div>;
  }

  const athletesRegistered = training.joined_member_list?.length ?? 0;

  return (
    <div className="container mx-auto px-4 sm:px-6 py-4 sm:py-6 max-w-4xl space-y-4 sm:space-y-6">
      <div className="rounded-lg shadow-sm border ">
        {/* Header */}
        <div className="p-4 sm:p-6 border-b ">
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-2">
            <div className="flex items-center gap-4">
              <h1 className="text-xl sm:text-2xl font-bold">{training.name}</h1>
              {isOwnerOrAdmin && (
                <Link
                  to={`/club/${idClub}/trainings/${idTraining}/edit`}
                  className="text-gray-600 hover:text-gray-900"
                >
                  <IconEdit className="h-5 w-5" />
                </Link>
              )}
            </div>
            {training.every_week && (
              <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded w-fit">
                {t("trainings.every-week")}
              </span>
            )}
          </div>
        </div>

        {/* Content */}
        <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">
          {/* Date and Time Section */}
          <section className="space-y-3">
            <div className="flex items-start sm:items-center gap-2">
              <IconCalendar className="h-5 w-5 mt-1 sm:mt-0" />
              <div>
                <p className="text-sm ">{t("general.date")}</p>
                <p className="text-sm sm:text-base">
                  {DateUtils.dateStringToLocaleDateString(training.start_date)}
                </p>
              </div>
            </div>
            <div className="flex items-start sm:items-center gap-2">
              <IconClock className="h-5 w-5 mt-1 sm:mt-0" />
              <div>
                <p className="text-sm ">{t("general.time")}</p>
                <p className="text-sm sm:text-base">
                  {DateUtils.dateStringToLocaleTimeString(training.start_date)}{" "}
                  - {DateUtils.dateStringToLocaleTimeString(training.end_date)}
                </p>
              </div>
            </div>
          </section>

          {/* Location */}
          <section>
            <div className="flex items-start sm:items-center gap-2">
              <IconMapPin className="h-5 w-5 mt-1 sm:mt-0" />
              <div>
                <p className="text-sm ">{t("trainings.place")}</p>
                <p className="text-sm sm:text-base break-words">
                  {training.place}
                </p>
              </div>
            </div>
          </section>

          {/* Capacity */}
          <section>
            <div className="flex items-start sm:items-center gap-2">
              <IconUsers className="h-5 w-5 mt-1 sm:mt-0" />
              <div>
                <p className="text-sm ">{t("trainings.capacity")}</p>
                <p className="text-sm sm:text-base">
                  {athletesRegistered} athletes registered
                  {training.max_athletes && ` (max: ${training.max_athletes})`}
                  {training.min_athletes && ` (min: ${training.min_athletes})`}
                </p>
              </div>
            </div>
          </section>

          {/* Booking Period */}
          {(training.book_start_date || training.book_end_date) && (
            <section className="space-y-3">
              <h3 className="font-medium">{t("trainings.booking-period")}</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {training.book_start_date && (
                  <div>
                    <p className="text-sm ">{t("general.from")}</p>
                    <p className="text-sm sm:text-base">
                      {DateUtils.dateStringToLocaleDateString(
                        training.book_start_date
                      )}
                    </p>
                  </div>
                )}
                {training.book_end_date && (
                  <div>
                    <p className="text-sm ">{t("general.to")}</p>
                    <p className="text-sm sm:text-base">
                      {DateUtils.dateStringToLocaleDateString(
                        training.book_end_date
                      )}
                    </p>
                  </div>
                )}
              </div>
            </section>
          )}

          {/* Notes */}
          {training.note && (
            <section>
              <div className="flex items-start gap-2">
                <IconNote className="h-5 w-5 mt-1" />
                <div>
                  <p className="text-sm ">{t("trainings.note")}</p>
                  <p className="text-sm sm:text-base whitespace-pre-wrap break-words">
                    {training.note}
                  </p>
                </div>
              </div>
            </section>
          )}
        </div>
      </div>

      {/* Member Management Section */}
      <TrainingActionView />
    </div>
  );
}

const TrainingActionView = () => {
  const { t } = useI18n();
  const { idClub, idTraining } = useParams();
  const trainingIdController = useTrainingIdController(idClub, idTraining);
  const { memberList, currentMember } = useMemberController(idClub);
  const training = trainingIdController.training;

  const [emailToAdd, setEmailToAdd] = useState("");
  const [firstnameToAdd, setFirstnameToAdd] = useState("");
  const [lastnameToAdd, setLastnameToAdd] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isOwnerOrAdmin =
    currentMember?.user_role_list.includes(UserRole.owner) ||
    currentMember?.user_role_list.includes(UserRole.super_admin) ||
    currentMember?.user_role_list.includes(UserRole.admin);

  const filteredMembers = memberList?.filter(
    (member) =>
      member.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      member.last_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      member.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  let currentTrainingMember = training?.joined_member_list?.find(
    (trainingMember) => trainingMember.email === currentMember?.email
  );

  const handleAddMember = async (
    email: string | null | undefined,
    firstName: string | null | undefined,
    lastName: string | null | undefined
  ) => {
    try {
      await trainingIdController.addMember(email, firstName, lastName);

      setEmailToAdd("");
      setFirstnameToAdd("");
      setLastnameToAdd("");
      setSearchTerm("");
    } catch (error) {
      alert(error);
    }
  };

  const handleJoinTraining = async () => {
    setIsLoading(true);
    try {
      await trainingIdController.addMember(
        currentMember?.email,
        currentMember?.first_name,
        currentMember?.last_name
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleLeaveTraining = async () => {
    setIsLoading(true);
    try {
      await trainingIdController.removeMember(currentTrainingMember!);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="rounded-lg shadow-sm border ">
      <div className="p-4 sm:p-6 border-b ">
        <h2 className="text-lg sm:text-xl font-semibold">
          {isOwnerOrAdmin
            ? t("trainings.member-management")
            : "Training Registration"}
        </h2>
      </div>

      <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">
        {/* Member's view for joining/leaving training */}
        <div className="p-4 sm:p-6">
          {currentTrainingMember ? (
            <button
              onClick={handleLeaveTraining}
              disabled={isLoading}
              className="w-full sm:w-auto bg-red-600 text-white px-6 py-2.5 rounded-md hover:bg-red-700 transition-colors disabled:opacity-50"
            >
              {isLoading ? (
                <IconLoader2 className="animate-spin-progress" size={20} />
              ) : (
                t("trainings.leave-training")
              )}
            </button>
          ) : (
            <button
              onClick={handleJoinTraining}
              disabled={isLoading}
              className="w-full sm:w-auto bg-app-primary px-6 py-2.5 rounded-md hover:bg-opacity-90 transition-colors disabled:opacity-50"
            >
              {isLoading ? (
                <IconLoader2 className="animate-spin-progress" size={20} />
              ) : (
                t("trainings.join-training")
              )}
            </button>
          )}
        </div>

        {/* Admin's view for managing members */}
        {isOwnerOrAdmin && (
          <>
            {/* Search Club Members Section */}
            <div className="space-y-4">
              <h3 className="font-medium">
                {t("trainings.search-club-member")}
              </h3>
              <div className="relative">
                <IconSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder={t("general.search")}
                  className="pl-10 w-full border rounded-md px-3 py-2 text-sm"
                />
              </div>

              {/* Club Member Suggestions */}
              {searchTerm && (
                <div className="mt-2 border rounded-md max-h-48 overflow-y-auto">
                  {filteredMembers?.map((member) => (
                    <div
                      key={member.email}
                      className="p-2 flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2"
                    >
                      <span className="text-sm break-all">
                        {member.first_name} {member.last_name}
                      </span>
                      <span className="text-sm break-all">{member.email}</span>
                      <button
                        onClick={() => {
                          handleAddMember(
                            member.email,
                            member.first_name,
                            member.last_name
                          );
                        }}
                        disabled={training?.joined_member_list?.some(
                          (trainingMember) =>
                            trainingMember.email === member.email
                        )}
                        className="w-full sm:w-auto bg-app-primary px-4 py-2 rounded-md disabled:opacity-50 text-sm"
                      >
                        {training?.joined_member_list?.some(
                          (trainingMember) =>
                            trainingMember.email === member.email
                        )
                          ? t("general.already-added")
                          : t("general.add")}
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="space-y-4">
              <h3 className="font-medium">
                {t("trainings.add-outside-member")}
              </h3>
              <div className="flex flex-col sm:flex-row gap-2">
                <input
                  type="email"
                  value={emailToAdd}
                  onChange={(e) => setEmailToAdd(e.target.value)}
                  placeholder={
                    t("general.email") + ` (${t("general.optional-field")})`
                  }
                  className="w-full border rounded-md px-3 py-2 text-sm"
                />
                <input
                  type="text"
                  value={firstnameToAdd}
                  onChange={(e) => setFirstnameToAdd(e.target.value)}
                  placeholder={t("general.first-name")}
                  className="w-full border rounded-md px-3 py-2 text-sm"
                />
                <input
                  type="text"
                  value={lastnameToAdd}
                  onChange={(e) => setLastnameToAdd(e.target.value)}
                  placeholder={t("general.last-name")}
                  className="w-full border rounded-md px-3 py-2 text-sm"
                />
                <button
                  onClick={() => {
                    handleAddMember(emailToAdd, firstnameToAdd, lastnameToAdd);
                  }}
                  disabled={!firstnameToAdd || !lastnameToAdd}
                  className="w-full sm:w-auto bg-app-primary px-4 py-2 rounded-md disabled:opacity-50 text-sm"
                >
                  {t("general.add")}
                </button>
              </div>
            </div>
          </>
        )}

        <MemberList />
      </div>
    </div>
  );
};

const MemberList = () => {
  const { idClub, idTraining } = useParams();
  const trainingIdController = useTrainingIdController(idClub, idTraining);
  const training = trainingIdController.training;
  const { t } = useI18n();

  const handleRemoveMember = async (trainingMember: TrainingMember) => {
    if (window.confirm(t("trainings.confirm-remove-member"))) {
      try {
        await trainingIdController.removeMember(trainingMember);
      } catch (error) {
        alert("trainings.remove-member-error");
      }
    }
  };

  return (
    <div className="space-y-4 p-4 sm:p-6">
      <h3 className="font-medium">{t("trainings.current-members")}</h3>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y">
          <thead>
            <tr>
              <th className="px-4 sm:px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                {t("general.first-name")}
              </th>
              <th className="px-4 sm:px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                {t("general.last-name")}
              </th>
              <th className="px-4 sm:px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                {t("general.email")}
              </th>
              <th className="px-4 sm:px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                {t("general.status")}
              </th>
              <th className="px-4 sm:px-6 py-3 text-right text-xs font-medium uppercase tracking-wider">
                {t("general.actions")}
              </th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {training?.joined_member_list?.map((member) => (
              <TrainingMemberRow
                key={member._id}
                trainingMember={member}
                handleRemoveMember={handleRemoveMember}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const TrainingMemberRow = ({
  trainingMember,
  handleRemoveMember,
}: {
  trainingMember: TrainingMember;
  handleRemoveMember: (trainingMember: TrainingMember) => void;
}) => {
  const { idClub } = useParams();
  const { memberList } = useMemberController(idClub);
  const { currentMember } = useMemberController(idClub);
  const { t } = useI18n();

  const isOwnerOrAdmin =
    currentMember?.user_role_list.includes(UserRole.owner) ||
    currentMember?.user_role_list.includes(UserRole.super_admin) ||
    currentMember?.user_role_list.includes(UserRole.admin);

  const member = memberList?.find((m) => m?.email === trainingMember.email);

  const isClubMember = memberList?.some(
    (m) => m?.email === trainingMember.email
  );

  return (
    <tr className="cursor-default">
      <td className="px-4 sm:px-6 py-3 whitespace-nowrap">
        {member?.first_name ?? trainingMember.first_name}
      </td>
      <td className="px-4 sm:px-6 py-3 whitespace-nowrap">
        {member?.last_name ?? trainingMember.last_name}
      </td>
      <td className="px-4 sm:px-6 py-3 whitespace-nowrap">
        {member?.email ?? trainingMember.email}
      </td>
      <td className="px-4 sm:px-6 py-3 whitespace-nowrap">
        {!isClubMember && (
          <span className="bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded">
            {t("trainings.external")}
          </span>
        )}
      </td>
      <Visible
        when={!!isOwnerOrAdmin || member?.email === currentMember?.email}
      >
        <td className="px-4 sm:px-6 py-3 whitespace-nowrap text-right">
          <button
            onClick={() => {
              handleRemoveMember(trainingMember);
            }}
            className="text-red-600 hover:text-red-900"
          >
            {t("general.remove")}
          </button>
        </td>
      </Visible>
    </tr>
  );
};

import { useCallback, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useI18n } from "../../../../../lang/i18n";
import { debounce } from "../../../../../libs/debouncer";
import {
  PlayerKey,
  Report,
  TeamInfo,
  TeamKey,
} from "../../../../../model/report";
import { fitetApi } from "../../../../../services/client";

interface PlayerInputProps {
  teamKey: TeamKey;
  playerKey: PlayerKey;
  team: TeamInfo;
  report: Report;
  fitet: boolean;
  setData: (data: Report) => void;
}

export const PlayerInput = ({
  report,
  playerKey,
  team,
  teamKey,
  fitet,
  setData,
}: PlayerInputProps) => {
  const { t } = useI18n();
  const { idClub } = useParams();

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [debouncedSearch, setDebouncedSearch] = useState("");

  const debouncedSetSearch = useCallback(
    debounce((value: string) => {
      setDebouncedSearch(value);
    }, 1000),
    []
  );

  const { data: suggestions, isFetching } = useQuery({
    queryKey: ["playerSearch", debouncedSearch],
    queryFn: () => fitetApi.searchPlayer(idClub!, debouncedSearch),
    enabled: !!idClub && debouncedSearch.length >= 3,
    staleTime: 1000 * 60 * 5,
  });

  const update = (partial: Partial<Report>) => {
    setData({
      ...report,
      ...partial,
    });
  };

  const handlePlayerSelect = (name: string, card: string) => {
    let players = team?.players ?? {};

    players[playerKey] = {
      ...players[playerKey],
      name,
      card,
    };

    update({
      teams: {
        ...report.teams,
        [teamKey]: {
          ...report.teams[teamKey],
          players,
        },
      },
    });

    setShowSuggestions(false);
  };

  return (
    <div className="flex flex-col gap-1 relative">
      <div className="flex flex-row gap-1">
        <div className="relative w-full">
          <input
            placeholder={t("reports.player") + " " + playerKey}
            value={team?.players[playerKey]?.name ?? searchValue}
            onChange={(e) => {
              const value = e.target.value;
              setSearchValue(value);

              if (fitet) {
                debouncedSetSearch(value);
              }

              let players = team?.players ?? {};

              players[playerKey] = {
                ...players[playerKey],
                name: value,
              };

              update({
                teams: {
                  ...report.teams,
                  [teamKey]: {
                    ...report.teams[teamKey],
                    players,
                  },
                },
              });
            }}
            onFocus={() => setShowSuggestions(true)}
          />
          {showSuggestions && (
            <div className="absolute z-10 w-full bg-white border border-gray-200 rounded-md shadow-lg mt-1">
              {isFetching ? (
                <div className="px-4 py-2 text-gray-500">
                  {t("general.loading")}
                </div>
              ) : (
                suggestions?.map((player) => (
                  <div
                    key={player.id_card}
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() =>
                      handlePlayerSelect(player.name, player.id_card)
                    }
                  >
                    {player.name} - {player.id_card}
                  </div>
                ))
              )}
            </div>
          )}
        </div>

        <input
          placeholder={t("reports.card") + " " + playerKey}
          value={team?.players[playerKey]?.card}
          onChange={(e) => {
            let players = team.players;

            players[playerKey] = {
              ...players[playerKey],
              card: e.target.value,
            };

            update({
              teams: {
                ...report.teams,
                [teamKey]: {
                  ...report.teams[teamKey],
                  players,
                },
              },
            });
          }}
        />
      </div>
    </div>
  );
};

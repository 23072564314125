import { Link, useParams } from "react-router-dom";

export default function NotFound() {
  const { lang } = useParams();

  return (
    <div className="flex flex-col items-center justify-center min-h-[60vh] gap-4">
      <h1 className="text-4xl font-bold">404</h1>
      <p className="text-xl">Page not found</p>
      <Link
        to={`/${lang}`}
        className="text-blue-500 hover:text-blue-700 underline"
      >
        Return to home
      </Link>
    </div>
  );
}

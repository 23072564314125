import { useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { config } from "../../config";
import { useI18n } from "../../lang/i18n";

export default function Contact() {
  const { t } = useI18n();
  const params = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  let canonical;
  if (params.lang !== "en") {
    canonical = `https://${config.domainName}/${params.lang}/contact`;
  } else {
    canonical = `https://${config.domainName}/contact`;
  }

  return (
    <section className="flex min-h-screen items-center justify-center pt-[120px]">
      <Helmet>
        <title>{t("contact.meta.title")}</title>
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content={t("contact.meta.description")?.toString()}
        />
        <meta
          name="keywords"
          content={t("contact.meta.keywords")?.toString()}
        />
        <link rel="canonical" href={canonical} />
      </Helmet>

      <div className="w-full max-w-lg rounded-lg p-8 shadow-lg">
        <h2 className="mb-6 text-center text-3xl font-semibold">
          {t("footer.send-me-a-message")}
        </h2>

        <div className="space-y-6">
          <div className="space-y-2">
            <label htmlFor="name" className="block text-sm font-medium">
              {t("general.name")}
            </label>
            <input
              id="name"
              type="text"
              className="w-full rounded-md border px-4 py-2 focus:border-blue-500 focus:outline-none"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="space-y-2">
            <label htmlFor="email" className="block text-sm font-medium">
              {t("general.email")}
            </label>
            <input
              id="email"
              type="email"
              className="w-full rounded-md border px-4 py-2 focus:border-blue-500 focus:outline-none"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="space-y-2">
            <label htmlFor="message" className="block text-sm font-medium">
              {t("general.note")}
            </label>
            <textarea
              id="message"
              rows={4}
              className="w-full rounded-md border px-4 py-2 focus:border-blue-500 focus:outline-none"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>

          <button
            className={`w-full rounded-md bg-blue-600 px-4 py-2 transition-all hover:bg-blue-700 focus:outline-none ${
              loading ? "cursor-not-allowed opacity-50" : ""
            }`}
            disabled={loading}
            data-variant="primary"
            onClick={async () => {
              if (loading) return;

              if (!name || !email || !message) {
                // TODO: Add proper error handling/validation
                alert("Please fill all fields");
                return;
              }

              setLoading(true);

              try {
                const baseUrl = process.env.PUBLIC_BACKEND_API_URL!;
                const response = await fetch(`${baseUrl}/contact`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ name, email, message }),
                });

                if (!response.ok) {
                  throw new Error("Failed to send message");
                }

                setName("");
                setEmail("");
                setMessage("");
                // TODO: Add proper success notification
                alert("Message sent successfully!");
              } catch (error) {
                console.error("Failed to send message:", error);
                // TODO: Add proper error notification
                alert("Failed to send message. Please try again later.");
              } finally {
                setLoading(false);
              }
            }}
          >
            {loading ? t("general.saving") : t("footer.send-me-a-message")}
          </button>
        </div>
      </div>
    </section>
  );
}

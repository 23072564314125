import { Parser } from "@json2csv/plainjs";
import { atom, useAtom } from "jotai";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { MemberAttendance } from "../model/member-attendance";
import { memberApi, trainingApi } from "../services/client";
import { useMemberController } from "./member-controller";

const startDateAtom = atom<string | null>(null);
const endDateAtom = atom<string | null>(null);

export const useDataController = (id_club: string | undefined) => {
  const [startDate, setStartDate] = useAtom(startDateAtom);
  const [endDate, setEndDate] = useAtom(endDateAtom);

  const memberController = useMemberController(id_club);
  const memberList = memberController.memberList;

  const { data: trainingList } = useQuery({
    queryKey: ["data", id_club, startDate, endDate],
    queryFn: async () => {
      let startDate00_00 = "";
      let endDate00_00 = "";

      if (startDate) {
        const startDateObj = new Date(startDate);
        startDateObj.setHours(0, 0, 0, 0);
        startDate00_00 = startDateObj.toISOString();
      }

      if (endDate) {
        const endDateObj = new Date(endDate);
        endDateObj.setHours(23, 59, 59, 999);
        endDate00_00 = endDateObj.toISOString();
      }

      return await trainingApi.get({
        id_club: id_club!,
        startDate: startDate00_00,
        endDate: endDate00_00,
      });
    },
    enabled: !!id_club && !!startDate && !!endDate,
    cacheTime: 0,
    staleTime: 0,
  });

  // for each training, count the number of presence for each member.
  // if the member is a club member, use their _id as key.
  // otherwise, use their email-first_name-last_name as key.
  const memberAttendance: MemberAttendance[] = useMemo(() => {
    if (!trainingList) return [];

    // key: if club member use _id, otherwise use email-first_name-last_name, value: attendance count
    const attendanceMap = new Map<string, MemberAttendance>();

    // Process each training
    trainingList.forEach((training) => {
      training.joined_member_list?.forEach((member) => {
        const existingMember = memberList?.find(
          (clubMember) => clubMember.email === member.email
        );

        let idComponents = [];
        if (existingMember?.email) {
          idComponents.push(existingMember.email);
        }
        idComponents.push(member.first_name);
        idComponents.push(member.last_name);

        const id = idComponents.join("-");
        const email = existingMember?.email ?? member.email;
        const first_name = existingMember?.first_name ?? member.first_name;
        const last_name = existingMember?.last_name ?? member.last_name;

        if (!attendanceMap.has(id)) {
          attendanceMap.set(id, {
            key: id,
            first_name,
            last_name,
            email,
            id_club_member: existingMember?._id,
            attendance_count: 0,
          });
        }

        // Increment attendance count
        const currentAttendance = attendanceMap.get(id)!;
        attendanceMap.set(id, {
          ...currentAttendance,
          attendance_count: currentAttendance.attendance_count + 1,
        });
      });
    });

    // Convert map to array and return
    return Array.from(attendanceMap.values());
  }, [trainingList, memberList]);

  const formatDateTime = (date: string) => {
    return new Date(date).toLocaleString();
  };

  const exportTrainingListCsv = () => {
    if (!trainingList) return;

    try {
      const data = trainingList.map((training) => ({
        name: training.name,
        start_date: formatDateTime(training.start_date!),
        end_date: formatDateTime(training.end_date!),
        place: training.place,
        participants: training.joined_member_list?.length ?? 0,
      }));

      const parser = new Parser();
      const csv = parser.parse(data);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);

      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `training-list-${new Date().toISOString().split("T")[0]}.csv`
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting training list:", error);
    }
  };

  const exportMemberAttendanceCsv = () => {
    if (!memberAttendance) return;

    try {
      const parser = new Parser();
      const csv = parser.parse(memberAttendance);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);

      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `member-attendance-${new Date().toISOString().split("T")[0]}.csv`
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting member attendance:", error);
    }
  };

  const setStartDateByDateString = (date: string) => {
    setStartDate(date);
  };

  const setEndDateByDateString = (date: string) => {
    setEndDate(date);
  };

  const getMemberTrainings = (id_club_member: string) => {
    if (!trainingList) return [];

    return trainingList.filter((training) =>
      training.joined_member_list?.some((member) => {
        const existingMember = memberList?.find(
          (clubMember) => clubMember.email === member.email
        );
        return existingMember?._id === id_club_member;
      })
    );
  };

  const exportMemberTrainingsCsv = (id_club_member: string) => {
    const memberTrainings = getMemberTrainings(id_club_member);
    if (!memberTrainings.length) return;

    try {
      const data = memberTrainings.map((training) => ({
        name: training.name,
        start_date: formatDateTime(training.start_date!),
        end_date: formatDateTime(training.end_date!),
        place: training.place,
        participants: training.joined_member_list?.length ?? 0,
      }));

      const parser = new Parser();
      const csv = parser.parse(data);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);

      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `member-trainings-${new Date().toISOString().split("T")[0]}.csv`
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting member trainings:", error);
    }
  };

  const exportNonMemberTrainingsCsv = (member: MemberAttendance) => {
    const memberTrainings = trainingList?.filter((training) =>
      training.joined_member_list?.some(
        (m) =>
          (!!m.email && m.email === member.email) ||
          (m.first_name === member.first_name &&
            m.last_name === member.last_name)
      )
    );

    if (!memberTrainings?.length) return;

    try {
      const data = memberTrainings.map((training) => ({
        name: training.name,
        start_date: formatDateTime(training.start_date!),
        end_date: formatDateTime(training.end_date!),
        place: training.place,
        participants: training.joined_member_list?.length ?? 0,
      }));

      const parser = new Parser();
      const csv = parser.parse(data);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);

      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `member-trainings-${new Date().toISOString().split("T")[0]}.csv`
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting non-member trainings:", error);
    }
  };

  const { data: registeredMembers = [] } = useQuery({
    queryKey: ["registered-members", id_club],
    queryFn: async () => {
      return await memberApi.getRegisteredMembers(id_club!);
    },
  });

  const exportRegisteredMembersCsv = () => {
    if (!registeredMembers) return;

    const parser = new Parser();
    const csv = parser.parse(registeredMembers);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", `registered-members.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return {
    trainingList,
    startDate,
    setStartDateByDateString,
    endDate,
    setEndDateByDateString,
    memberAttendance,
    exportTrainingListCsv,
    exportMemberAttendanceCsv,
    getMemberTrainings,
    exportMemberTrainingsCsv,
    exportNonMemberTrainingsCsv,
    registeredMembers,
    exportRegisteredMembersCsv,
  };
};

import { IconChevronDown, IconDownload } from "@tabler/icons-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDataController } from "../../../../controller/data-controller";
import { useMemberController } from "../../../../controller/member-controller";
import { useI18n } from "../../../../lang/i18n";
import TrainingList from "../trainings/training-list";
import DateFilters from "./_components/date-filters";

export default function DataMemberPage() {
  const { idClub, idMember } = useParams();
  const { t } = useI18n();
  const [showTrainingList, setShowTrainingList] = useState(true);
  const { memberList } = useMemberController(idClub);

  const {
    getMemberTrainings,
    startDate,
    endDate,
    setStartDateByDateString,
    setEndDateByDateString,
    exportMemberTrainingsCsv,
  } = useDataController(idClub);

  const memberTrainings = idMember ? getMemberTrainings(idMember) : [];
  const member = memberList?.find((m) => m._id === idMember);

  return (
    <div className="container mx-auto p-3 sm:p-6 max-w-4xl space-y-4 sm:space-y-6">
      {member && (
        <div className="generic-card">
          <div className="generic-card-header">
            <h2 className="generic-card-title">{t("general.member-info")}</h2>
          </div>
          <div className="generic-card-body">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {t("general.email")}
                </p>
                <p className="font-medium">{member.email}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {t("general.first-name")}
                </p>
                <p className="font-medium">{member.first_name}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {t("general.last-name")}
                </p>
                <p className="font-medium">{member.last_name}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <DateFilters
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={setStartDateByDateString}
        onEndDateChange={setEndDateByDateString}
      />

      <div className="generic-card">
        <div className="generic-card-header">
          <h2 className="generic-card-title">{t("data.member-trainings")}</h2>
          <div className="flex items-center gap-2">
            <button
              onClick={() => idMember && exportMemberTrainingsCsv(idMember)}
              className="p-2 rounded-full transition-colors hover:bg-gray-200/50 dark:hover:bg-gray-700/50"
              title={t("general.export-to-csv")}
            >
              <IconDownload className="h-5 w-5 sm:h-6 sm:w-6 text-gray-500" />
            </button>
            <button
              onClick={() => setShowTrainingList(!showTrainingList)}
              className="p-1 rounded-full transition-transform duration-200 hover:bg-gray-200/50 dark:hover:bg-gray-700/50"
              style={{
                transform: showTrainingList
                  ? "rotate(0deg)"
                  : "rotate(-180deg)",
              }}
            >
              <IconChevronDown className="h-5 w-5 sm:h-6 sm:w-6 text-gray-500" />
            </button>
          </div>
        </div>
        {showTrainingList && (
          <div className="generic-card-body">
            {memberTrainings && memberTrainings.length > 0 ? (
              <TrainingList idClub={idClub} trainingList={memberTrainings} />
            ) : (
              <div className="text-center text-gray-500 py-6 sm:py-8 text-sm sm:text-base">
                {t("data.no-trainings-found")}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

import OneSignal from "react-onesignal";

let onesignalInitialized = false;

const loadOneSignalScript = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (document.getElementById("oneSignalScript")) {
      resolve();
      return;
    }

    const script = document.createElement("script");
    script.id = "oneSignalScript";
    script.src = "https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js";
    script.async = true;

    script.onload = () => resolve();
    script.onerror = (error) => reject(error);

    document.head.appendChild(script);
  });
};

export const initOneSignal = async () => {
  if (onesignalInitialized) {
    return;
  }

  console.log("initializing onesignal");
  onesignalInitialized = true;

  try {
    // Load the OneSignal script
    await loadOneSignalScript();

    // Wait for OneSignal to be available
    await new Promise<void>((resolve) => {
      if (OneSignal) {
        resolve();
      } else {
        addEventListener("load", () => resolve());
      }
    });

    await OneSignal.init({
      appId: process.env.PUBLIC_ONE_SIGNAL_APP_ID!,
      safari_web_id: process.env.PUBLIC_ONE_SIGNAL_SAFARI_WEB_ID,
      notifyButton: {
        enable: false,
      },
      allowLocalhostAsSecureOrigin:
        process.env.PUBLIC_ONE_SIGNAL_LOCALHOST_AS_SECURE_ORIGIN === "true",
    });

    // Request notification permission
    const isPushSupported = OneSignal.Notifications.isPushSupported();
    if (isPushSupported) {
      await OneSignal.Notifications.requestPermission();
    }
  } catch (error) {
    console.error("Failed to initialize OneSignal:", error);
  }
};

// Function to check if browser supports push notifications
export const isPushSupported = (): boolean => {
  return OneSignal.Notifications.isPushSupported();
};

// Function to get push notification permission status
export const getPushNotificationPermissionStatus =
  async (): Promise<string> => {
    if (!OneSignal) return "unsupported";
    const permission = OneSignal.Notifications.permissionNative;
    return permission;
  };

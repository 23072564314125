import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useI18n } from "../../../lang/i18n";
import Logo from "../../dashboard/component/logo";

function NavLinkSection({
  sectionId,
  children,
  onClick,
}: {
  sectionId: string;
  children: React.ReactNode;
  onClick?: () => void;
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (onClick) onClick();

    // Check if we're on the root path or a language root path (e.g., /it, /en)
    const isRootPath =
      location.pathname === "/" || /^\/[a-z]{2}$/.test(location.pathname);

    if (isRootPath) {
      // If already on landing page or language root, just scroll
      const element = document.getElementById(sectionId);
      element?.scrollIntoView({ behavior: "smooth" });
    } else {
      // If on another page, navigate first then scroll
      navigate("/");
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        element?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };

  return (
    <a
      href={`#${sectionId}`}
      onClick={handleClick}
      className="text-gray-600 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400 transition-colors"
    >
      {children}
    </a>
  );
}

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useI18n();

  return (
    <header className="fixed top-0 left-0 right-0 z-50 bg-white/80 dark:bg-gray-900/80 backdrop-blur-sm border-b border-gray-200 dark:border-gray-700">
      <nav className="container mx-auto px-6 py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-12">
            <Logo />

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center gap-8">
              <NavLinkSection sectionId="features">
                {t("landing.nav.features")}
              </NavLinkSection>
              <NavLinkSection sectionId="benefits">
                {t("landing.nav.benefits")}
              </NavLinkSection>
              <NavLinkSection sectionId="pricing">
                {t("landing.nav.pricing")}
              </NavLinkSection>
              <NavLinkSection sectionId="faq">
                {t("landing.nav.faq")}
              </NavLinkSection>
            </div>
          </div>

          {/* Desktop Right Navigation */}
          <div className="hidden md:flex items-center gap-4">
            <Link
              to="/contact"
              className="text-gray-600 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400 transition-colors"
            >
              {t("landing.nav.contact")}
            </Link>
            <Link
              to="/auth"
              className="px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors font-medium shadow-sm"
            >
              {t("landing.nav.login")}
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden p-2 text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-100 focus:outline-none"
            aria-label="Toggle menu"
          >
            {!isMenuOpen ? (
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            )}
          </button>
        </div>

        {/* Mobile Menu */}
        <div
          className={`md:hidden ${
            isMenuOpen ? "block" : "hidden"
          } absolute left-0 right-0 top-full bg-white dark:bg-gray-900 border-b border-gray-200 dark:border-gray-700 shadow-lg`}
        >
          <div className="container mx-auto px-6 py-4 flex flex-col gap-4">
            <NavLinkSection
              sectionId="features"
              onClick={() => setIsMenuOpen(false)}
            >
              {t("landing.nav.features")}
            </NavLinkSection>
            <NavLinkSection
              sectionId="benefits"
              onClick={() => setIsMenuOpen(false)}
            >
              {t("landing.nav.benefits")}
            </NavLinkSection>
            <NavLinkSection
              sectionId="pricing"
              onClick={() => setIsMenuOpen(false)}
            >
              {t("landing.nav.pricing")}
            </NavLinkSection>
            <NavLinkSection
              sectionId="faq"
              onClick={() => setIsMenuOpen(false)}
            >
              {t("landing.nav.faq")}
            </NavLinkSection>
            <div className="border-t border-gray-100 dark:border-gray-800 my-2"></div>
            <Link
              to="/contact"
              className="text-gray-600 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400 transition-colors py-2"
              onClick={() => setIsMenuOpen(false)}
            >
              {t("landing.nav.contact")}
            </Link>
            <Link
              to="/auth"
              className="inline-block text-center px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors font-medium shadow-sm"
              onClick={() => setIsMenuOpen(false)}
            >
              {t("landing.nav.login")}
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
}

function ProductHuntBadge() {
  return (
    <a
      href="https://www.producthunt.com/posts/tt-club-manager?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-tt&#0045;club&#0045;manager"
      target="_blank"
      className="flex items-center w-full md:w-auto justify-center"
    >
      <img
        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=587255&theme=light"
        alt="TT&#0032;Club&#0032;Manager - All&#0045;in&#0045;one&#0032;management&#0032;platform&#0032;for&#0032;table&#0032;tennis&#0032;clubs | Product Hunt"
        width={194}
      />
    </a>
  );
}

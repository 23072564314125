import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useClubIdController } from "../../../../../controller/club-id-controller";
import { useMemberController } from "../../../../../controller/member-controller";
import { useI18n } from "../../../../../lang/i18n";
import { DateUtils } from "../../../../../libs/date";
import { Member, UserRole } from "../../../../../model/member";
import Visible from "../../../component/visible";

interface MemberListProps {
  filteredMembers: Member[];
  showMedicalColumn?: boolean;
  showMembershipColumn?: boolean;
}

export default function MemberList({
  filteredMembers,
  showMedicalColumn = true,
  showMembershipColumn = true,
}: MemberListProps) {
  const navigate = useNavigate();
  const { idClub } = useParams();
  const { t } = useI18n();
  const memberController = useMemberController(idClub);
  const clubController = useClubIdController(idClub);

  const isOwner = memberController.currentMember?.user_role_list?.includes(
    UserRole.owner
  );

  const isExpired = (date: string | null | undefined) => {
    try {
      if (!date) return true;
      return new Date(date) < new Date();
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  if (!!clubController.club?.enable_medical_expiration) {
    showMedicalColumn = true;
  } else {
    showMedicalColumn = false;
  }

  if (!!clubController.club?.enable_membership_expiration) {
    showMembershipColumn = true;
  } else {
    showMembershipColumn = false;
  }

  if (!isOwner) {
    showMembershipColumn = false;
    showMedicalColumn = false;
  }

  // Add sorting state
  const [sortColumn, setSortColumn] = useState<string>("");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  // Add sorting function
  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  // Sort members based on current sort state
  const sortedMembers = [...filteredMembers].sort((a, b) => {
    if (!sortColumn) return 0;

    const aValue = a[sortColumn as keyof Member];
    const bValue = b[sortColumn as keyof Member];

    // Handle special cases for date fields
    if (sortColumn.includes("expiration_date")) {
      const aTime = aValue ? new Date(aValue as string).getTime() : 0;
      const bTime = bValue ? new Date(bValue as string).getTime() : 0;
      return sortDirection === "asc" ? aTime - bTime : bTime - aTime;
    }

    // Handle regular string/array comparisons
    if (aValue === bValue) return 0;
    if (aValue === null || aValue === undefined) return 1;
    if (bValue === null || bValue === undefined) return -1;

    const comparison = aValue < bValue ? -1 : 1;
    return sortDirection === "asc" ? comparison : -comparison;
  });

  // Helper function for sort indicator
  const getSortIndicator = (column: string) => {
    if (sortColumn !== column) return "↕";
    return sortDirection === "asc" ? "↑" : "↓";
  };

  return (
    <div className="overflow-x-auto -mx-4 md:mx-0">
      <div className="min-w-full px-4 md:px-0">
        <table className="w-full border-collapse">
          <thead>
            <tr>
              <th
                className="border p-2 md:p-3 text-left cursor-pointer hover:bg-gray-50"
                onClick={() => handleSort("email")}
              >
                {t("general.email")} {getSortIndicator("email")}
              </th>

              <th
                className="border p-2 md:p-3 text-left cursor-pointer hover:bg-gray-50"
                onClick={() => handleSort("first_name")}
              >
                {t("general.first-name")} {getSortIndicator("first_name")}
              </th>

              <th
                className="border p-2 md:p-3 text-left cursor-pointer hover:bg-gray-50"
                onClick={() => handleSort("last_name")}
              >
                {t("general.last-name")} {getSortIndicator("last_name")}
              </th>

              <Visible when={!!isOwner}>
                <th className="border p-2 md:p-3 text-left">
                  {t("members.roles")}
                </th>
              </Visible>

              {showMedicalColumn && (
                <th
                  className="border p-2 md:p-3 text-left cursor-pointer hover:bg-gray-50"
                  onClick={() => handleSort("medical_exam_expiration_date")}
                >
                  {t("members.medical-exam")}{" "}
                  {getSortIndicator("medical_exam_expiration_date")}
                </th>
              )}

              {showMembershipColumn && (
                <th
                  className="border p-2 md:p-3 text-left cursor-pointer hover:bg-gray-50"
                  onClick={() => handleSort("membership_expiration_date")}
                >
                  {t("members.membership")}{" "}
                  {getSortIndicator("membership_expiration_date")}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {sortedMembers?.map((member, index) => (
              <tr
                key={member._id ?? index}
                className={`cursor-pointer ${
                  member._id ? "" : "pointer-events-none opacity-50"
                }`}
                onClick={() => {
                  if (member._id) {
                    navigate(`/club/${idClub}/members/${member._id}`);
                  }
                }}
              >
                <td className="border p-2 md:p-3 break-all">{member.email}</td>

                <td className="border p-2 md:p-3">{member.first_name}</td>

                <td className="border p-2 md:p-3">{member.last_name}</td>

                <Visible when={!!isOwner}>
                  <td className="border p-2 md:p-3">
                    {(member.user_role_list || []).join(", ")}
                  </td>
                </Visible>

                {showMedicalColumn && (
                  <td
                    className={`border p-2 md:p-3 ${
                      isExpired(member.medical_exam_expiration_date)
                        ? "text-red-600"
                        : ""
                    }`}
                  >
                    {DateUtils.dateStringToLocaleDateString(
                      member.medical_exam_expiration_date
                    ) || "N/A"}
                  </td>
                )}

                {showMembershipColumn && (
                  <td
                    className={`border p-2 md:p-3 ${
                      isExpired(member.membership_expiration_date)
                        ? "text-red-600"
                        : ""
                    }`}
                  >
                    {DateUtils.dateStringToLocaleDateString(
                      member.membership_expiration_date
                    ) || "N/A"}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

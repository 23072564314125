import { IconLoader2, IconLock, IconMail } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../controller/auth-controller";
import { getUser } from "../controller/user-controller";
import { useI18n } from "../lang/i18n";
import Logo from "./dashboard/component/logo";

enum AuthView {
  LOGIN,
  SIGNUP,
  OTP,
}

export default function Auth() {
  const navigate = useNavigate();
  const user = getUser({ ignoreException: true });

  useEffect(() => {
    if (!!user?._id) {
      navigate("/club");
    }
  }, [user]);

  const { t } = useI18n();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [view, setView] = useState<AuthView>(AuthView.LOGIN);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center px-6 py-12 background">
      <div className="w-full max-w-md space-y-8">
        <div className="flex flex-col items-center text-center">
          <Logo className="w-48" />
          <h2 className="mt-8 text-3xl font-bold tracking-tight">
            {t("auth.login-to-access-the-dashboard")}
          </h2>
        </div>

        <div className="mt-8 space-y-6">
          <span className="block text-center text-sm text-gray-500 dark:text-gray-400">
            {view === AuthView.LOGIN
              ? t("auth.login-description")
              : t("auth.signup-description")}
          </span>

          {view === AuthView.LOGIN && (
            <LoginView
              setView={setView}
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
            />
          )}
          {view === AuthView.SIGNUP && (
            <SignupView
              setView={setView}
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
            />
          )}
          {view === AuthView.OTP && (
            <OtpView setView={setView} email={email} password={password} />
          )}
        </div>

        <div className="text-sm text-center text-gray-600">
          {t("auth.by-signup-you-agree-to-our")}{" "}
          <button
            data-variant="text"
            onClick={() => window.open("/privacy-policy.md", "_blank")}
            className="text-app-primary hover:text-app-primary/80"
          >
            {t("general.privacy-policy")}
          </button>{" "}
          {t("auth.and")}{" "}
          <button
            data-variant="text"
            onClick={() => window.open("/terms-and-conditions.md", "_blank")}
            className="text-app-primary hover:text-app-primary/80"
          >
            {t("general.terms-of-service")}
          </button>
        </div>
      </div>
    </div>
  );
}

const LoginView = ({
  setView,
  email,
  setEmail,
  password,
  setPassword,
}: {
  setView: (view: AuthView) => void;
  email: string;
  setEmail: (email: string) => void;
  password: string;
  setPassword: (password: string) => void;
}) => {
  const { t, tNode } = useI18n();
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loginError, setLoginError] = useState<string | null>(null);

  const auth = useAuth();

  return (
    <div className="space-y-4">
      <div className="relative">
        <div className="icon-container">
          <IconMail className="icon" />
        </div>
        <input
          type="email"
          data-variant="with-icon"
          placeholder={t("general.email")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full"
        />
      </div>

      <div className="relative">
        <div className="icon-container">
          <IconLock className="icon" />
        </div>
        <input
          type="password"
          data-variant="with-icon"
          placeholder={t("general.password")}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full"
        />
      </div>

      <button
        data-variant="primary"
        className={`w-full ${
          loadingLogin ? "opacity-75 cursor-not-allowed" : ""
        }`}
        onClick={() => {
          setLoadingLogin(true);
          setLoginError(null);
          auth!
            .login(email, password)
            .catch(() => {
              setLoginError(t("auth.login-error"));
            })
            .finally(() => {
              setLoadingLogin(false);
            });
        }}
        disabled={loadingLogin}
      >
        {loadingLogin ? (
          <IconLoader2 className="animate-spin h-5 w-5 mx-auto" />
        ) : (
          t("auth.returning-user-signin")
        )}
      </button>

      {loginError && (
        <div className="text-sm text-red-600 text-center">{loginError}</div>
      )}

      <div className="space-y-4">
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-200"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 background text-gray-500">
              {t("auth.or")}
            </span>
          </div>
        </div>

        <div className="rounded-2xl border border-blue-100 bg-blue-50 dark:border-blue-900 dark:bg-blue-950 p-6">
          <div className="flex gap-4">
            <div className="flex-shrink-0 h-12 w-12 rounded-xl bg-white dark:bg-gray-800 flex items-center justify-center border border-blue-100 dark:border-gray-700">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-blue-600 dark:text-blue-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="space-y-2 flex-1">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                {t("auth.first-time-user-info")}
              </h3>
              <p className="text-sm text-gray-600 dark:text-gray-300 leading-relaxed">
                {t("auth.new-user-guidance")}
              </p>
            </div>
          </div>
        </div>

        <button
          data-variant="secondary"
          className="w-full"
          onClick={() => {
            setView(AuthView.SIGNUP);
          }}
        >
          {t("auth.first-time-signup")}
        </button>

        <div className="flex justify-center">
          <button
            onClick={() => {
              auth!.resetPassword(email);
            }}
            data-variant="text"
            className="text-sm text-gray-600 hover:text-gray-900 w-min whitespace-nowrap"
          >
            {t("auth.reset-password")}
          </button>
        </div>
      </div>
    </div>
  );
};

const SignupView = ({
  setView,
  email,
  setEmail,
  password,
  setPassword,
}: {
  setView: (view: AuthView) => void;
  email: string;
  setEmail: (email: string) => void;
  password: string;
  setPassword: (password: string) => void;
}) => {
  const { t } = useI18n();
  const [emailConfirm, setEmailConfirm] = useState("");
  const [loadingSignup, setLoadingSignup] = useState(false);
  const [signupError, setSignupError] = useState<string | null>(null);

  const auth = useAuth();

  return (
    <div className="space-y-4">
      <div className="rounded-2xl border border-blue-100 bg-blue-50 dark:border-blue-900 dark:bg-blue-950 p-6">
        <div className="flex gap-4">
          <div className="flex-shrink-0 h-12 w-12 rounded-xl bg-white dark:bg-gray-800 flex items-center justify-center border border-blue-100 dark:border-gray-700">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-blue-600 dark:text-blue-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="space-y-2 flex-1">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              {t("auth.first-time-user-info")}
            </h3>
            <p className="text-sm text-gray-600 dark:text-gray-300 leading-relaxed">
              {t("auth.new-user-guidance")}
            </p>
          </div>
        </div>
      </div>

      <div className="relative">
        <div className="icon-container">
          <IconMail className="icon" />
        </div>
        <input
          type="email"
          data-variant="with-icon"
          placeholder={t("general.email")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full"
        />
      </div>

      <div className="relative">
        <div className="icon-container">
          <IconMail className="icon" />
        </div>
        <input
          type="email"
          data-variant="with-icon"
          placeholder={t("auth.email-confirm")}
          value={emailConfirm}
          onChange={(e) => setEmailConfirm(e.target.value)}
          className="w-full"
        />
      </div>

      <div className="relative">
        <div className="icon-container">
          <IconLock className="icon" />
        </div>
        <input
          type="password"
          data-variant="with-icon"
          placeholder={t("general.password")}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full"
        />
      </div>

      <button
        data-variant="primary"
        className={`w-full ${
          loadingSignup ? "opacity-75 cursor-not-allowed" : ""
        }`}
        onClick={() => {
          if (email !== emailConfirm) {
            setSignupError(t("auth.emails-do-not-match"));
            return;
          }

          setLoadingSignup(true);
          setSignupError(null);

          auth!
            .sendEmailVerification(email)
            .then(() => {
              setView(AuthView.OTP);
            })
            .catch(() => {
              setSignupError(t("auth.already-registered"));
            })
            .finally(() => {
              setLoadingSignup(false);
            });
        }}
        disabled={loadingSignup}
      >
        {loadingSignup ? (
          <IconLoader2 className="animate-spin h-5 w-5 mx-auto" />
        ) : (
          t("auth.signup")
        )}
      </button>

      {signupError && (
        <div className="text-sm text-red-600 text-center">{signupError}</div>
      )}

      <div className="flex justify-center">
        <button data-variant="text" onClick={() => setView(AuthView.LOGIN)}>
          {t("auth.back-to-login")}
        </button>
      </div>
    </div>
  );
};

export const OtpView = ({
  email,
  password,
  setView,
}: {
  email: string;
  password: string;
  setView: (view: AuthView) => void;
}) => {
  const { t } = useI18n();
  const [otp, setOtp] = useState("");
  const [loadingSignup, setLoadingSignup] = useState(false);
  const [signupError, setSignupError] = useState<string | null>(null);
  const [resendOtpLoading, setResendOtpLoading] = useState(false);

  const auth = useAuth();

  return (
    <div className="space-y-4">
      <div className="text-center">
        <span className="font-normal text-gray-600">{t("auth.enter-otp")}</span>{" "}
        <span className="italic underline text-md text-gray-900">{email}</span>
      </div>

      <div className="relative">
        <div className="icon-container">
          <IconLock className="icon" />
        </div>
        <input
          type="text"
          data-variant="with-icon"
          placeholder={t("general.otp")}
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          className="w-full"
        />
      </div>

      <button
        data-variant="primary"
        className="w-full"
        disabled={loadingSignup}
        onClick={() => {
          setLoadingSignup(true);
          setSignupError(null);

          auth!
            .signup(email, password, otp)
            .catch(() => {
              setSignupError(t("auth.already-registered"));
            })
            .finally(() => {
              setLoadingSignup(false);
            });
        }}
      >
        {t("auth.verify-otp")}
      </button>

      {signupError && (
        <div className="text-sm text-red-600 text-center">{signupError}</div>
      )}

      <div className="flex justify-center items-center">
        <button
          data-variant="text"
          disabled={resendOtpLoading}
          onClick={() => {
            setResendOtpLoading(true);
            auth.sendEmailVerification(email);
          }}
        >
          {t("auth.resend-otp")}
        </button>

        <span className="mx-2">{t("auth.or")}</span>

        <button data-variant="text" onClick={() => setView(AuthView.LOGIN)}>
          {t("auth.back-to-login")}
        </button>
      </div>
    </div>
  );
};

import { IconCircleXFilled, IconPlus, IconTrash } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useClubIdController } from "../../../../controller/club-id-controller";
import { useMemberController } from "../../../../controller/member-controller";
import { useI18n } from "../../../../lang/i18n";
import { getAllRoles, UserRole } from "../../../../model/member";
import Visible from "../../component/visible";

export default function MemberPage() {
  const { idClub, idMember } = useParams();
  const navigate = useNavigate();

  const memberController = useMemberController(idClub);
  const { t } = useI18n();
  const clubController = useClubIdController(idClub);

  const [medicalExam, setMedicalExam] = useState("");
  const [membership, setMembership] = useState("");
  const [hasChanges, setHasChanges] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [isRoleLoading, setIsRoleLoading] = useState(false);
  const [loadingRoleId, setLoadingRoleId] = useState<string | null>(null);

  const member = memberController.memberList?.find((m) => m._id === idMember);

  const isOwner = memberController.currentMember?.user_role_list.includes(
    UserRole.owner
  );

  useEffect(() => {
    setMedicalExam(member?.medical_exam_expiration_date || "");
    setMembership(member?.membership_expiration_date || "");
    setFirstName(member?.first_name || "");
    setLastName(member?.last_name || "");
  }, [member]);

  if (!member) return <div>{t("general.loading")}</div>;

  const userRoleList = member.user_role_list ?? [];

  const allRoles = getAllRoles();

  const handleDeleteMember = async () => {
    if (!window.confirm(t("members.delete-confirmation"))) {
      return;
    }

    try {
      await memberController.remove(idMember!);
      navigate(`/club/${idClub}/members`);
    } catch (error) {
      alert(t("members.delete-error"));
    }
  };

  const handleAddRole = async (role: string) => {
    if (!role) return;
    setIsRoleLoading(true);
    try {
      await memberController.patchRole({
        idClub: idClub!,
        idMember: idMember!,
        role,
      });
    } catch (error) {
      alert(t("general.save-error"));
    } finally {
      setIsRoleLoading(false);
    }
  };

  const handleRemoveRole = async (role: string) => {
    setLoadingRoleId(role);
    try {
      await memberController.removeRole({
        idClub: idClub!,
        idMember: idMember!,
        role,
      });
    } catch (error) {
      alert(t("general.save-error"));
    } finally {
      setLoadingRoleId(null);
    }
  };

  return (
    <div className="p-4 md:p-6 space-y-4 md:space-y-6">
      <h1 className="text-xl md:text-2xl font-bold mb-4 md:mb-6">
        {t("general.details")}
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-4">
          <div>
            <h3 className="font-semibold mb-2 text-sm">
              {t("general.first-name")}
            </h3>
            <input
              type="text"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
                setHasChanges(true);
              }}
              className="border rounded-md p-2 w-full"
            />
          </div>

          <div>
            <h3 className="font-semibold mb-2 text-sm">
              {t("general.last-name")}
            </h3>
            <input
              type="text"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
                setHasChanges(true);
              }}
              className="border rounded-md p-2 w-full"
            />
          </div>

          <div>
            <h3 className="font-semibold mb-2 text-sm">{t("general.email")}</h3>
            <p className="text-gray-700">{member.email}</p>
          </div>

          <Visible
            when={!!isOwner && !!clubController.club?.enable_medical_expiration}
          >
            <div>
              <h3 className="font-semibold mb-2 text-sm">
                {t("members.medical-exam-expiration")}{" "}
                <span className="text-gray-500">(Optional)</span>
              </h3>
              <input
                type="date"
                value={medicalExam}
                onChange={(e) => {
                  setMedicalExam(e.target.value);
                  setHasChanges(true);
                }}
                placeholder="YYYY-MM-DD"
                className="border rounded-md p-2"
              />
            </div>
          </Visible>

          <Visible
            when={
              !!isOwner && !!clubController.club?.enable_membership_expiration
            }
          >
            <div>
              <h3 className="font-semibold mb-2 text-sm">
                {t("members.membership-expiration")}{" "}
                <span className="text-gray-500">(Optional)</span>
              </h3>
              <input
                type="date"
                value={membership}
                onChange={(e) => {
                  setMembership(e.target.value);
                  setHasChanges(true);
                }}
                placeholder="YYYY-MM-DD"
                className="border rounded-md p-2"
              />
            </div>
          </Visible>

          <Visible when={!!isOwner}>
            <div className="flex flex-col gap-2">
              <button
                disabled={!hasChanges}
                onClick={async () => {
                  try {
                    await memberController.patch(idMember!, {
                      medical_exam_expiration_date:
                        medicalExam == "" ? null : medicalExam,
                      membership_expiration_date:
                        membership == "" ? null : membership,
                      first_name: firstName || undefined,
                      last_name: lastName || undefined,
                    });
                    setHasChanges(false);
                    setSaveSuccess(true);
                    // Hide success message after 3 seconds
                    setTimeout(() => setSaveSuccess(false), 3000);
                  } catch (error) {
                    alert(t("general.save-error"));
                  }
                }}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {t("general.save")}
              </button>

              {saveSuccess && (
                <span className="text-green-600">
                  {t("general.save-success")}
                </span>
              )}
            </div>
          </Visible>
        </div>

        <div className="space-y-4">
          <Visible when={!!isOwner}>
            <div>
              <h3 className="font-semibold mb-2 text-sm dark:text-gray-200">
                {t("members.roles")}
              </h3>
              <div className="flex flex-wrap items-center gap-2">
                {userRoleList.map((role) => (
                  <span
                    key={role}
                    className="px-3 py-1.5 text-sm rounded-md bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-200 flex items-center gap-2 transition-all hover:bg-gray-200 dark:hover:bg-gray-600"
                  >
                    {role}
                    <button
                      onClick={() => handleRemoveRole(role)}
                      disabled={loadingRoleId === role}
                      className="hover:text-gray-900 dark:hover:text-gray-100 disabled:opacity-50 flex items-center"
                    >
                      {loadingRoleId === role ? (
                        <div className="w-3 h-3 border-2 border-gray-600 dark:border-gray-300 border-t-transparent rounded-full animate-spin" />
                      ) : (
                        <IconCircleXFilled
                          size={14}
                          className="text-gray-500 dark:text-gray-400"
                        />
                      )}
                    </button>
                  </span>
                ))}

                <div className="relative inline-flex items-center">
                  <select
                    className={`text-sm h-[34px] border rounded-md px-3 pr-8 appearance-none bg-white dark:bg-gray-800 dark:text-gray-200 dark:border-gray-600
                      hover:border-gray-400 dark:hover:border-gray-500 focus:border-gray-500 dark:focus:border-gray-400 focus:ring-1 focus:ring-gray-500 dark:focus:ring-gray-400 outline-none
                      ${
                        isRoleLoading
                          ? "opacity-50 cursor-not-allowed"
                          : "cursor-pointer"
                      }`}
                    onChange={(e) => handleAddRole(e.target.value)}
                    disabled={isRoleLoading}
                    value=""
                  >
                    <option value="">{t("members.add-role")}</option>
                    {allRoles.map(
                      (role) =>
                        !userRoleList.includes(role) && (
                          <option key={role} value={role}>
                            {role}
                          </option>
                        )
                    )}
                  </select>
                  <div className="absolute right-2 top-1/2 -translate-y-1/2 pointer-events-none">
                    {isRoleLoading ? (
                      <div className="w-4 h-4 border-2 border-gray-400 dark:border-gray-500 border-t-transparent rounded-full animate-spin" />
                    ) : (
                      <IconPlus
                        size={14}
                        className="text-gray-400 dark:text-gray-500"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Visible>
        </div>
      </div>

      <Visible when={!!isOwner}>
        <div className="mt-8 pt-8 border-t">
          <h3 className="text-red-600 font-semibold mb-4 text-sm">
            {t("settings.danger-zone")}
          </h3>
          <button
            onClick={handleDeleteMember}
            className="flex items-center gap-2 px-4 py-2 bg-red-100 text-red-700 rounded-md hover:bg-red-200"
          >
            <IconTrash size={18} />
            {t("members.delete-member")}
          </button>
        </div>
      </Visible>
    </div>
  );
}

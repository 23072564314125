import { useI18n } from "../../../lang/i18n";

import { IconMenu, IconSwitchHorizontal, IconX } from "@tabler/icons-react";
import { useEffect, useMemo, useState } from "react";
import { Link, Outlet, useNavigation, useParams } from "react-router-dom";
import { useClubController } from "../../../controller/club-controller";
import { useMemberController } from "../../../controller/member-controller";
import { UserRole } from "../../../model/member";
import Logo from "../component/logo";
import Visible from "../component/visible";

type Link = {
  name: string;
  href: string;
  key: string;
};

const href = (idClub: string, href: string) => `/club/${idClub}${href}`;

export default function ClubLayout() {
  const { idClub } = useParams();

  const { t } = useI18n();

  const { currentMember } = useMemberController(idClub);
  const isOwner = currentMember?.user_role_list.includes(UserRole.owner);

  const items = useMemo<Link[]>(
    () => [
      { name: t("reports.title"), href: `/reports`, key: "reports" },
      { name: t("members.title"), href: "/members", key: "members" },
      { name: t("trainings.title"), href: "/trainings", key: "trainings" },
      { name: t("news.title"), href: "/news", key: "news" },
      { name: t("data.title"), href: "/data", key: "data" },
      { name: t("settings.title"), href: "/settings", key: "settings" },
    ],
    [t]
  );

  let links = [...items];

  if (!isOwner) {
    links = links.filter((link) => link.key !== "data");
  }

  if (!idClub) {
    return <div>Club not found</div>;
  }

  return (
    <div className="flex h-screen w-full overflow-hidden">
      <DashboardNavbarMd idClub={idClub} links={links} />

      <div className="flex-1 flex flex-col w-full overflow-hidden">
        <DashboardNavbarMobile idClub={idClub} links={links} />
        <main className="flex-1 overflow-auto">
          <div className="px-4 py-6 md:px-6">
            <div className="w-full max-w-[1200px] mx-auto">
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

const DashboardNavbarMd = ({
  idClub,
  links,
}: {
  idClub: string;
  links: Link[];
}) => {
  const { t } = useI18n();
  const navigation = useNavigation();
  const { clubList = [] } = useClubController();

  const currentClub = clubList.find((club) => club._id === idClub);

  const pathname = navigation.location?.pathname;

  return (
    <header className="hidden md:flex w-[280px] h-screen flex-shrink-0 flex-col border-r border bg-white dark:bg-gray-900">
      <div className="p-4 border-b">
        <Logo className="h-6 object-cover" href="/club" />
      </div>

      {currentClub && (
        <div className="px-8 py-3 border-b">
          <div className="flex items-center justify-between">
            <h2 className="font-medium text-gray-600 dark:text-gray-300 truncate text-sm">
              {currentClub.name}
            </h2>
            <Visible when={clubList?.length > 1}>
              <Link
                to="/club"
                className="p-1.5 rounded-md hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
                aria-label={t("club-selection.change-club")}
              >
                <IconSwitchHorizontal className="w-4 h-4 text-gray-500" />
              </Link>
            </Visible>
          </div>
        </div>
      )}

      <nav className="flex-1 overflow-y-auto p-4 space-y-1">
        {links.map((item) => (
          <DashboardNavbarItem
            key={item.name}
            name={item.name}
            href={href(idClub, item.href)}
            active={pathname?.startsWith(href(idClub, item.href)) ?? false}
          />
        ))}
      </nav>

      <Footer />
    </header>
  );
};

const DashboardNavbarMobile = ({
  idClub,
  links,
}: {
  idClub: string;
  links: Link[];
}) => {
  const navigation = useNavigation();
  const { t } = useI18n();

  const { clubList = [] } = useClubController();
  const pathname = navigation.location?.pathname;
  const [opened, setOpened] = useState(false);

  // Prevent body scroll when menu is open
  useEffect(() => {
    if (opened) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [opened]);

  const currentClub = clubList.find((club) => club._id === idClub);

  return (
    <>
      <header className="md:hidden border-b sticky top-0 z-40 bg-white dark:bg-gray-900">
        <div className="px-4 py-4 flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <button
              className="p-2.5 -ml-2.5 rounded-lg transition-colors"
              onClick={() => setOpened(!opened)}
              aria-label="Toggle menu"
            >
              <IconMenu className="h-6 w-6 text-gray-700" />
            </button>
            <div className="flex flex-col">
              <Logo className="h-6 object-cover" href="/club" />
            </div>
          </div>
        </div>
      </header>

      {/* Mobile menu overlay with transition */}
      <div
        className={`md:hidden fixed inset-0 z-50 transition-opacity duration-300 ease-in-out ${
          opened
            ? "opacity-100 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        }`}
      >
        {/* Replace blur overlay with semi-transparent overlay */}
        <div
          className="absolute inset-0 bg-black/20"
          onClick={() => setOpened(false)}
        />

        {/* Update menu background */}
        <div
          className={`absolute inset-y-0 left-0 w-[280px] bg-white dark:bg-gray-900 shadow-xl transition-transform duration-300 ease-in-out ${
            opened ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <div className="flex flex-col h-full">
            <div className="p-4 border-b border flex justify-between items-center">
              <div className="flex flex-col">
                <Logo className="h-6 object-cover" href="/club" />
              </div>
              <button
                className="p-2.5 -mr-2.5 rounded-lg transition-colors"
                onClick={() => setOpened(false)}
                aria-label="Close menu"
              >
                <IconX className="h-6 w-6 text-gray-700" />
              </button>
            </div>

            {currentClub && (
              <div className="px-8 py-3 border-b">
                <div className="flex items-center justify-between">
                  <h2 className="font-medium text-gray-600 dark:text-gray-300 truncate text-sm">
                    {currentClub.name}
                  </h2>
                  <Visible when={clubList?.length > 1}>
                    <Link
                      to="/club"
                      className="p-1.5 rounded-md hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
                      aria-label={t("club-selection.change-club")}
                      onClick={() => setOpened(false)}
                    >
                      <IconSwitchHorizontal className="w-4 h-4 text-gray-500" />
                    </Link>
                  </Visible>
                </div>
              </div>
            )}

            <nav className="flex-1 overflow-y-auto p-4 space-y-1.5">
              {links.map((item) => (
                <DashboardNavbarItem
                  key={item.name}
                  name={item.name}
                  href={href(idClub, item.href)}
                  active={
                    pathname?.startsWith(href(idClub, item.href)) ?? false
                  }
                  onClick={() => setOpened(false)}
                />
              ))}
            </nav>

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

type NavbarItem = {
  name: string;
  href: string;
  active: boolean;
  onClick?: () => void;
};

const DashboardNavbarItem = ({ name, href, active, onClick }: NavbarItem) => {
  return (
    <Link
      to={href}
      onClick={onClick}
      data-active={active}
      className="sidebar-link"
    >
      {name}
    </Link>
  );
};

const Footer = () => {
  const { t } = useI18n();

  return (
    <footer className="border-t p-4">
      <div className="space-y-4 text-sm">
        <section className="flex justify-between items-center py-1">
          <span className="text-gray-600 font-medium">{t("support")}</span>
          <Link
            to={`/contact`}
            className="px-2 py-1 -mr-2 rounded-md text-gray-500 hover:text-gray-900 transition-colors"
          >
            {t("contact-us")}
          </Link>
        </section>
      </div>
    </footer>
  );
};

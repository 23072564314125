import { useEffect, useMemo, useState } from "react";
import { useI18n } from "../../../lang/i18n";

const allImages = [
  {
    path: "/screenshot/chose-club.png",
    description: "landing.screenshots.chose_club",
  },
  {
    path: "/screenshot/members.png",
    description: "landing.screenshots.members",
  },
  {
    path: "/screenshot/import-members.png",
    description: "landing.screenshots.import_members",
  },
  {
    path: "/screenshot/trainings.png",
    description: "landing.screenshots.trainings",
  },
  {
    path: "/screenshot/new-report.png",
    description: "landing.screenshots.new_report",
  },
  {
    path: "/screenshot/news.png",
    description: "landing.screenshots.news",
  },
  { path: "/screenshot/data.png", description: "landing.screenshots.data" },
  {
    path: "/screenshot/settings.png",
    description: "landing.screenshots.settings",
  },
];

export default function SliderPictures({ id }: { id: string }) {
  const [refreshImages, setRefreshImages] = useState(0);
  const { t } = useI18n();

  const images = useMemo(() => {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      return allImages.map((image) => ({
        ...image,
        path: image.path.replace(".png", "-d.png"),
      }));
    }

    return allImages.map((image) => ({
      ...image,
      path: image.path.replace(".png", "-l.png"),
    }));
  }, [refreshImages]);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleColorSchemeChange = () => {
      setRefreshImages((prev) => prev + 1);
    };

    mediaQuery.addEventListener("change", handleColorSchemeChange);

    return () => {
      mediaQuery.removeEventListener("change", handleColorSchemeChange);
    };
  }, []);

  return (
    <div className="relative max-w-[95vw] mx-auto" id={id}>
      {images.length > 0 && (
        <div className="relative">
          <div className="absolute left-0 top-0 bottom-4 w-12 sm:w-16 bg-gradient-to-r from-white/90 to-transparent z-10 pointer-events-none dark:from-gray-900/90" />
          <div className="absolute right-0 top-0 bottom-4 w-12 sm:w-16 bg-gradient-to-l from-white/90 to-transparent z-10 pointer-events-none dark:from-gray-900/90" />

          <div className="overflow-x-auto scrollbar-hide flex space-x-4 sm:space-x-6 px-4 sm:px-8 snap-x snap-mandatory">
            {images.map((image, index) => (
              <div
                key={index}
                className="relative flex-shrink-0 snap-center group cursor-pointer"
                onClick={() => window.open(image.path, "_blank")}
              >
                <img
                  src={image.path}
                  alt={`Gallery image ${index + 1}`}
                  className="object-cover w-[280px] sm:w-[800px] rounded-xl shadow-[0_4px_12px_rgba(0,0,0,0.04)] dark:shadow-lg h-[201px] sm:h-[576px]
                    border border-gray-200 dark:border-gray-800"
                  loading={index === 0 ? "eager" : "lazy"}
                />

                <div
                  className="absolute bottom-4 left-1/2 -translate-x-1/2 bg-black/60 text-white 
                  px-3 sm:px-4 py-1 rounded-full text-xs sm:text-sm backdrop-blur-sm"
                >
                  {index + 1} / {images.length}
                </div>

                <div className="absolute bottom-8 left-4 sm:left-8 right-4 sm:right-8 text-gray-700 dark:text-white text-sm sm:text-xl font-semibold">
                  {t(image.description)}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

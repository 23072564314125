import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useReportIdController } from "../../../../controller/report-id-controller";
import { useI18n } from "../../../../lang/i18n";
import { DateUtils } from "../../../../libs/date";
import { debounce } from "../../../../libs/debouncer";
import {
  Report,
  ReportType,
  TeamKey,
  getSetKeyFromNumber,
  matchRelations,
} from "../../../../model/report";
import { DayInfoComponent } from "./_components/day-info";
import { MatchesInput } from "./_components/matches-input";
import { SignatureModal } from "./_components/signature-modal";
import { Signatures } from "./_components/signatures";
import { TeamInput } from "./_components/team-input";
import {
  SignatureFieldName,
  signatureMapping,
  textMapping,
} from "./_models/map";
import {
  miniSwaythlingJson,
  reportMiniSwaythlingKeys,
} from "./_models/mini-swaythling";
import {
  miniSwaythlingDoubleJson,
  reportMiniSwaythlingDoubleKeys,
} from "./_models/mini-swaythling-double";
import { reportSwaythlingKeys, swaythlingJson } from "./_models/swaythling";
import { ReportActions } from "./report-actions";

const createDebouncedSave = (
  save: (data: Report) => Promise<void>,
  setLoading: (loading: boolean) => void
) => {
  return debounce((data: Report) => {
    setLoading(true);
    save(data).finally(() => {
      setLoading(false);
    });
  }, 2000);
};

export default function ReportPage() {
  const { idClub, idReport } = useParams();

  const width = 512;

  const controller = useReportIdController(idClub, idReport);

  const [imageBytes, setImageBytes] = useState<ArrayBuffer | null>(null);

  const [data, update] = useState<Report>(controller.report ?? new Report());
  const [selectedSignatureField, setSelectedSignatureField] =
    useState<SignatureFieldName | null>(null);

  const [showPreview, setShowPreview] = useState(false);
  const [debounceLoading, setDebounceLoading] = useState(false);

  const debouncedSave = useRef(
    createDebouncedSave(controller.save.bind(controller), setDebounceLoading)
  );

  useEffect(() => {
    return () => {
      debouncedSave.current.cancel();
    };
  }, []);

  const setData = useCallback(
    (newData: Report) => {
      update(newData);
      debouncedSave.current(newData);
    },
    [debouncedSave]
  );

  useEffect(() => {
    if (controller.report) {
      setData(controller.report);
    }
  }, [controller.report]);

  const documentParams = () => {
    let originalWidth: number;
    let originalHeight: number;
    let path: string;
    let keys: string[];
    let json: any;

    switch (data?.type) {
      case ReportType.swaythling:
        originalWidth = swaythlingJson.width;
        originalHeight = swaythlingJson.height;
        path = "/reports/swaythling.jpg";
        keys = reportSwaythlingKeys;
        json = swaythlingJson;
        break;
      case ReportType.miniSwaythling:
        originalWidth = miniSwaythlingJson.width;
        originalHeight = miniSwaythlingJson.height;
        path = "/reports/mini-swaythling.jpg";
        keys = reportMiniSwaythlingKeys;
        json = miniSwaythlingJson;
        break;
      case ReportType.miniSwaythlingDoppio:
        originalWidth = miniSwaythlingDoubleJson.width;
        originalHeight = miniSwaythlingDoubleJson.height;
        path = "/reports/mini-swaythling-doppio.jpg";
        keys = reportMiniSwaythlingDoubleKeys;
        json = miniSwaythlingDoubleJson;
        break;
    }

    return {
      originalWidth: originalWidth!,
      originalHeight: originalHeight!,
      path: path!,
      keys: keys!,
      json: json!,
    };
  };

  const getCanvas = () => {
    const { originalWidth, originalHeight } = documentParams();

    const canvas = document.createElement("canvas");
    canvas.width = originalWidth!;
    canvas.height = originalHeight!;

    return canvas;
  };

  const getContext = async (canvas: HTMLCanvasElement) => {
    const { path } = documentParams();

    const image = new Image();
    image.src = path!;

    await new Promise((resolve) => {
      image.onload = resolve;
    });

    const ctx = canvas.getContext("2d");

    if (!ctx) {
      throw new Error("Could not get 2d context");
    }

    ctx.textAlign = "left";
    ctx.textBaseline = "top";
    ctx.font = "40px Arial";

    const { originalWidth, originalHeight } = documentParams();

    ctx.drawImage(image, 0, 0, originalWidth!, originalHeight!);

    return ctx;
  };

  useEffect(() => {
    const trigger = async () => {
      const canvas = getCanvas();
      const ctx = await getContext(canvas);

      const { keys } = documentParams();

      for (const key of keys) {
        const value = textMapping(data!)[key];
        drawText(ctx, key, value ?? "");

        const dataUrl = signatureMapping(data!)[key];
        if (dataUrl) {
          await drawImage(ctx, key, dataUrl!);
        }
      }

      const dataUrl = canvas.toDataURL("image/jpeg");

      const response = await fetch(dataUrl);
      const blob = await response.blob();

      const arrayBuffer = await new Response(blob).arrayBuffer();

      setImageBytes(arrayBuffer);
    };

    trigger();
  }, [data]);

  const drawText = (
    ctx: CanvasRenderingContext2D,
    field: string,
    text: string
  ) => {
    ctx.font = "32px Arial";

    const { json } = documentParams();

    const textField = json.children[0].children.find(
      (child: any) => child.name === field
    );

    const xTextPosition = textField?.x;
    const yTextPosition = textField?.y;

    ctx.textBaseline = "top";
    ctx.fillText(text, xTextPosition!, yTextPosition!);
  };

  const drawImage = async (
    ctx: CanvasRenderingContext2D,
    field: string,
    dataUrl: string
  ) => {
    const image = new Image();
    image.src = dataUrl;

    const { json } = documentParams();

    const imageField = json.children[0].children.find(
      (child: any) => child.name === field
    );
    const xImagePosition = imageField?.x;
    const yImagePosition = imageField?.y;
    const height = imageField?.height;

    await new Promise((resolve) => {
      image.onload = resolve;
    });

    const aspectRatio = image.width / image.height;
    const newHeight = height;
    const newWidth = height * aspectRatio;

    ctx.drawImage(
      image,
      xImagePosition!,
      yImagePosition!,
      newWidth,
      newHeight!
    );
  };

  const { t } = useI18n();

  return (
    <div className="min-h-screen flex flex-col dark:bg-gray-900">
      {/* Mobile Toggle */}
      <div className="md:hidden sticky top-0 z-10 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 p-2">
        <div className="flex gap-2">
          <button
            className={`flex-1 py-2 px-4 rounded-lg transition-colors ${
              !showPreview
                ? "bg-blue-500 text-white"
                : "bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
            }`}
            onClick={() => setShowPreview(false)}
          >
            {t("reports.form")}
          </button>
          <button
            className={`flex-1 py-2 px-4 rounded-lg transition-colors ${
              showPreview
                ? "bg-blue-500 text-white"
                : "bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
            }`}
            onClick={() => setShowPreview(true)}
          >
            {t("reports.preview")}
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 md:grid md:grid-cols-2 gap-6 p-4 md:p-6">
        {/* Left column - Form content */}
        <div
          className={`flex flex-col gap-4 ${
            showPreview ? "hidden md:flex" : "flex"
          }`}
        >
          <span className="text-sm text-gray-600 dark:text-gray-400">
            {t("reports.updated-at")}{" "}
            {DateUtils.dateStringToLocaleDateTimeString(
              controller.updatedAt.toISOString()
            )}
          </span>

          <ReportActions
            debounceLoading={debounceLoading}
            data={data}
            setData={setData}
            imageBytes={imageBytes}
          />

          {/* Form inputs */}
          <div className="flex flex-col gap-4 max-w-full">
            <input
              className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 text-sm md:text-base"
              placeholder={t("reports.name")}
              value={data.name ?? ""}
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
              }}
            />

            <DayInfoComponent report={data} setData={setData} />

            <button
              className="w-full bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-100 px-4 py-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors text-sm md:text-base"
              onClick={() => {
                const prev = data;

                setData({
                  ...data,
                  teams: {
                    ...data.teams,
                    [TeamKey.ABC]: data.teams[TeamKey.ABC],
                    [TeamKey.XYZ]: data.teams[TeamKey.XYZ],
                  },
                });

                // invert sets of matches
                let matches = prev.matches;

                for (const relation of matchRelations) {
                  const match = { ...matches[relation.key] };

                  const sets = { ...match.sets };

                  for (let i = 1; i <= 5; i++) {
                    const setKey = getSetKeyFromNumber(i);

                    const pointsABC = sets[setKey].points_abc;
                    const pointsXYZ = sets[setKey].points_xyz;

                    sets[setKey] = {
                      points_abc: pointsXYZ,
                      points_xyz: pointsABC,
                    };
                  }

                  match.sets = {
                    ...sets,
                  };

                  matches = {
                    ...matches,
                    [relation.key]: match,
                  };
                }

                return {
                  ...prev,
                  teams: {
                    ...prev.teams,
                    [TeamKey.ABC]: prev.teams[TeamKey.XYZ],
                    [TeamKey.XYZ]: prev.teams[TeamKey.ABC],
                  },
                  matches: {
                    ...matches,
                  },
                };
              }}
            >
              {t("reports.invert-teams")}
            </button>

            <TeamInput
              teamKey={TeamKey.ABC}
              setData={setData}
              report={data}
              fitet={true}
            />

            <TeamInput
              teamKey={TeamKey.XYZ}
              setData={setData}
              report={data}
              fitet={true}
            />

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 text-sm md:text-base"
                placeholder={t("reports.match-start-time")}
                value={data.day_info.match_start_time}
                onChange={(e) => {
                  setData({
                    ...data,
                    day_info: {
                      ...data.day_info,
                      match_start_time: e.target.value,
                    },
                  });
                }}
              />

              <input
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 text-sm md:text-base"
                placeholder={t("reports.match-end-time")}
                value={data.day_info.match_end_time}
                onChange={(e) => {
                  setData({
                    ...data,
                    day_info: {
                      ...data.day_info,
                      match_end_time: e.target.value,
                    },
                  });
                }}
              />
            </div>

            <MatchesInput data={data} setData={setData} />

            <Signatures
              data={data}
              setSelectedSignatureField={setSelectedSignatureField}
            />
          </div>
        </div>

        {/* Right column - Preview */}
        <div
          className={`relative ${!showPreview ? "hidden md:block" : "block"}`}
        >
          <div className="md:sticky md:top-6">
            <img
              className="w-full h-auto rounded-lg shadow-lg dark:shadow-gray-900"
              alt="Rendered report"
              src={
                !!imageBytes
                  ? URL.createObjectURL(new Blob([imageBytes]))
                  : "/assets/swaythling/original.jpg"
              }
              width={width}
              height={(width * 3) / 4}
            />
            <div className="md:hidden mt-4 px-4">
              <button
                className="w-full bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition-colors"
                onClick={() => {
                  const a = document.createElement("a");
                  a.href = URL.createObjectURL(new Blob([imageBytes!]));
                  a.download = "report.jpg";
                  a.click();
                }}
              >
                {t("general.download")}
              </button>
            </div>
          </div>
        </div>

        <SignatureModal
          field={selectedSignatureField!}
          onSign={(field, url) => {
            setData({
              ...data,
              day_info: { ...data.day_info, [field]: url },
            });
          }}
          onRemove={(field) => {
            setData({
              ...data,
              day_info: { ...data.day_info, [field]: "" },
            });
          }}
        />
      </div>
    </div>
  );
}

import { IconLoader2, IconRefresh } from "@tabler/icons-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useReportController } from "../../../../controller/report-controller";
import { useI18n } from "../../../../lang/i18n";
import { queryClient } from "../../../../libs/react-query";
import { ReportType, reportTypeNameFromKey } from "../../../../model/report";

export default function ReportListPage() {
  const navigate = useNavigate();
  const { idClub } = useParams();
  const controller = useReportController(idClub);
  const [type, setType] = useState<ReportType>(ReportType.swaythling);
  const [name, setName] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const { t } = useI18n();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (name) {
      setIsCreating(true);
      try {
        const idReport = await controller.onNewClick(name, type);
        navigate(`/club/${idClub}/reports/${idReport}`);
        setName("");
        setShowForm(false);
      } finally {
        setIsCreating(false);
      }
    }
  };

  return (
    <div className="p-6 space-y-6">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center justify-between w-full gap-2">
          <h1 className="text-2xl font-bold">{t("reports.title")}</h1>

          <div id="actions" className="flex gap-4 items-center">
            <button
              onClick={() => queryClient.invalidateQueries(["report"])}
              className="p-1 hover:bg-gray-100 rounded-full"
            >
              <IconRefresh size={20} className="text-gray-600" />
            </button>

            <button
              onClick={() => setShowForm(!showForm)}
              className="bg-app-primary px-4 py-2 rounded-md"
            >
              {showForm ? t("general.cancel") : t("general.new")}
            </button>
          </div>
        </div>
      </div>

      {showForm && (
        <form onSubmit={handleSubmit} className="space-y-4 mb-8">
          <div className="flex flex-col gap-2">
            <label htmlFor="name" className="font-medium">
              {t("reports.name")}
            </label>
            <input
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="border rounded-md p-2"
              required
            />
          </div>

          <div className="flex flex-col gap-2">
            <label htmlFor="type" className="font-medium">
              {t("reports.type")}
            </label>
            <select
              id="type"
              className="border rounded-md p-2"
              onChange={(e) => setType(e.target.value as ReportType)}
              value={type}
            >
              {Object.values(ReportType).map((value) => (
                <option key={value} value={value}>
                  {reportTypeNameFromKey(value)}
                </option>
              ))}
            </select>
          </div>

          <button
            type="submit"
            className="bg-app-primary px-4 py-2 rounded-md flex items-center gap-2"
            disabled={isCreating}
          >
            {isCreating ? (
              <IconLoader2 className="animate-spin-progress" size={20} />
            ) : (
              t("general.create")
            )}
          </button>
        </form>
      )}

      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse">
          <thead>
            <tr>
              <th className="border p-3 text-left">{t("reports.name")}</th>
              <th className="border p-3 text-left">{t("reports.serie")}</th>
              <th className="border p-3 text-left">{t("reports.team-home")}</th>
              <th className="border p-3 text-left">{t("reports.team-away")}</th>
              <th className="border p-3 text-left">
                {t("reports.updated-at")}
              </th>
            </tr>
          </thead>
          <tbody>
            {controller.reportList?.map((report) => (
              <tr
                key={report._id?.toString()}
                onClick={() =>
                  navigate(`/club/${idClub}/reports/${report._id}`)
                }
                className="cursor-pointer"
              >
                <td className="border p-3">{report.name}</td>
                <td className="border p-3">{report.day_info.serie}</td>
                <td className="border p-3">{report.team_home?.name}</td>
                <td className="border p-3">{report.team_away?.name}</td>
                <td className="border p-3">
                  {new Date(report.updated_at).toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

import { useI18n } from "../../../../../lang/i18n";
import { PlayerKey, Report, TeamKey } from "../../../../../model/report";
import { PlayerInput } from "./player-input";

export interface TeamInputProps {
  teamKey: TeamKey;
  report: Report;
  fitet: boolean;
  setData: (data: Report) => void;
}

export const TeamInput = ({
  teamKey,
  report,
  fitet,
  setData,
}: TeamInputProps) => {
  const { t } = useI18n();

  const update = (partial: Partial<Report>) => {
    setData({
      ...report,
      ...partial,
    });
  };

  return (
    <div className="mt-2">
      <span className="font-bold">{teamKey}</span>

      <input
        placeholder={t("reports.name")}
        className="mt-2"
        value={report.teams[teamKey].name}
        onChange={(e) => {
          update({
            teams: {
              ...report.teams,
              [teamKey]: {
                ...report.teams[teamKey],
                name: e.target.value,
              },
            },
          });
        }}
      />

      <input
        placeholder={t("reports.color")}
        value={report.teams[teamKey].color}
        onChange={(e) => {
          let team = report.teams[teamKey];

          team.color = e.target.value;

          update({
            teams: {
              ...report.teams,
              [teamKey]: team,
            },
          });
        }}
      />

      <input
        placeholder={t("reports.time-of-arrival")}
        value={report.teams[teamKey].time}
        onChange={(e) => {
          let team = report.teams[teamKey];

          team.time = e.target.value;

          update({
            teams: {
              ...report.teams,
              [teamKey]: team,
            },
          });
        }}
      />

      <PlayerInput
        playerKey={PlayerKey.P1}
        teamKey={teamKey}
        team={report.teams[teamKey]}
        report={report}
        fitet={fitet}
        setData={setData}
      />

      <PlayerInput
        playerKey={PlayerKey.P2}
        teamKey={teamKey}
        team={report.teams[teamKey]}
        report={report}
        fitet={fitet}
        setData={setData}
      />

      <PlayerInput
        playerKey={PlayerKey.P3}
        teamKey={teamKey}
        team={report.teams[teamKey]}
        report={report}
        fitet={fitet}
        setData={setData}
      />

      <PlayerInput
        playerKey={PlayerKey.R1}
        teamKey={teamKey}
        team={report.teams[teamKey]}
        report={report}
        fitet={fitet}
        setData={setData}
      />

      <PlayerInput
        playerKey={PlayerKey.R2}
        teamKey={teamKey}
        team={report.teams[teamKey]}
        report={report}
        fitet={fitet}
        setData={setData}
      />

      <PlayerInput
        playerKey={PlayerKey.CAPTAIN}
        teamKey={teamKey}
        team={report.teams[teamKey]}
        report={report}
        fitet={fitet}
        setData={setData}
      />

      <PlayerInput
        playerKey={PlayerKey.COACH}
        teamKey={teamKey}
        team={report.teams[teamKey]}
        report={report}
        fitet={fitet}
        setData={setData}
      />

      <PlayerInput
        playerKey={PlayerKey.MEDIC}
        teamKey={teamKey}
        team={report.teams[teamKey]}
        report={report}
        fitet={fitet}
        setData={setData}
      />

      <PlayerInput
        playerKey={PlayerKey.MANAGER}
        teamKey={teamKey}
        team={report.teams[teamKey]}
        report={report}
        fitet={fitet}
        setData={setData}
      />
    </div>
  );
};

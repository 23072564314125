import { useQuery } from "react-query";
import { queryClient } from "../libs/react-query";
import { Member } from "../model/member";
import { memberApi } from "../services/client";

export const useMemberController = (id_club: string | undefined) => {
  const { data: memberList } = useQuery<Member[]>({
    queryKey: ["memberList"],
    queryFn: async () => {
      return await memberApi.get(id_club!);
    },
    enabled: !!id_club,
  });

  const { data: currentMember } = useQuery({
    queryKey: ["club", id_club, "current-member"],
    queryFn: async () => {
      return await memberApi.getCurrentMember(id_club!);
    },
    enabled: !!id_club,
  });

  const post = async (partial: Partial<Member>) => {
    const member = new Member({ id_club, ...partial });

    await memberApi.post(member);

    queryClient.invalidateQueries({ queryKey: ["memberList"] });
  };

  const patch = async (id_member: string, partial: Partial<Member>) => {
    await memberApi.patch(id_club!, id_member, partial);

    queryClient.invalidateQueries({ queryKey: ["memberList"] });
  };

  const silentPatch = async (id_member: string, partial: Partial<Member>) => {
    await memberApi.patch(id_club!, id_member, partial);
  };

  const patchRole = async ({
    idClub,
    idMember,
    role,
  }: {
    idClub: string;
    idMember: string;
    role: string;
  }) => {
    await memberApi.patchRole(idClub, idMember, role);

    queryClient.invalidateQueries({ queryKey: ["memberList"] });
  };

  const removeRole = async ({
    idClub,
    idMember,
    role,
  }: {
    idClub: string;
    idMember: string;
    role: string;
  }) => {
    await memberApi.removeRole(idClub, idMember, role);

    queryClient.invalidateQueries({ queryKey: ["memberList"] });
  };

  const remove = async (id_member: string) => {
    await memberApi.remove(id_club!, id_member);

    queryClient.invalidateQueries({ queryKey: ["memberList"] });
  };

  const addBulk = async (members: Member[]) => {
    try {
      await memberApi.import(id_club!, members);

      queryClient.invalidateQueries({ queryKey: ["memberList"] });
    } catch (error) {
      console.error("Error importing CSV:", error);
      throw error;
    }
  };

  return {
    currentMember,
    memberList,
    post,
    patch,
    silentPatch,
    patchRole,
    removeRole,
    remove,
    addBulk,
  };
};

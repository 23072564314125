import { Member } from "../model/member";
import { Client } from "./client";

export class MemberApi {
  private client: Client;

  constructor(client: Client) {
    this.client = client;
  }

  async get(id_club: string): Promise<Member[]> {
    const response = await this.client.fetch(`/club/${id_club}/member`);

    const json = await response.json();

    return json.data as Member[];
  }

  async getCurrentMember(id_club: string): Promise<Member> {
    const response = await this.client.fetch(`/club/${id_club}/current-member`);

    const json = await response.json();

    return json.data as Member;
  }

  async post(member: Member): Promise<Member> {
    const response = await this.client.fetch(`/club/${member.id_club}/member`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(member),
    });

    const json = await response.json();

    return json.data as Member;
  }

  async patch(id_club: string, id_member: string, member: Partial<Member>) {
    await this.client.fetch(`/club/${id_club}/member/${id_member}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(member),
    });
  }

  async patchRole(id_club: string, id_member: string, role: string) {
    await this.client.fetch(`/club/${id_club}/member/${id_member}/role`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        role: role,
      }),
    });
  }

  async removeRole(id_club: string, id_member: string, role: string) {
    await this.client.fetch(`/club/${id_club}/member/${id_member}/role`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        role: role,
      }),
    });
  }

  async remove(id_club: string, id_member: string) {
    await this.client.fetch(`/club/${id_club}/member/${id_member}`, {
      method: "DELETE",
    });
  }

  async import(id_club: string, members: Member[]) {
    await this.client.fetch(`/club/${id_club}/member/import`, {
      method: "POST",
      body: JSON.stringify({
        members: members,
      }),
    });
  }

  async getRegisteredMembers(id_club: string): Promise<Member[]> {
    const response = await this.client.fetch(
      `/club/${id_club}/member/registered`
    );

    const json = await response.json();

    return json.data as Member[];
  }
}

import { useState } from "react";
import { useParams } from "react-router-dom";
import { useMemberController } from "../../../../controller/member-controller";
import { useI18n } from "../../../../lang/i18n";
import { DateUtils } from "../../../../libs/date";
import { Member } from "../../../../model/member";
import { Training, TrainingMember } from "../../../../model/training";

interface TrainingInputDataProps {
  training: Partial<Training>;
  onChange: (training: Partial<Training>) => void;
  onAddMember: (
    email?: string | null,
    firstName?: string | null,
    lastName?: string | null
  ) => void;
  onRemoveMember: (trainingMember: TrainingMember) => void;
  periodicUpdate?: boolean;
}

export const TrainingInputData = ({
  training,
  onChange,
  onAddMember,
  onRemoveMember,
  periodicUpdate = false,
}: TrainingInputDataProps) => {
  const { idClub } = useParams();
  const { t } = useI18n();

  const [searchTerm, setSearchTerm] = useState("");
  const [emailToAdd, setEmailToAdd] = useState("");
  const [firstNameToAdd, setFirstNameToAdd] = useState("");
  const [lastNameToAdd, setLastNameToAdd] = useState("");

  const { memberList } = useMemberController(idClub);

  const filteredMembers: Member[] =
    memberList?.filter(
      (member) =>
        member.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        member.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        member.last_name?.toLowerCase().includes(searchTerm.toLowerCase())
    ) ?? [];

  const handleAddMember = (
    email: string | null | undefined,
    firstName: string | null | undefined,
    lastName: string | null | undefined
  ) => {
    onAddMember(email, firstName, lastName);
    setEmailToAdd("");
    setFirstNameToAdd("");
    setLastNameToAdd("");
  };

  const handleRemoveMember = (trainingMember: TrainingMember) => {
    onRemoveMember(trainingMember);
    setEmailToAdd("");
    setFirstNameToAdd("");
    setLastNameToAdd("");
  };

  const timeFromISOString = (isoString: string | undefined) => {
    if (!isoString) {
      return "";
    }

    const date = new Date(isoString);

    return date.toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <div className="space-y-4 mb-8">
      {/* Form fields grid */}
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2">
          <label htmlFor="name" className="block text-sm font-medium ">
            {t("trainings.name")}
          </label>
          <input
            type="text"
            id="name"
            value={training.name}
            onChange={(e) => onChange({ ...training, name: e.target.value })}
            required
            className="mt-1 block w-full border  rounded-md p-2"
          />
        </div>

        <div className="space-y-2 col-span-2 md:col-span-1">
          <label className="block text-sm font-medium">
            {t("general.start-date")}
          </label>
          <div className="flex gap-2">
            <input
              type="date"
              value={DateUtils.dateForInput(training.start_date)}
              onChange={(e) => {
                let startTime = training.start_date?.split("T")[1];

                if (!startTime) {
                  startTime = "18:00:00";
                }

                let newDateTime = `${e.target.value}T${startTime}`;
                const newDate = new Date(newDateTime);
                onChange({
                  ...training,
                  start_date: newDate.toISOString(),
                });
              }}
              disabled={periodicUpdate}
              className="flex-1 mt-1 block border rounded-md p-2"
            />
            <input
              type="time"
              value={timeFromISOString(training.start_date)}
              onChange={(e) => {
                let newDateTime = `${training.start_date?.split("T")[0]}T${
                  e.target.value
                }`;

                const newDate = new Date(newDateTime);

                onChange({
                  ...training,
                  start_date: newDate.toISOString(),
                });
              }}
              className="flex-1 mt-1 block border rounded-md p-2"
            />
          </div>
        </div>
        <div className="space-y-2 col-span-2 md:col-span-1">
          <label className="block text-sm font-medium">
            {t("general.end-date")}
          </label>
          <div className="flex gap-2">
            <input
              type="date"
              value={DateUtils.dateForInput(training.end_date)}
              onChange={(e) => {
                let endTime = training.end_date?.split("T")[1];

                if (!endTime) {
                  endTime = "20:00:00";
                }

                let newDateTime = `${e.target.value}T${endTime}`;
                const newDate = new Date(newDateTime);
                onChange({
                  ...training,
                  end_date: newDate.toISOString(),
                });
              }}
              disabled={periodicUpdate}
              className="flex-1 mt-1 block border rounded-md p-2"
            />
            <input
              type="time"
              value={timeFromISOString(training.end_date)}
              onChange={(e) => {
                let newDateTime = `${training.end_date?.split("T")[0]}T${
                  e.target.value
                }`;

                const newDate = new Date(newDateTime);

                onChange({
                  ...training,
                  end_date: newDate.toISOString(),
                });
              }}
              className="flex-1 mt-1 block border rounded-md p-2"
            />
          </div>
        </div>
        <div className="col-span-2">
          <label htmlFor="place" className="block text-sm font-medium ">
            {t("trainings.place")}
          </label>
          <input
            type="text"
            id="place"
            value={training.place}
            onChange={(e) => onChange({ ...training, place: e.target.value })}
            className="mt-1 block w-full border  rounded-md p-2"
          />
        </div>
        <div className="md:col-span-1">
          <label htmlFor="minAthletes" className="block text-sm font-medium ">
            {t("trainings.min-athletes")}
          </label>
          <input
            type="number"
            id="minAthletes"
            value={training.min_athletes ?? ""}
            onChange={(e) =>
              onChange({
                ...training,
                min_athletes: e.target.value ? parseInt(e.target.value) : null,
              })
            }
            className="mt-1 block w-full border  rounded-md p-2"
          />
        </div>
        <div className="md:col-span-1">
          <label htmlFor="maxAthletes" className="block text-sm font-medium ">
            {t("trainings.max-athletes")}
          </label>
          <input
            type="number"
            id="maxAthletes"
            value={training.max_athletes ?? ""}
            onChange={(e) =>
              onChange({
                ...training,
                max_athletes: e.target.value ? parseInt(e.target.value) : null,
              })
            }
            className="mt-1 block w-full border  rounded-md p-2"
          />
        </div>
        <div className="col-span-2">
          <label htmlFor="note" className="block text-sm font-medium ">
            {t("trainings.note")}
          </label>
          <textarea
            id="note"
            value={training.note}
            onChange={(e) => onChange({ ...training, note: e.target.value })}
            className="mt-1 block w-full border  rounded-md p-2"
          />
        </div>

        {/* <div className="relative">
          <label htmlFor="bookStartDate" className="block text-sm font-medium ">
            {i18n("trainings.booking-start-date")}
          </label>
          <input
            type="date"
            id="bookStartDate"
            value={DateUtils.dateForInput(training.book_start_date)}
            onChange={(e) => {
              let newDateTime = `${e.target.value}T00:00:00`;
              const newDate = new Date(newDateTime);
              onChange({
                ...training,
                book_start_date: newDate.toISOString(),
              });
            }}
            placeholder="dd/MM/yyyy"
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div>
        
        <div className="relative">
          <label htmlFor="bookEndDate" className="block text-sm font-medium ">
            {i18n("trainings.booking-end-date")}
          </label>
          <input
            type="date"
            id="bookEndDate"
            value={DateUtils.dateForInput(training.book_end_date)}
            onChange={(e) => {
              let newDateTime = `${e.target.value}T23:59:59`;
              const newDate = new Date(newDateTime);
              onChange({
                ...training,
                book_end_date: newDate.toISOString(),
              });
            }}
            placeholder="dd/MM/yyyy"
            className="mt-1 block w-full border rounded-md p-2"
          />
        </div> */}

        <div className="flex items-center mt-4">
          <input
            type="checkbox"
            id="everyWeek"
            checked={training.every_week}
            onChange={(e) =>
              onChange({ ...training, every_week: e.target.checked })
            }
            className="mr-2 h-4 w-4 text-app-primary  rounded focus:ring-app-primary"
          />
          <label htmlFor="everyWeek" className="text-sm font-medium ">
            {t("trainings.every-week")}
          </label>
        </div>

        {training.every_week && (
          <div className="relative">
            <label htmlFor="untilDate" className="block text-sm font-medium ">
              {t("trainings.until-date")}
            </label>
            <input
              type="date"
              id="untilDate"
              value={training.until_date}
              onChange={(e) =>
                onChange({ ...training, until_date: e.target.value })
              }
              disabled={periodicUpdate}
              placeholder="dd/MM/yyyy"
              className="mt-1 block w-full border rounded-md p-2"
            />
          </div>
        )}
      </div>

      {/* Members section */}
      <div className="space-y-4 p-4 rounded-lg shadow-sm">
        <h3 className="text-lg font-medium">{t("trainings.add-member")}</h3>

        {/* Member search */}
        <div className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={t("trainings.search-by-email")}
            className="w-full border  rounded-md px-3 py-2"
          />
        </div>

        {searchTerm && (
          <div className="mt-2 border  rounded-md max-h-48 overflow-y-auto">
            {filteredMembers?.map((member) => (
              <MemberItem
                key={member._id}
                member={member}
                onAddMember={(email, firstName, lastName) => {
                  handleAddMember(email, firstName, lastName);
                  setSearchTerm("");
                }}
                training={training}
              />
            ))}
          </div>
        )}

        {/* Selected members list */}
        {(training.joined_member_list?.length ?? 0) > 0 && (
          <div className="mt-4">
            <h4 className="font-medium mb-2">
              {t("trainings.selected-club-members")}
            </h4>
            <div className="space-y-2">
              {(training.joined_member_list ?? []).map((member) => (
                <TrainingMemberItem
                  key={
                    member._id ??
                    `${member.email}-${member.first_name}-${member.last_name}`
                  }
                  trainingMember={member}
                  onRemoveMember={() => handleRemoveMember(member)}
                />
              ))}
            </div>
          </div>
        )}

        <h3 className="text-lg font-medium">
          {t("trainings.add-outside-member")}
        </h3>

        <div className="flex flex-col sm:flex-row gap-2">
          <input
            type="email"
            value={emailToAdd}
            onChange={(e) => setEmailToAdd(e.target.value)}
            placeholder={
              t("general.email") + ` (${t("general.optional-field")})`
            }
            className="flex-1 border  rounded-md px-3 py-2"
          />
          <input
            type="text"
            value={firstNameToAdd}
            onChange={(e) => setFirstNameToAdd(e.target.value)}
            placeholder={t("general.first-name")}
            className="flex-1 border  rounded-md px-3 py-2"
          />
          <input
            type="text"
            value={lastNameToAdd}
            onChange={(e) => setLastNameToAdd(e.target.value)}
            placeholder={t("general.last-name")}
            className="flex-1 border  rounded-md px-3 py-2"
          />
          <button
            type="button"
            onClick={() => {
              handleAddMember(emailToAdd, firstNameToAdd, lastNameToAdd);
            }}
            disabled={!firstNameToAdd || !lastNameToAdd}
            className="bg-app-primary px-4 py-2 rounded-md disabled:opacity-50 w-full sm:w-auto"
          >
            {t("general.add")}
          </button>
        </div>
      </div>
    </div>
  );
};

const MemberItem = ({
  member,
  onAddMember,
  training,
}: {
  member: Member;
  onAddMember: (
    email: string | null | undefined,
    firstName: string | null | undefined,
    lastName: string | null | undefined
  ) => void;
  training: Training;
}) => {
  const { t } = useI18n();

  let addedMember = training.joined_member_list?.find(
    (trainingMember) =>
      (!!trainingMember.email && trainingMember.email === member.email) ||
      (trainingMember.first_name === member.first_name &&
        trainingMember.last_name === member.last_name)
  );

  const isAlreadyAdded = !!addedMember;

  return (
    <div className="p-3 flex justify-between items-center hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors border-b border-gray-200 dark:border-gray-700 last:border-b-0">
      <div className="flex flex-col sm:flex-row gap-1 sm:gap-3">
        <span className="font-medium text-gray-900 dark:text-gray-100">
          {member.first_name} {member.last_name}
        </span>
        <span className="text-gray-600 dark:text-gray-400 text-sm">
          {member.email}
        </span>
      </div>
      <button
        type="button"
        onClick={() => {
          onAddMember(member.email, member.first_name, member.last_name);
        }}
        disabled={isAlreadyAdded}
        className={`px-3 py-1.5 rounded-md text-sm transition-colors`}
      >
        {isAlreadyAdded ? t("general.added") : t("general.add")}
      </button>
    </div>
  );
};

const TrainingMemberItem = ({
  trainingMember,
  onRemoveMember,
}: {
  trainingMember: TrainingMember;
  onRemoveMember: () => void;
}) => {
  const { t } = useI18n();
  const { idClub } = useParams();
  const { memberList } = useMemberController(idClub);

  const member = memberList?.find((m) => m?.email === trainingMember.email);

  const email = member?.email;
  const firstName = member?.first_name ?? trainingMember.first_name;
  const lastName = member?.last_name ?? trainingMember.last_name;

  return (
    <div className="flex justify-between items-center p-3 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors">
      <div className="flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-4">
        <span className="font-medium text-gray-900 dark:text-gray-100">
          {firstName} {lastName}
        </span>
        <span className="text-gray-600 dark:text-gray-400 text-sm">
          {email}
        </span>
      </div>
      <button
        type="button"
        onClick={() => onRemoveMember()}
        className="px-3 py-1.5 text-sm text-red-600 dark:text-red-400 hover:text-red-800 dark:hover:text-red-300 hover:bg-red-50 dark:hover:bg-red-900/20 rounded-md transition-colors"
      >
        {t("general.remove")}
      </button>
    </div>
  );
};

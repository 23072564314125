import { IconCheck } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useI18n } from "../../../lang/i18n";

const PRICING_FEATURES = [
  "unlimited_players",
  "training_schedule",
  "player_profiles",
  "match_reports",
  "training_analytics",
  "mobile_access",
] as const;

export default function Pricing({ id }: { id: string }) {
  const { t, tNode } = useI18n();
  const [selectedCurrency, setSelectedCurrency] = useState<string>("eur");
  const [availableCurrencies, setAvailableCurrencies] = useState<string[]>([]);

  const features = PRICING_FEATURES.map((feature) =>
    t(`landing.pricing.plan.features.${feature}`)
  );

  const { data: clubCount = 0 } = useQuery<number>({
    queryKey: ["clubCount"],
    queryFn: () =>
      fetch(process.env.PUBLIC_BACKEND_API_URL + "/public/club/count").then(
        async (res) => (await res.json()).data
      ),
  });

  const { data: response } = useQuery({
    queryKey: ["price"],
    queryFn: () =>
      fetch(process.env.PUBLIC_BACKEND_API_URL + "/public/price").then(
        async (res) => (await res.json()).data
      ),
  });

  useEffect(() => {
    if (response?.price?.currency_options) {
      const currencies = Object.keys(response.price.currency_options);
      setAvailableCurrencies(currencies);
      if (!currencies.includes(selectedCurrency)) {
        setSelectedCurrency(currencies[0]);
      }
    }
  }, [response]);

  let priceFormatted = "";
  let newPriceFormatted = "";

  if (response) {
    const currencyData = response.price?.currency_options?.[selectedCurrency];
    const price = currencyData?.unit_amount / 100;
    const amountOff =
      response.launch_promotion?.coupon?.currency_options?.[selectedCurrency]
        ?.amount_off / 100 || 0;

    priceFormatted = price.toFixed(2);
    newPriceFormatted = (price - amountOff).toFixed(2);
  }

  return (
    <section className="py-16" id={id}>
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            {t("landing.pricing.title")}
          </h2>
          <p className="text-xl max-w-2xl mx-auto">
            {t("landing.pricing.subtitle")}
          </p>
        </div>

        <div className="max-w-md mx-auto">
          <PricingCard
            name={t("landing.pricing.plan.name")}
            price={priceFormatted}
            period={t("landing.pricing.plan.period")}
            description={t("landing.pricing.plan.description")}
            discount={tNode("landing.pricing.plan.discount", {
              clubCount: (
                <span className="font-bold text-app-primary">
                  {clubCount > 10 ? 10 : clubCount}
                </span>
              ),
              newPrice: newPriceFormatted,
            })}
            features={features}
            cta={t("landing.pricing.plan.cta")}
            highlighted={false}
            subtitle={t("landing.pricing.plan.subtitle")}
            currencySelector={
              <select
                value={selectedCurrency}
                onChange={(e) => setSelectedCurrency(e.target.value)}
                className="mt-2 block rounded-md border-gray-300 py-1 pl-2 pr-8 text-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500"
              >
                {availableCurrencies.map((currency) => (
                  <option key={currency} value={currency}>
                    {currency.toUpperCase()}
                  </option>
                ))}
              </select>
            }
          />
        </div>
      </div>
    </section>
  );
}

function PricingCard({
  name,
  price,
  period = "",
  description,
  discount = "",
  features,
  cta,
  highlighted = false,
  subtitle = "",
  currencySelector,
}: {
  name: React.ReactNode;
  price: React.ReactNode;
  period?: React.ReactNode;
  description: React.ReactNode;
  discount?: React.ReactNode;
  features: React.ReactNode[];
  cta: React.ReactNode;
  highlighted?: boolean;
  subtitle?: React.ReactNode;
  currencySelector?: React.ReactNode;
}) {
  const { t } = useI18n();
  const navigate = useNavigate();

  return (
    <div
      className={`rounded-xl shadow-sm border ${
        highlighted ? "border-app-primary" : "border-gray-100"
      } p-8 relative ${highlighted ? "scale-105" : ""}`}
    >
      {highlighted && (
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <span className="bg-indigo-600/90 backdrop-blur-sm text-sm font-semibold px-4 py-1.5 rounded-full shadow-lg text-white ring-2 ring-indigo-600 ring-offset-2">
            {t("landing.pricing.plan.popular_badge")}
          </span>
        </div>
      )}

      <div className="text-center mb-8">
        <h3 className="text-2xl font-bold mb-2">{name}</h3>
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-baseline gap-1">
            <span className="text-4xl font-bold">{price}</span>
            {period && <span>{period}</span>}
          </div>
          {currencySelector}
        </div>
        {discount && (
          <p className="text-base font-semibold text-indigo-600 text-center mt-4 bg-indigo-50 py-2 px-4 rounded-lg border border-indigo-100">
            {discount}
          </p>
        )}
        <p className="mt-2">{description}</p>
      </div>

      <ul className="space-y-4 mb-8">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center gap-3">
            <IconCheck className="w-5 h-5 text-green-500 flex-shrink-0" />
            {feature}
          </li>
        ))}
      </ul>

      <button
        onClick={() => navigate("/auth")}
        data-variant="primary"
        className={`w-full py-3 px-4 rounded-lg font-medium transition-colors ${
          highlighted
            ? "bg-app-primary text-white hover:bg-opacity-90"
            : "bg-gray-100 text-gray-900 hover:bg-gray-200"
        }`}
      >
        {cta}
      </button>
      {subtitle && (
        <p className="text-sm text-gray-500 text-center mt-4">{subtitle}</p>
      )}
    </div>
  );
}

import { Link } from "react-router-dom";
import { useI18n } from "../../../lang/i18n";

const FooterDashboard = () => {
  const { t } = useI18n();

  return (
    <footer className="flex flex-row md:h-[9rem] px-4 justify-center bottom-0 left-0 w-full md:p-6">
      <div className="max-w-xl flex flex-col md:flex-row justify-between items-center md:items-start w-full py-4 md:py-0 space-y-4 md:space-y-0 leading-none">
        <section className="flex flex-col space-y-2 items-center md:items-start">
          <span className="font-bold">{t("footer.support")}</span>
          <Link to={`/contact`}>
            <span className="text-xs sm:text-center">
              {t("footer.send-me-a-message")}
            </span>
          </Link>
        </section>

        <section className="flex flex-col space-y-2 items-center md:items-start">
          <span className="font-bold">{t("footer.legal")}</span>
          <a href="/privacy-policy.md" className="text-xs sm:text-center">
            {t("footer.privacy-policy")}
          </a>
          <a href="/terms-and-conditions.md" className="text-xs sm:text-center">
            {t("footer.terms-of-service")}
          </a>
          <a href="/cookie-policy.md" className="text-xs sm:text-center">
            {t("footer.cookie-policy")}
          </a>
        </section>

        <section className="flex flex-col space-y-2 items-center md:items-start">
          <span className="font-bold">{t("footer.about-me")}</span>
          <a
            href="https://ricu.it"
            className="text-xs sm:text-center"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://ricu.it
          </a>
        </section>
      </div>
    </footer>
  );
};

export default FooterDashboard;

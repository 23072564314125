import { useNavigate } from "react-router-dom";
import { useI18n } from "../../../lang/i18n";

export default function Faq({ id }: { id: string }) {
  const { t, tNode } = useI18n();
  const navigate = useNavigate();

  return (
    <div className="mt-16 max-w-3xl mx-auto" id={id}>
      <h3 className="text-2xl font-bold mb-8 text-center">
        {t("landing.faq.title")}
      </h3>
      <div className="space-y-6">
        <FaqItem
          question={t("landing.faq.items.setup.question")}
          answer={tNode("landing.faq.items.setup.answer")}
        />
        <FaqItem
          question={t("landing.faq.items.cancel.question")}
          answer={tNode("landing.faq.items.cancel.answer")}
        />
        <FaqItem
          question={t("landing.faq.items.security.question")}
          answer={tNode("landing.faq.items.security.answer")}
        />
        <FaqItem
          question={t("landing.faq.items.setup_help.question")}
          answer={tNode("landing.faq.items.setup_help.answer")}
        />
        <FaqItem
          question={t("landing.faq.items.mobile_app.question")}
          answer={tNode("landing.faq.items.mobile_app.answer", {
            ios_version: (
              <a
                href="https://apps.apple.com/it/app/tt-club-manager/id6738867548"
                className="text-blue-500 underline"
                target="_blank"
              >
                App Store
              </a>
            ),
            android_version: (
              <a
                href="https://play.google.com/store/apps/details?id=it.matteoricupero.ttclubmanager"
                className="text-blue-500 underline"
                target="_blank"
              >
                Play Store
              </a>
            ),
          })}
        />
        <FaqItem
          question={t("landing.faq.items.support.question")}
          answer={tNode("landing.faq.items.support.answer")}
        />
      </div>

      {/* Contact section */}
      <div className="mt-12 text-center">
        <p className="mb-4">{tNode("landing.faq.items.contact.text")}</p>
        <button
          onClick={() => navigate("/contact")}
          className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          {tNode("landing.faq.items.contact.button")}
        </button>
      </div>
    </div>
  );
}

function FaqItem({
  question,
  answer,
}: {
  question: React.ReactNode;
  answer: React.ReactNode;
}) {
  return (
    <div className="border-b pb-6">
      <h4 className="text-lg font-medium mb-2">{question}</h4>
      <div className="prose">{answer}</div>
    </div>
  );
}

import { ClubApi } from "./club-api";
import { FitetApi } from "./fitet-api";
import { MemberApi } from "./member-api";
import { NewsApi } from "./news-api";
import { ReportApi } from "./report-api";
import { StripeApi } from "./stripe-api";
import { TrainingApi } from "./training-api";
import { UserApi } from "./user-api";

const defaultHeaders = {
  "Content-Type": "application/json",
};

export class Client {
  baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async fetch(
    url: string,
    init?: RequestInit,
    ignoreException = false
  ): Promise<Response> {
    try {
      const headers = {
        ...defaultHeaders,
        ...(init?.headers || {}),
      };

      const uri = this.baseUrl + url;

      const response = await fetch(uri, {
        ...init,
        headers,
        credentials: "include",
      });

      console.log(
        "method:",
        init?.method ?? "GET",
        "request:",
        url,
        "response:",
        response.status
      );

      if (response.ok) {
        return response;
      }

      if (ignoreException) {
        return response;
      }

      if (response.status === 401) {
        alert("Your session has expired. Please login again.");
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/auth";
        throw new Error("Unauthorized");
      }

      const json = await response.json();

      const message: string | null = json.message;

      if (message) {
        alert(message);
        throw new Error(message);
      }

      throw new Error(response.statusText);
    } catch (e) {
      console.error("error:", e);
      throw e;
    }
  }
}

export const client = new Client(process.env.PUBLIC_BACKEND_API_URL!);
export const stripeApi = new StripeApi(client);
export const userApi = new UserApi(client);
export const clubApi = new ClubApi(client);
export const reportApi = new ReportApi(client);
export const memberApi = new MemberApi(client);
export const newsApi = new NewsApi(client);
export const trainingApi = new TrainingApi(client);
export const fitetApi = new FitetApi(client);

export enum UserRole {
  owner = "owner",
  super_admin = "super_admin",
  admin = "admin",
  member = "member",
}

export const getAllRoles = () => {
  return Object.values(UserRole);
};

export class Member {
  _id?: string;
  id_club: string;
  id_sub_user?: string | null;
  email: string;
  first_name?: string | null;
  last_name?: string | null;
  user_role_list: UserRole[];
  medical_exam_expiration_date?: string | null;
  membership_expiration_date?: string | null;
  onesignal_subscription_id?: string | null;
  onesignal_id?: string | null;
  push_subscriptions?: string[];
  virt_registered?: boolean;

  constructor(partial: Partial<Member>) {
    this.id_club = partial.id_club ?? "";
    this.email = partial.email ?? "";
    this.first_name = partial.first_name ?? null;
    this.last_name = partial.last_name ?? null;
    this.user_role_list = partial.user_role_list ?? [UserRole.member];
    this.medical_exam_expiration_date =
      partial.medical_exam_expiration_date ?? "";
    this.membership_expiration_date = partial.membership_expiration_date ?? "";
    this.push_subscriptions = partial.push_subscriptions ?? [];
    this.onesignal_subscription_id = partial.onesignal_subscription_id ?? null;
    this.onesignal_id = partial.onesignal_id ?? null;
  }
}

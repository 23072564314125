import { Club, ClubResponse } from "../model/club";
import { Client } from "./client";

export class ClubApi {
  private client: Client;

  constructor(client: Client) {
    this.client = client;
  }

  async get(): Promise<Club[]> {
    const response = await this.client.fetch(`/club`);

    const json = await response.json();

    return json.data as Club[];
  }

  async getId(id: string): Promise<ClubResponse> {
    const response = await this.client.fetch(`/club/${id}`);

    const json = await response.json();

    return json.data as ClubResponse;
  }

  async post({
    name,
    currency,
  }: {
    name: string;
    currency: string;
  }): Promise<Club> {
    const response = await this.client.fetch(`/club`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, currency }),
    });

    const json = await response.json();

    return json.data as Club;
  }

  async patch(id: string, club: Partial<Club>): Promise<Club> {
    const response = await this.client.fetch(`/club/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(club),
    });

    const json = await response.json();

    return json.data as Club;
  }

  async postMember(id_club: string, emailUser: string): Promise<Club> {
    const response = await this.client.fetch(`/club/${id_club}/member`, {
      method: "POST",
      body: JSON.stringify({
        email: emailUser,
      }),
    });

    const json = await response.json();

    return json.data as Club;
  }

  async reactivateSubscription(id_club: string): Promise<ClubResponse> {
    const response = await this.client.fetch(
      `/club/${id_club}/reactivate-subscription`,
      {
        method: "POST",
      }
    );

    const json = await response.json();

    return json.data as ClubResponse;
  }
}

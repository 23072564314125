import { IconCalendar, IconChevronDown } from "@tabler/icons-react";
import { useState } from "react";
import { useI18n } from "../../../../../lang/i18n";
import { DateUtils } from "../../../../../libs/date";

interface DateFiltersProps {
  startDate: string | null;
  endDate: string | null;
  onStartDateChange: (date: string) => void;
  onEndDateChange: (date: string) => void;
}

export default function DateFilters({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
}: DateFiltersProps) {
  const { t } = useI18n();
  const [showDateFilters, setShowDateFilters] = useState(true);

  return (
    <div className="generic-card">
      <div className="generic-card-header">
        <h2 className="generic-card-title">{t("data.date-filters")}</h2>
        <button
          onClick={() => setShowDateFilters(!showDateFilters)}
          className="p-1 rounded-full transition-transform duration-200 hover:bg-gray-200/50 dark:hover:bg-gray-700/50"
          style={{
            transform: showDateFilters ? "rotate(0deg)" : "rotate(-180deg)",
          }}
        >
          <IconChevronDown className="h-5 w-5 sm:h-6 sm:w-6 text-gray-500" />
        </button>
      </div>
      {showDateFilters && (
        <div className="generic-card-body">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                {t("data.start-date")}
              </label>
              <div className="relative">
                <IconCalendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                <input
                  type="date"
                  value={DateUtils.dateForInput(startDate)}
                  onChange={(e) => onStartDateChange(e.target.value)}
                  className="pl-10 w-full border rounded-md px-3 py-2 text-sm sm:text-base"
                />
              </div>
            </div>
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                {t("data.end-date")}
              </label>
              <div className="relative">
                <IconCalendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                <input
                  type="date"
                  value={DateUtils.dateForInput(endDate)}
                  onChange={(e) => onEndDateChange(e.target.value)}
                  className="pl-10 w-full border rounded-md px-3 py-2 text-sm sm:text-base"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

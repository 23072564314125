import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export type LogoProps = {
  className?: string;
  href?: string;
};

export default function Logo({ className, href }: LogoProps) {
  const [isDarkMode, setIsDarkMode] = useState(
    window.matchMedia("(prefers-color-scheme: dark)").matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChange = (e: MediaQueryListEvent) => {
      setIsDarkMode(e.matches);
    };

    mediaQuery.addEventListener("change", handleChange);
    return () => mediaQuery.removeEventListener("change", handleChange);
  }, []);

  return (
    <Link
      to={href || "/"}
      className="flex items-center gap-1 leading-none cursor-pointer"
    >
      <img
        src={isDarkMode ? "/logo/logo-invert.png" : "/logo/logo.png"}
        alt="TT Club"
        className="h-12"
      />
      <span
        className={`w-auto text-[21px] pt-[2px] font-light text-app-accent tracking-tighter ${className}`}
      >
        Club Manager
      </span>
    </Link>
  );
}

import { IconLoader2, IconRefresh } from "@tabler/icons-react";
import {
  addDays,
  addMonths,
  addWeeks,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useMemberController } from "../../../../controller/member-controller";
import { useTrainingController } from "../../../../controller/training-controller";
import { useI18n } from "../../../../lang/i18n";
import { queryClient } from "../../../../libs/react-query";
import { UserRole } from "../../../../model/member";
import { Training, TrainingMember } from "../../../../model/training";
import HorizontalCalendar from "./horizontal-calendar";
import { TrainingInputData } from "./training-input-data";
import TrainingList from "./training-list";

export default function TrainingListPage() {
  const { idClub } = useParams();
  const { t } = useI18n();

  const trainingController = useTrainingController(idClub);

  const { trainingList, createTraining, deleteTraining } = trainingController;

  const handleDelete = async (trainingId: string) => {
    if (window.confirm("Are you sure you want to delete this training?")) {
      await deleteTraining(trainingId);
    }
  };

  const [showForm, setShowForm] = useState(false);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  // Update the filter change handlers
  const handleStartDateChange = (date: string) => {
    setFilterStartDate(date);
    setSelectedDate(null);

    const startOfDayDate = startOfDay(new Date(date));
    trainingController.setFilterStartDate(startOfDayDate.toISOString());
  };

  const handleEndDateChange = (date: string) => {
    setFilterEndDate(date);
    setSelectedDate(null);

    const endOfDayDate = endOfDay(new Date(date));
    trainingController.setFilterEndDate(endOfDayDate.toISOString());
  };

  const applyDateFilter = (start: Date, end: Date) => {
    trainingController.setFilterStartDate(start.toISOString());
    trainingController.setFilterEndDate(end.toISOString());

    setFilterStartDate(format(parseISO(start.toISOString()), "yyyy-MM-dd"));
    setFilterEndDate(format(parseISO(end.toISOString()), "yyyy-MM-dd"));

    setSelectedDate(null);
  };

  const filterToday = () => {
    const today = new Date();
    applyDateFilter(startOfDay(today), endOfDay(today));
  };

  const filterTomorrow = () => {
    const tomorrow = addDays(new Date(), 1);
    applyDateFilter(startOfDay(tomorrow), endOfDay(tomorrow));
  };

  const filterThisWeek = () => {
    const today = new Date();
    applyDateFilter(
      startOfWeek(today, { weekStartsOn: 1 }),
      endOfWeek(today, { weekStartsOn: 1 })
    );
  };

  const filterNextWeek = () => {
    const nextWeek = addWeeks(new Date(), 1);
    applyDateFilter(
      startOfWeek(nextWeek, { weekStartsOn: 1 }),
      endOfWeek(nextWeek, { weekStartsOn: 1 })
    );
  };

  const filterThisMonth = () => {
    const today = new Date();
    applyDateFilter(startOfMonth(today), endOfMonth(today));
  };

  const filterNextMonth = () => {
    const nextMonth = addMonths(new Date(), 1);
    applyDateFilter(startOfMonth(nextMonth), endOfMonth(nextMonth));
  };

  const filterNextThreeMonths = () => {
    const today = new Date();
    const threeMonthsLater = addMonths(today, 3);
    applyDateFilter(startOfDay(today), endOfMonth(threeMonthsLater));
  };

  const { currentMember } = useMemberController(idClub);

  const isOwnerOrAdmin =
    currentMember?.user_role_list.includes(UserRole.owner) ||
    currentMember?.user_role_list.includes(UserRole.super_admin) ||
    currentMember?.user_role_list.includes(UserRole.admin);

  const [newTraining, setNewTraining] = useState<Partial<Training>>({
    id_club: idClub!,
    start_date: new Date().toISOString(),
    end_date: new Date().toISOString(),
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!isOwnerOrAdmin) {
      alert("You do not have permission to create a training.");
      return;
    }

    setLoading(true);

    try {
      await createTraining(newTraining);

      // Reset all form fields
      setNewTraining({ id_club: idClub! });

      toggleForm();
    } finally {
      setLoading(false);
    }
  };

  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const handleDateSelect = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    const dateComposed = new Date(Date.UTC(year, month, day)).toISOString();

    const startDate = startOfDay(dateComposed);
    const endDate = endOfDay(dateComposed);

    setFilterStartDate(dateComposed.split("T")[0]);
    setFilterEndDate(dateComposed.split("T")[0]);
    setSelectedDate(date);

    trainingController.setFilterStartDate(startDate.toISOString());
    trainingController.setFilterEndDate(endDate.toISOString());
  };

  return (
    <div className="space-y-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <div className="flex items-center justify-between w-full gap-2">
          <h1 className="text-2xl font-bold">{t("trainings.title")}</h1>
          <button
            onClick={() => queryClient.invalidateQueries(["training"])}
            className="p-1 hover:bg-gray-100 rounded-full"
          >
            <IconRefresh size={20} className="text-gray-600" />
          </button>
        </div>

        <div className="flex gap-2 w-full sm:w-auto">
          {isOwnerOrAdmin && (
            <button
              onClick={toggleForm}
              className="bg-app-primary px-4 py-2 rounded-md w-full sm:w-auto"
            >
              {showForm ? t("general.hide") : t("general.create")}
            </button>
          )}
        </div>
      </div>

      {isOwnerOrAdmin && showForm && (
        <form onSubmit={handleSubmit}>
          <TrainingInputData
            training={newTraining}
            onChange={(training: Partial<Training>) => setNewTraining(training)}
            onAddMember={(email, firstName, lastName) => {
              const trainingMember: TrainingMember = {
                email: email,
                first_name: firstName,
                last_name: lastName,
              };

              setNewTraining({
                ...newTraining,
                joined_member_list: [
                  ...(newTraining.joined_member_list ?? []),
                  trainingMember,
                ],
              });
            }}
            onRemoveMember={(trainingMemberToRemove) => {
              const updatedMemberList = newTraining.joined_member_list?.filter(
                (member) => {
                  const emailMatches =
                    member.email !== trainingMemberToRemove.email;
                  const nameMatches =
                    member.first_name !== trainingMemberToRemove.first_name ||
                    member.last_name !== trainingMemberToRemove.last_name;

                  return emailMatches || nameMatches;
                }
              );

              setNewTraining({
                ...newTraining,
                joined_member_list: updatedMemberList,
              });
            }}
          />

          <div className="flex flex-col sm:flex-row gap-3">
            <button
              className="w-full sm:w-auto bg-app-primary text-white px-6 py-2.5 rounded-md hover:bg-opacity-90 transition-colors"
              data-variant="primary"
            >
              {loading ? (
                <IconLoader2 className="animate-spin-progress" size={20} />
              ) : (
                t("general.create")
              )}
            </button>
            <button
              type="button"
              onClick={() => {
                // Reset form logic
              }}
              className="w-full sm:w-auto px-6 py-2.5 rounded-md transition-colors"
            >
              {t("general.reset")}
            </button>
          </div>
        </form>
      )}

      <hr className="mb-8" />

      <div className="flex flex-col gap-4">
        {/* Quick filter buttons - now horizontally scrollable on mobile */}
        <div className="relative">
          <div className="flex overflow-x-auto py-2 hide-scrollbar">
            <div className="flex gap-2 min-w-min">
              <QuickFilterButton onClick={filterToday}>
                {t("general.today")}
              </QuickFilterButton>
              <QuickFilterButton onClick={filterTomorrow}>
                {t("general.tomorrow")}
              </QuickFilterButton>
              <QuickFilterButton onClick={filterThisWeek}>
                {t("general.this-week")}
              </QuickFilterButton>
              <QuickFilterButton onClick={filterNextWeek}>
                {t("general.next-week")}
              </QuickFilterButton>
              <QuickFilterButton onClick={filterThisMonth}>
                {t("general.this-month")}
              </QuickFilterButton>
              <QuickFilterButton onClick={filterNextMonth}>
                {t("general.next-month")}
              </QuickFilterButton>
              <QuickFilterButton onClick={filterNextThreeMonths}>
                {t("general.next-three-months")}
              </QuickFilterButton>
            </div>
          </div>
        </div>

        {/* Update the date filter inputs styling */}
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex flex-row gap-4 w-full">
            <div className="w-1/2">
              <label
                htmlFor="startDate"
                className="block text-sm font-medium mb-1"
              >
                {t("general.start-date")}
              </label>
              <input
                type="date"
                id="startDate"
                value={filterStartDate}
                onChange={(e) => handleStartDateChange(e.target.value)}
                className="w-full border rounded-md px-3 py-2"
              />
            </div>
            <div className="w-1/2">
              <label
                htmlFor="endDate"
                className="block text-sm font-medium mb-1"
              >
                {t("general.end-date")}
              </label>
              <input
                type="date"
                id="endDate"
                value={filterEndDate}
                onChange={(e) => handleEndDateChange(e.target.value)}
                className="w-full border rounded-md px-3 py-2"
              />
            </div>
          </div>
        </div>
      </div>

      <HorizontalCalendar
        selectedDate={selectedDate}
        onDateSelect={handleDateSelect}
      />

      {/* Responsive table */}
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <div className="overflow-hidden rounded-lg">
            <TrainingList
              idClub={idClub}
              trainingList={trainingList ?? []}
              isOwnerOrAdmin={isOwnerOrAdmin}
              handleDelete={handleDelete}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const QuickFilterButton = ({
  onClick,
  children,
}: {
  onClick: () => void;
  children: React.ReactNode;
}) => {
  return (
    <button
      onClick={onClick}
      className="whitespace-nowrap px-3 py-1.5 text-sm border border-gray-300 rounded-md hover:bg-gray-50 flex-shrink-0"
    >
      {children}
    </button>
  );
};

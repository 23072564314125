import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../controller/auth-controller";
import { useUserController } from "../../../controller/user-controller";
import { useI18n } from "../../../lang/i18n";

export default function AccountInfo() {
  const userController = useUserController();
  const authController = useAuth();
  const navigate = useNavigate();
  const { t } = useI18n();

  return (
    <div className="rounded-lg shadow-sm border">
      <div className="p-6">
        <div className="flex flex-col space-y-0 mb-4">
          <h3 className="text-lg font-semibold">{t("auth.account")}</h3>
          <span className="">{userController.user?.email}</span>
        </div>

        <div className="space-y-4">
          <div className="flex flex-col gap-2">
            <button
              data-variant="secondary"
              onClick={() =>
                authController.resetPassword(userController.user?.email || "")
              }
              className="w-full"
            >
              {t("auth.reset-password")}
            </button>

            <button
              data-variant="danger"
              onClick={async () => {
                await authController.logout();
                navigate("/auth");
              }}
              className="w-full"
            >
              {t("settings.sign-out")}
            </button>
          </div>
        </div>

        <div className="flex flex-col items-start gap-3 mt-6">
          <span className="text-sm font-semibold">
            {t("settings.account-deletion")}
          </span>

          <p className="text-sm">
            {t("settings.account-deletion-description")}
          </p>

          <button
            data-variant="text-danger"
            onClick={async () => {
              if (window.confirm(t("settings.account-deletion-description"))) {
                try {
                  await authController.deleteAccount();
                  navigate("/auth");
                } catch (error) {
                  console.error("Failed to delete user:", error);
                }
              }
            }}
          >
            Delete Account
          </button>
        </div>
      </div>
    </div>
  );
}

import { IconLoader2, IconPlus, IconRefresh } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useClubController } from "../../controller/club-controller";
import { useUserController } from "../../controller/user-controller";
import { useI18n } from "../../lang/i18n";
import { UserRole } from "../../model/member";
import AccountInfo from "./component/account";
import FooterDashboard from "./component/footer";
import Navbar from "./component/navbar";

export default function Clubs() {
  const navigate = useNavigate();
  const { t } = useI18n();

  const [newName, setNewName] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("eur");
  const [isCreating, setIsCreating] = useState(false);
  const clubController = useClubController();
  const userController = useUserController();
  const list = clubController.clubList ?? [];
  const isListLoading = clubController.isLoading;

  const [isLoading, setIsLoading] = useState(false);

  // Fetch available currencies from the price API
  const { data: priceData } = useQuery({
    queryKey: ["price"],
    queryFn: () =>
      fetch(process.env.PUBLIC_BACKEND_API_URL + "/public/price").then(
        async (res) => (await res.json()).data
      ),
  });

  // Extract available currencies from the price data
  const availableCurrencies = priceData?.price?.currency_options
    ? Object.keys(priceData.price.currency_options)
    : [];

  // Set default currency when currencies are loaded
  useEffect(() => {
    // Only set the currency if it hasn't been set yet or current selection is invalid
    if (
      availableCurrencies.length > 0 &&
      !availableCurrencies.includes(selectedCurrency)
    ) {
      if (availableCurrencies.includes("eur")) {
        setSelectedCurrency("eur");
      } else {
        setSelectedCurrency(availableCurrencies[0]);
      }
    }
  }, [availableCurrencies, selectedCurrency]);

  useEffect(() => {
    const roles = clubController.roles || [];

    if (roles.length === 1 && roles[0] === UserRole.member) {
      const club = list[0];
      navigate(`/club/${club._id}/trainings`);
    }
  }, [clubController.roles]);

  const handleSubmit = async () => {
    const user = userController.user;
    if (!user || !newName.trim()) return;

    setIsLoading(true);
    try {
      await clubController.post(newName, selectedCurrency);
      setNewName("");
      setIsCreating(false);
    } catch (error) {
      alert("Club creation error, try again later");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Navbar */}
      <Navbar />

      {/* Main Content */}
      <main className="flex-grow container mx-auto p-6 max-w-4xl">
        <div className="flex flex-col gap-6">
          {/* Header Section */}
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold ">
              {t("club-selection.your-clubs")}
            </h1>
            <button
              onClick={() => setIsCreating(true)}
              className="flex items-center gap-2"
            >
              <IconPlus className="h-4 w-4" />
              {t("general.new")}
            </button>
          </div>

          {/* Create Club Form */}
          {isCreating && (
            <div className="rounded-lg shadow-sm border p-6">
              <h2 className="text-lg font-semibold mb-4">
                {t("general.create")}
              </h2>
              <div className="space-y-4">
                <div>
                  <label
                    htmlFor="clubName"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    {t("club-selection.club-name")}
                  </label>
                  <input
                    id="clubName"
                    type="text"
                    placeholder={t("club-selection.enter-club-name")}
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    className="w-full px-3 py-2 border rounded-md shadow-sm focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label
                    htmlFor="currency"
                    className="block text-xs font-medium text-gray-400 mb-1"
                  >
                    Currency
                  </label>
                  <select
                    id="currency"
                    value={selectedCurrency}
                    onChange={(e) => setSelectedCurrency(e.target.value)}
                    className="w-full px-3 py-2 border rounded-md shadow-sm focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  >
                    {availableCurrencies.map((currency) => (
                      <option key={currency} value={currency}>
                        {currency.toUpperCase()}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex gap-3">
                  <button
                    onClick={handleSubmit}
                    disabled={isLoading}
                    className="flex items-center gap-2"
                  >
                    {isLoading ? (
                      <IconRefresh className="h-4 w-4 animate-spin" />
                    ) : null}
                    {t("general.create")}
                  </button>
                  <button onClick={() => setIsCreating(false)}>
                    {t("general.cancel")}
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Clubs List */}
          {isListLoading ? (
            <div className="text-center py-12 rounded-lg shadow-sm border">
              <IconLoader2 className="h-6 w-6 animate-spin mx-auto mb-2" />
            </div>
          ) : list.length > 0 ? (
            <div className="rounded-lg shadow-sm border">
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="px-6 py-3 text-left text-sm font-semibold border-b">
                        {t("club-selection.club-name")}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y ">
                    {list.map((club) => (
                      <tr
                        key={club._id?.toString()}
                        onClick={() => navigate(`/club/${club._id}/settings`)}
                        className="cursor-pointer w-full"
                      >
                        <td className="px-6 py-4 text-sm ">
                          {club.name ?? "-"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="text-center py-12 rounded-lg shadow-sm border">
              <p className="text-gray-500">
                {t("club-selection.no-clubs-created")}
              </p>
            </div>
          )}
        </div>

        <div className="mt-8">
          <AccountInfo />
        </div>
      </main>

      <FooterDashboard />
    </div>
  );
}

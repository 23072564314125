import { IFitetPlayer } from "../model/fitet-player";
import { Client } from "./client";

export class FitetApi {
  private client: Client;

  constructor(client: Client) {
    this.client = client;
  }

  async searchPlayer(id_club: string, value: string): Promise<IFitetPlayer[]> {
    if (!value || value.length < 3) return [];

    const response = await this.client.fetch(
      `/club/${id_club}/fitet/search-player`,
      {
        method: "POST",
        body: JSON.stringify({ value }),
      }
    );

    return response.json();
  }
}

import { useI18n } from "../../../../../lang/i18n";
import { Report, ReportType } from "../../../../../model/report";
import Visible from "../../../component/visible";
import { SignatureFieldName } from "../_models/map";

type SignatureProps = {
  data: Report;
  setSelectedSignatureField: (field: SignatureFieldName) => void;
};

export const Signatures = ({
  data,
  setSelectedSignatureField,
}: SignatureProps) => {
  const { t } = useI18n();

  return (
    <div className="flex flex-col gap-2 items-start">
      <span className="font-bold">{t("reports.signatures")}</span>

      <div className="flex flex-wrap gap-4 whitespace-nowrap mt-2">
        <button
          className={
            data.day_info.referee_sign_1 ? "bg-green-600 text-white" : ""
          }
          onClick={async () => {
            setSelectedSignatureField(SignatureFieldName.REFEREE_SIGN_1);
            (document.getElementById("my_modal_2") as any).showModal();
          }}
        >
          {t("reports.referee-acceptance")}
        </button>

        <Visible when={data.type !== ReportType.miniSwaythlingDoppio}>
          <button
            className={
              data.day_info.defib_referee_sign ? "bg-green-600 text-white" : ""
            }
            onClick={async () => {
              setSelectedSignatureField(SignatureFieldName.DEFIB_REFEREE_SIGN);
              (document.getElementById("my_modal_2") as any).showModal();
            }}
          >
            {t("reports.referee-defibrillator")}
          </button>
        </Visible>

        <button
          className={
            data.day_info.referee_sign_2 ? "bg-green-600 text-white" : ""
          }
          onClick={async () => {
            setSelectedSignatureField(SignatureFieldName.REFEREE_SIGN_2);
            (document.getElementById("my_modal_2") as any).showModal();
          }}
        >
          {t("reports.referee-end")}
        </button>

        <button
          className={
            data.day_info.captain_home_sign ? "bg-green-600 text-white" : ""
          }
          onClick={async () => {
            setSelectedSignatureField(SignatureFieldName.CAPTAIN_HOME_SIGN);
            (document.getElementById("my_modal_2") as any).showModal();
          }}
        >
          {t("reports.captain-home")}
        </button>

        <button
          className={
            data.day_info.captain_away_sign ? "bg-green-600 text-white" : ""
          }
          onClick={async () => {
            setSelectedSignatureField(SignatureFieldName.CAPTAIN_AWAY_SIGN);
            (document.getElementById("my_modal_2") as any).showModal();
          }}
        >
          {t("reports.captain-away")}
        </button>

        <button
          className={
            data.day_info.defib_boss_sign ? "bg-green-600 text-white" : ""
          }
          onClick={async () => {
            setSelectedSignatureField(SignatureFieldName.DEFIB_BOSS_SIGN);
            (document.getElementById("my_modal_2") as any).showModal();
          }}
        >
          {t("reports.manager")}
        </button>

        <Visible when={data.type === ReportType.miniSwaythling}>
          <button
            className={
              data.day_info.second_referee_sign_end
                ? "bg-green-600 text-white"
                : ""
            }
            onClick={async () => {
              setSelectedSignatureField(
                SignatureFieldName.SECOND_REFEREE_SIGN_END
              );
              (document.getElementById("my_modal_2") as any).showModal();
            }}
          >
            {t("reports.second-referee-end")}
          </button>
        </Visible>
      </div>
    </div>
  );
};

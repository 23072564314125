import { User } from "../model/user";
import { Client } from "./client";

export class UserApi {
  private client: Client;

  constructor(client: Client) {
    this.client = client;
  }

  async add({ email }: { email: string }): Promise<User> {
    const response = await this.client.fetch(`/user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });

    const json = await response.json();

    return json.data as User;
  }

  async getMe({
    ignoreException = false,
  }: { ignoreException?: boolean } = {}): Promise<User | null> {
    const response = await this.client.fetch(
      `/user/me`,
      undefined,
      ignoreException
    );

    const json = await response.json();

    return json.data as User;
  }

  async patch(user: Partial<User>): Promise<User> {
    const response = await this.client.fetch(`/user/me`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });

    const json = await response.json();

    return json.user as User;
  }

  async uploadPicture(formData: FormData): Promise<void> {
    await this.client.fetch(`/user/me/picture`, {
      method: "POST",
      body: formData,
    });
  }

  async sendEmailVerification(email: string): Promise<void> {
    await this.client.fetch(`/send-email-verification`, {
      method: "POST",
      body: JSON.stringify({ email }),
    });
  }

  async logout(): Promise<void> {
    await this.client.fetch(`/user/logout`, {
      method: "POST",
    });
  }

  async deleteAccount(): Promise<void> {
    await this.client.fetch(`/user/me`, {
      method: "DELETE",
    });
  }
}

import { IconArrowRight } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useI18n } from "../../../lang/i18n";

export default function Hero({ id }: { id: string }) {
  const navigate = useNavigate();
  const { t } = useI18n();

  return (
    <div className="max-w-4xl mx-auto text-center" id={id}>
      {/* Main Headline */}
      <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-8">
        {t("landing.hero.headline.prefix")}{" "}
        <span className="text-app-primary">
          {t("landing.hero.headline.highlight")}
        </span>
      </h1>

      {/* Subheadline */}
      <p className="text-xl text-gray-600 mb-12 max-w-2xl mx-auto">
        {t("landing.hero.subheadline")}
      </p>

      {/* CTA Button */}
      <button
        onClick={() => navigate("/auth")}
        data-variant="primary"
        className="group inline-flex items-center gap-2 bg-app-primary px-8 py-4 rounded-lg text-lg font-medium hover:bg-opacity-90 transition-all transform hover:translate-y-[-2px]"
      >
        {t("landing.hero.cta")}
        <IconArrowRight className="w-5 h-5 transition-transform group-hover:translate-x-1" />
      </button>

      {/* Social Proof */}
      <p className="mt-6 text-sm">{t("landing.hero.social-proof")}</p>
    </div>
  );
}

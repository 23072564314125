import { useI18n } from "../../../../../lang/i18n";
import { Report, ReportType } from "../../../../../model/report";
import Visible from "../../../component/visible";

export interface DayInfoProps {
  report: Report;
  setData: (data: Report) => void;
}

export const DayInfoComponent = ({ report, setData }: DayInfoProps) => {
  const { t } = useI18n();

  const update = (partial: Partial<Report>) => {
    setData({ ...report, ...partial });
  };

  return (
    <div className="flex flex-col">
      <span className="font-bold">{t("reports.day-info")}</span>

      <input
        placeholder={t("reports.date")}
        className="mt-2"
        value={report.day_info.date}
        onChange={(e) => {
          update({
            day_info: { ...report.day_info, date: e.target.value },
          });
        }}
      />
      <input
        placeholder={t("reports.place")}
        value={report.day_info.place}
        onChange={(e) => {
          update({
            day_info: { ...report.day_info, place: e.target.value },
          });
        }}
      />
      <input
        placeholder={t("reports.serie")}
        value={report.day_info.serie}
        onChange={(e) => {
          update({
            day_info: { ...report.day_info, serie: e.target.value },
          });
        }}
      />
      <input
        placeholder={t("reports.match-place")}
        value={report.day_info.match_place}
        onChange={(e) => {
          update({
            day_info: { ...report.day_info, match_place: e.target.value },
          });
        }}
      />
      <input
        placeholder={t("reports.address")}
        value={report.day_info.address}
        onChange={(e) => {
          update({
            day_info: { ...report.day_info, address: e.target.value },
          });
        }}
      />
      <input
        placeholder={t("reports.table")}
        value={report.day_info.table}
        onChange={(e) => {
          update({
            day_info: { ...report.day_info, table: e.target.value },
          });
        }}
      />
      <input
        placeholder={t("reports.balls-type")}
        value={report.day_info.balls}
        onChange={(e) => {
          update({
            day_info: { ...report.day_info, balls: e.target.value },
          });
        }}
      />

      <input
        placeholder={t("reports.team-home")}
        value={report.team_home?.name}
        onChange={(e) => {
          update({
            team_home: { ...report.team_home, name: e.target.value },
          });
        }}
      />

      <input
        placeholder={t("reports.team-away")}
        value={report.team_away?.name}
        onChange={(e) => {
          update({
            team_away: { ...report.team_away, name: e.target.value },
          });
        }}
      />

      <div className="flex flex-row">
        <input
          placeholder={t("reports.referee-name")}
          value={report.day_info.referee_name}
          onChange={(e) => {
            update({
              day_info: { ...report.day_info, referee_name: e.target.value },
            });
          }}
        />
        <input
          placeholder={t("reports.referee-role")}
          value={report.day_info.referee_role}
          onChange={(e) => {
            update({
              day_info: { ...report.day_info, referee_role: e.target.value },
            });
          }}
        />
      </div>

      <Visible when={report.type === ReportType.miniSwaythling}>
        <input
          placeholder={t("reports.referee-name") + " 2"}
          value={report.day_info.second_referee_name_end}
          onChange={(e) => {
            update({
              day_info: {
                ...report.day_info,
                second_referee_name_end: e.target.value,
              },
            });
          }}
        />
      </Visible>

      <div className="flex flex-col gap-2 items-start mt-4">
        <span className="font-bold">{t("reports.defibrillator-present")}</span>
        <div className="flex flex-row gap-2 justify-start">
          <label>
            <input
              type="radio"
              name="defib"
              className="mr-1"
              checked={report.day_info.defib_yes == "X"}
              onChange={() => {
                update({
                  day_info: {
                    ...report.day_info,
                    defib_yes: "X",
                    defib_no: "",
                  },
                });
              }}
            />
            {t("general.yes")}
          </label>
          <label>
            <input
              type="radio"
              name="defib"
              className="mr-1"
              checked={report.day_info.defib_no == "X"}
              onChange={() => {
                update({
                  day_info: {
                    ...report.day_info,
                    defib_no: "X",
                    defib_yes: "",
                  },
                });
              }}
            />
            {t("general.no")}
          </label>
        </div>

        <input
          placeholder={t("reports.defibrillator-operator-name")}
          value={report.day_info.defib_operator_name}
          onChange={(e) => {
            update({
              day_info: {
                ...report.day_info,
                defib_operator_name: e.target.value,
              },
            });
          }}
        />
      </div>
    </div>
  );
};

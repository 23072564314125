import {
  IconCalendarStats,
  IconChartBar,
  IconLanguage,
  IconUsers,
} from "@tabler/icons-react";
import { useI18n } from "../../../lang/i18n";

export default function Benefit({ id }: { id: string }) {
  const { t } = useI18n();

  return (
    <section className="py-16" id={id}>
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            {t("landing.benefit.title")}
          </h2>
          <p className="text-xl max-w-2xl mx-auto">
            {t("landing.benefit.subtitle")}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-12 max-w-5xl mx-auto">
          <BenefitCard
            icon={<IconCalendarStats className="w-8 h-8" />}
            title={t("landing.benefit.cards.club.title")}
            description={t("landing.benefit.cards.club.description")}
            highlight={t("landing.benefit.cards.club.highlight")}
          />

          <BenefitCard
            icon={<IconUsers className="w-8 h-8" />}
            title={t("landing.benefit.cards.players.title")}
            description={t("landing.benefit.cards.players.description")}
            highlight={t("landing.benefit.cards.players.highlight")}
          />

          <BenefitCard
            icon={<IconChartBar className="w-8 h-8" />}
            title={t("landing.benefit.cards.data.title")}
            description={t("landing.benefit.cards.data.description")}
            highlight={t("landing.benefit.cards.data.highlight")}
          />

          <BenefitCard
            icon={<IconLanguage className="w-8 h-8" />}
            title={t("landing.benefit.cards.languages.title")}
            description={t("landing.benefit.cards.languages.description")}
            highlight={t("landing.benefit.cards.languages.highlight")}
          />
        </div>
      </div>
    </section>
  );
}

function BenefitCard({
  icon,
  title,
  description,
  highlight,
}: {
  icon: React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
  highlight: React.ReactNode;
}) {
  return (
    <div className="flex flex-col items-center text-center">
      <div className="w-16 h-16 rounded-full bg-app-primary bg-opacity-10 flex items-center justify-center text-app-primary mb-6">
        {icon}
      </div>
      <h3 className="text-xl font-semibold mb-3 md:h-16">{title}</h3>
      <p className="mb-4 md:h-52">{description}</p>
      <span className="text-sm font-medium bg-app-primary bg-opacity-10 px-3 py-1 rounded-full">
        {highlight}
      </span>
    </div>
  );
}

import { Client } from "./client";

export class StripeApi {
  private client: Client;

  constructor(client: Client) {
    this.client = client;
  }

  async activate(): Promise<any> {
    const response = await this.client.fetch(`/stripe/activate`);

    return await response.json();
  }

  async account() {
    const response = await this.client.fetch(`/stripe/connect`);

    return await response.json();
  }

  async connect() {
    const response = await this.client.fetch(`/stripe/connect/create`);

    return await response.json();
  }
}

export enum ReportType {
  swaythling = "swaythling",
  miniSwaythling = "mini-swaythling",
  miniSwaythlingDoppio = "mini-swaythling-doppio",
}

export const reportTypeNameFromKey = (type: ReportType): string => {
  switch (type) {
    case ReportType.swaythling:
      return "Swaythling";
    case ReportType.miniSwaythling:
      return "Mini Swaythling";
    case ReportType.miniSwaythlingDoppio:
      return "Mini Swaythling Doppio";
  }
};

export enum MatchRelation {
  A_X = "A_X",
  B_Y = "B_Y",
  C_Z = "C_Z",
  B_X = "B_X",
  A_Z = "A_Z",
  C_Y = "C_Y",
  B_Z = "B_Z",
  C_X = "C_X",
  A_Y = "A_Y",
  DOUBLE = "double",
}

export const matchRelationFromString = (str: string): MatchRelation => {
  switch (str) {
    case "A_X":
      return MatchRelation.A_X;
    case "B_Y":
      return MatchRelation.B_Y;
    case "C_Z":
      return MatchRelation.C_Z;
    case "B_X":
      return MatchRelation.B_X;
    case "A_Z":
      return MatchRelation.A_Z;
    case "C_Y":
      return MatchRelation.C_Y;
    case "B_Z":
      return MatchRelation.B_Z;
    case "C_X":
      return MatchRelation.C_X;
    case "A_Y":
      return MatchRelation.A_Y;
    case "double":
      return MatchRelation.DOUBLE;
    default:
      throw new Error(`Unknown match relation: ${str}`);
  }
};

export enum TeamKey {
  ABC = "ABC",
  XYZ = "XYZ",
}

export enum PlayerKey {
  P1 = "P1",
  P2 = "P2",
  P3 = "P3",
  R1 = "R1",
  R2 = "R2",
  CAPTAIN = "CAPTAIN",
  COACH = "COACH",
  MEDIC = "MEDIC",
  MANAGER = "MANAGER",
  DOUBLE_ABC_1 = "DOUBLE_ABC_1",
  DOUBLE_ABC_2 = "DOUBLE_ABC_2",
  DOUBLE_XYZ_1 = "DOUBLE_XYZ_1",
  DOUBLE_XYZ_2 = "DOUBLE_XYZ_2",
}

export const matchRelations = [
  {
    key: MatchRelation.A_X,
    playerABC: PlayerKey.P1,
    playerXYZ: PlayerKey.P1,
  },
  {
    key: MatchRelation.B_Y,
    playerABC: PlayerKey.P2,
    playerXYZ: PlayerKey.P2,
  },
  {
    key: MatchRelation.C_Z,
    playerABC: PlayerKey.P3,
    playerXYZ: PlayerKey.P3,
  },
  {
    key: MatchRelation.B_X,
    playerABC: PlayerKey.P2,
    playerXYZ: PlayerKey.P1,
  },
  {
    key: MatchRelation.A_Z,
    playerABC: PlayerKey.P1,
    playerXYZ: PlayerKey.P3,
  },
  {
    key: MatchRelation.C_Y,
    playerABC: PlayerKey.P3,
    playerXYZ: PlayerKey.P2,
  },
  {
    key: MatchRelation.B_Z,
    playerABC: PlayerKey.P2,
    playerXYZ: PlayerKey.P3,
  },
  {
    key: MatchRelation.C_X,
    playerABC: PlayerKey.P3,
    playerXYZ: PlayerKey.P1,
  },
  {
    key: MatchRelation.A_Y,
    playerABC: PlayerKey.P1,
    playerXYZ: PlayerKey.P2,
  },
];

export class Report {
  _id?: string;
  id_user: string | null = null;
  id_club: string | null = null;
  name: string | null = null;
  version: number = 1;
  type: string = "swaythling";
  updated_at: string = new Date().toISOString();
  day_info: DayInfo = new DayInfo();
  teams: {
    [key in TeamKey]: TeamInfo;
  } = {
    ABC: new TeamInfo(),
    XYZ: new TeamInfo(),
  };
  team_home: TeamInfo = new TeamInfo();
  team_away: TeamInfo = new TeamInfo();

  matches: {
    [key in MatchRelation]: Match;
  } = {
    [MatchRelation.A_X]: new Match(),
    [MatchRelation.B_Y]: new Match(),
    [MatchRelation.C_Z]: new Match(),
    [MatchRelation.B_X]: new Match(),
    [MatchRelation.A_Z]: new Match(),
    [MatchRelation.C_Y]: new Match(),
    [MatchRelation.B_Z]: new Match(),
    [MatchRelation.C_X]: new Match(),
    [MatchRelation.A_Y]: new Match(),
    [MatchRelation.DOUBLE]: new Match(),
  };
}

export class DayInfo {
  balls: string = "";
  serie: string = "";
  date: string = "";
  place: string = "";
  referee_role: string = "";
  referee_name: string = "";
  referee_sign_1: string = "";
  referee_sign_2: string = "";
  defib_boss_sign: string = "";
  defib_operator_name: string = "";
  defib_referee_sign: string = "";
  captain_home_sign: string = "";
  captain_away_sign: string = "";
  captain_abc_sign: string = "";
  captain_xyz_sign: string = "";
  defib_yes: string = "";
  defib_no: string = "";
  table: string = "";
  match_place: string = "";
  address: string = "";
  match_start_time: string = "";
  match_end_time: string = "";
  match_win_by: string = "";
  match_win_score: string = "";
  second_referee_name_end: string = "";
  second_referee_sign_end: string = "";
}

export class Player {
  name: string = "";
  card: string = "";
}

export class TeamInfo {
  name: string = "";
  color: string = "";
  time: string = "";

  players: {
    [key in PlayerKey]: Player;
  } = {
    P1: new Player(),
    P2: new Player(),
    P3: new Player(),
    R1: new Player(),
    R2: new Player(),
    CAPTAIN: new Player(),
    COACH: new Player(),
    MEDIC: new Player(),
    MANAGER: new Player(),
    DOUBLE_ABC_1: new Player(),
    DOUBLE_ABC_2: new Player(),
    DOUBLE_XYZ_1: new Player(),
    DOUBLE_XYZ_2: new Player(),
  };
}

export class Set {
  points_abc: string = "";
  points_xyz: string = "";
}

export enum SetKey {
  S1 = "1",
  S2 = "2",
  S3 = "3",
  S4 = "4",
  S5 = "5",
}

export class Match {
  player_abc_override: Player | null = null;
  player_xyz_override: Player | null = null;
  sets: {
    [key in SetKey]: Set;
  } = {
    [SetKey.S1]: new Set(),
    [SetKey.S2]: new Set(),
    [SetKey.S3]: new Set(),
    [SetKey.S4]: new Set(),
    [SetKey.S5]: new Set(),
  };
}

export const getSetKeyFromNumber = (num: number): SetKey => {
  switch (num) {
    case 1:
      return SetKey.S1;
    case 2:
      return SetKey.S2;
    case 3:
      return SetKey.S3;
    case 4:
      return SetKey.S4;
    case 5:
      return SetKey.S5;
    default:
      throw new Error(`Unknown set number: ${num}`);
  }
};

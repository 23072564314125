import { IconCheck } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import OneSignal from "react-onesignal";
import { Link, useParams } from "react-router-dom";
import { useClubIdController } from "../../../controller/club-id-controller";
import { useMemberController } from "../../../controller/member-controller";
import { useUserController } from "../../../controller/user-controller";
import { useI18n } from "../../../lang/i18n";
import { DateUtils } from "../../../libs/date";
import { UserRole } from "../../../model/member";
import AccountInfo from "../component/account";
import Visible from "../component/visible";

export default function ClubSettings() {
  const { idClub } = useParams();

  const clubController = useClubIdController(idClub);
  const userController = useUserController();
  const memberController = useMemberController(idClub);
  const { t } = useI18n();

  const [newName, setNewName] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const club = clubController.club;
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [deviceId, setDeviceId] = useState("");
  const [token, setToken] = useState("");
  const [isNewsSubscribed, setIsNewsSubscribed] = useState(false);
  const [isNewsToggleLoading, setIsNewsToggleLoading] = useState(false);
  const [medicalExpirationEnabled, setMedicalExpirationEnabled] =
    useState(true);
  const [membershipExpirationEnabled, setMembershipExpirationEnabled] =
    useState(true);

  const currentMember = memberController.currentMember;
  const isOwner = currentMember?.user_role_list.includes(UserRole.owner);

  useEffect(() => {
    setNewName(clubController.club?.name ?? "");
    setMedicalExpirationEnabled(
      clubController.club?.enable_medical_expiration ?? true
    );
    setMembershipExpirationEnabled(
      clubController.club?.enable_membership_expiration ?? true
    );
  }, [clubController.club]);

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      if (!userController.user?._id) return;

      console.log("onesignal initialized");

      // Check if service worker is already installed
      const checkServiceWorker = async () => {
        if (!("serviceWorker" in navigator)) {
          console.error("Service Worker not supported");
          return;
        }

        try {
          const registration = await navigator.serviceWorker.getRegistration(
            "/OneSignalSDKWorker.js"
          );

          if (!registration) {
            // Service worker not installed, register it
            await navigator.serviceWorker.register("/OneSignalSDKWorker.js", {
              scope: "/",
            });
            console.log("OneSignal Service Worker installed successfully");
          } else {
            console.log("OneSignal Service Worker already installed");
          }
        } catch (error) {
          console.error("Error installing OneSignal Service Worker:", error);
        }
      };

      await checkServiceWorker();

      try {
        if (userController.user?._id) {
          console.log("onesignal logging in");
          await OneSignal.login(userController.user._id.toString());
        }

        const isOptedIn = OneSignal.User.PushSubscription?.optedIn;
        setIsSubscribed(!!isOptedIn);

        const subId = OneSignal.User.PushSubscription?.id;
        setDeviceId(subId ?? "");
        setToken(OneSignal.User.PushSubscription?.token ?? "");

        const stripeSubscriptionOk =
          clubController.status === "active" ||
          clubController.status === "trialing";

        if (subId && stripeSubscriptionOk) {
          await memberController.silentPatch(currentMember!._id!.toString(), {
            onesignal_subscription_id: subId,
          });
        } else {
          console.log("no sub id");
        }
      } catch (error) {
        console.error("Error checking subscription status:", error);
      }

      const checkNewsSubscription = async () => {
        if (!currentMember?._id) return;

        // Check if this club's ID is in the user's push_subscriptions array
        const isSubscribed =
          currentMember.push_subscriptions?.includes("news") ?? false;
        setIsNewsSubscribed(isSubscribed);
      };

      checkNewsSubscription();
    };

    const timer = setTimeout(checkSubscriptionStatus, 1000);
    return () => clearTimeout(timer);
  }, [
    clubController.status,
    currentMember,
    memberController,
    userController.user?._id,
  ]);

  const handleSave = async () => {
    if (!club?._id || !newName.trim()) return;

    try {
      setIsSaving(true);
      await clubController.patch(club._id, {
        name: newName,
        enable_medical_expiration: medicalExpirationEnabled,
        enable_membership_expiration: membershipExpirationEnabled,
      });
    } catch (error) {
      console.error("Failed to update club:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handlePushNotificationToggle = async () => {
    if (!userController.user?.email) {
      alert(t("settings.please-sign-in-to-manage-push-notifications"));
      return;
    }

    try {
      if (!OneSignal.User) {
        console.error("OneSignal not initialized");
        alert("Push notification service not ready. Please try again later.");
        return;
      }

      if (isSubscribed) {
        await OneSignal.User.PushSubscription.optOut();
        await memberController.patch(currentMember!._id!.toString(), {
          onesignal_subscription_id: null,
        });
        setIsSubscribed(false);
        setDeviceId("");
        setToken("");
      } else {
        const isPushSupported = OneSignal.Notifications.isPushSupported();

        if (!isPushSupported) {
          alert(t("settings.push-notifications-not-supported"));
          return;
        }

        await OneSignal.Notifications.requestPermission();

        const permission = await Notification.requestPermission();
        if (permission !== "granted") {
          alert(
            t(
              "settings.please-allow-notifications-to-enable-push-notifications"
            )
          );
          return;
        }

        try {
          console.log("user id", userController.user?._id);

          if (userController.user?._id) {
            await OneSignal.login(userController.user._id.toString());
          }
          await OneSignal.User.PushSubscription.optIn();
          const subId = OneSignal.User.PushSubscription?.id;

          await memberController.patch(currentMember!._id!.toString(), {
            onesignal_subscription_id: subId,
          });
          setIsSubscribed(true);
          setDeviceId(subId ?? "");
          setToken(OneSignal.User.PushSubscription?.token ?? "");
        } catch (error) {
          alert(t("settings.push-notifications-permission-denied"));
        }
      }
    } catch (error) {
      console.error("Error managing push notifications:", error);
      alert(t("settings.failed-to-manage-push-notification-settings"));
    }
  };

  const handleNewsNotificationToggle = async () => {
    if (!userController.user?.email) {
      alert(t("settings.please-sign-in-to-manage-news-notifications"));
      return;
    }

    try {
      setIsNewsToggleLoading(true);
      const isOptedIn = OneSignal.User.PushSubscription?.optedIn;
      if (!isOptedIn) {
        alert(t("settings.please-enable-push-notifications-first"));
        return;
      }

      const currentSubscriptions = currentMember?.push_subscriptions || [];
      const clubNewsKey = "news";

      if (isNewsSubscribed) {
        const updatedSubscriptions = currentSubscriptions.filter(
          (id) => id !== clubNewsKey
        );
        await memberController.patch(currentMember!._id!.toString(), {
          push_subscriptions: updatedSubscriptions,
        });
        setIsNewsSubscribed(false);
      } else {
        const updatedSubscriptions = [...currentSubscriptions, clubNewsKey];
        await memberController.patch(currentMember!._id!.toString(), {
          push_subscriptions: updatedSubscriptions,
        });
        setIsNewsSubscribed(true);
      }
    } catch (error) {
      console.error("Error managing news notifications:", error);
      alert(t("settings.failed-to-manage-news-notification-settings"));
    } finally {
      setIsNewsToggleLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-6 max-w-4xl ">
      <div className="flex flex-col gap-6">
        {/* Header */}
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold">{t("settings.club-settings")}</h1>
        </div>

        {/* Loading */}
        <Visible when={!club}>
          <div className="container mx-auto p-6 max-w-4xl">
            <div className="text-center py-12 rounded-lg shadow-sm border">
              <p className="text-gray-500">
                {t("settings.loading-club-settings")}
              </p>
            </div>
          </div>
        </Visible>

        {/* Settings Form */}
        <Visible when={!!isOwner}>
          <div className="rounded-lg shadow-sm border ">
            <div className="p-6">
              <div className="space-y-6">
                {/* Club Name Section */}
                <div className="space-y-4">
                  <div>
                    <label
                      htmlFor="clubName"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      {t("settings.club-name")}
                    </label>
                    <input
                      id="clubName"
                      type="text"
                      placeholder={t("settings.enter-club-name")}
                      value={newName}
                      onChange={(e) => setNewName(e.target.value)}
                      className="w-full px-3 py-2 border  rounded-md shadow-sm focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>

                {/* Medical Expiration Toggle */}
                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <label className="text-sm font-medium text-gray-700">
                      {t("settings.enable-medical-expiration")}
                    </label>
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        checked={medicalExpirationEnabled}
                        onChange={(e) =>
                          setMedicalExpirationEnabled(e.target.checked)
                        }
                        className="sr-only peer"
                      />
                      <div
                        className={`w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600`}
                      ></div>
                    </label>
                  </div>
                </div>

                {/* Membership Expiration Toggle */}
                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <label className="text-sm font-medium text-gray-700">
                      {t("settings.enable-membership-expiration")}
                    </label>
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        checked={membershipExpirationEnabled}
                        onChange={(e) =>
                          setMembershipExpirationEnabled(e.target.checked)
                        }
                        className="sr-only peer"
                      />
                      <div
                        className={`w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600`}
                      ></div>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {/* Form Actions */}
            <div className="px-6 py-4 border-t border-b">
              <div className="flex justify-end">
                <button
                  onClick={handleSave}
                  disabled={isSaving}
                  className="flex items-center gap-2"
                >
                  <IconCheck className="h-4 w-4" />
                  {isSaving ? t("general.saving") : t("general.save")}
                </button>
              </div>
            </div>

            {/* Club ID Display */}
            <div className="p-6">
              <div className="space-y-6">
                {/* Club ID Section */}
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      {t("settings.club-id")}
                    </label>
                    <p className="text-sm text-gray-500">{idClub}</p>
                  </div>
                </div>

                {/* Subscription Status Section */}
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      {t("settings.subscription.status")}
                    </label>
                    <p
                      className={`text-sm ${
                        clubController.status === "trialing"
                          ? "text-orange-500"
                          : clubController.status === "active"
                          ? "text-green-500"
                          : "text-red-500"
                      }`}
                    >
                      {(() => {
                        switch (clubController.status) {
                          case "trialing":
                            const formatted = DateUtils.formatDateFromMs(
                              clubController.trial_end
                            );
                            return (
                              t("settings.subscription.trial") +
                              " - " +
                              t("settings.subscription.trial-warning") +
                              " (" +
                              formatted +
                              ")"
                            );
                          case "active":
                            return t("settings.subscription.active");
                          case "past_due":
                            return t("settings.subscription.past-due");
                          case "canceled":
                            return t("settings.subscription.canceled");
                          case "unpaid":
                            return t("settings.subscription.unpaid");
                          case "paused":
                            return t("settings.subscription.paused");
                          case "incomplete":
                          case "incomplete_expired":
                            return t("settings.subscription.incomplete");
                          default:
                            return t("settings.subscription.inactive");
                        }
                      })()}
                    </p>
                    <a
                      href={clubController.stripe_subscription_link}
                      target="_blank"
                      className="mt-2 inline-block px-3 py-1.5 text-sm font-medium   hover:bg-opacity-80 rounded-md transition-colors"
                    >
                      {t("settings.subscription.view-stripe-subscription")}
                    </a>
                  </div>
                </div>

                {clubController.status === "canceled" && (
                  <div className="mt-4">
                    <button
                      onClick={() => clubController.reactivateSubscription()}
                      className="inline-block px-4 py-2 text-sm font-medium  bg-green-600 hover:bg-green-700 rounded-md transition-colors"
                    >
                      {t("settings.subscription.reactivate-subscription")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Visible>

        {/* Logout Section */}
        <AccountInfo />

        {/* Push Notifications */}
        <div className="rounded-lg shadow-sm border">
          <div className="p-6">
            <div className="flex flex-col space-y-0 mb-4">
              <h3 className="text-lg font-semibold">
                {t("settings.push-notifications")}
              </h3>
              <p className="text-sm">
                {t("settings.manage-your-push-notification-settings")}
              </p>
            </div>

            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <div>
                  <label className="text-sm font-medium">
                    {t("settings.enable-push-notifications")}
                  </label>
                  <p className="text-sm text-gray-500">
                    {t("settings.enable-or-disable-all-push-notifications")}
                  </p>
                </div>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={isSubscribed}
                    onChange={handlePushNotificationToggle}
                    className="checkbox-base"
                  />
                </label>
              </div>

              <div className="border-t pt-4 ">
                <div className="flex items-center justify-between">
                  <div>
                    <label className="text-sm font-medium">
                      {t("settings.news-push-notifications")}
                    </label>
                    <p className="text-sm text-gray-500">
                      {t(
                        "settings.receive-notifications-about-club-news-and-updates"
                      )}
                    </p>
                  </div>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={isNewsSubscribed}
                      onChange={handleNewsNotificationToggle}
                      disabled={isNewsToggleLoading}
                      className="checkbox-base"
                    />
                  </label>
                </div>
              </div>

              <div className="border-t pt-4">
                <label className="block text-sm font-medium mb-1">
                  {t("settings.device-id")}
                </label>
                <input
                  type="text"
                  readOnly
                  value={deviceId || "Not available"}
                  className="w-full px-3 py-2 text-sm border rounded-md text-gray-500"
                />
                <p className="mt-1 text-xs text-gray-500">
                  {t(
                    "settings.this-is-your-unique-device-identifier-for-push-notifications"
                  )}
                </p>
              </div>

              <div className="border-t pt-4">
                <label className="block text-sm font-medium mb-1">
                  {t("settings.push-notification-token")}
                </label>
                <input
                  type="text"
                  readOnly
                  value={token || "Not available"}
                  className="w-full px-3 py-2 text-sm border rounded-md text-gray-500"
                />
              </div>

              <div className="border-t pt-4">
                <p className="text-sm text-gray-700">
                  {t("settings.push-notifications-not-working")}
                </p>
                <Link
                  to={`/club/${idClub}/push-instructions`}
                  className="text-sm text-blue-600 hover:text-blue-800 hover:underline"
                >
                  {t("settings.view-troubleshooting-guide")}
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Danger Zone */}
        <Visible when={!!isOwner}>
          <div className="rounded-lg shadow-sm border border-red-200">
            <div className="p-6">
              <h3 className="text-lg font-semibold text-red-600 mb-4">
                {t("settings.danger-zone")}
              </h3>
              <div className="space-y-4">
                <p className="text-sm">
                  {t(
                    "settings.once-you-delete-a-club-there-is-no-going-back-please-be-certain"
                  )}
                </p>
                <button
                  disabled={true}
                  onClick={() => {
                    // Add delete confirmation modal/logic here
                  }}
                  className="bg-red-600 hover:bg-red-700 "
                >
                  {t("settings.delete-club")}
                </button>
              </div>
            </div>
          </div>
        </Visible>
      </div>
    </div>
  );
}

import { useQuery } from "react-query";
import { queryClient } from "../libs/react-query";
import { Club, ClubResponse } from "../model/club";
import { clubApi } from "../services/client";

export const useClubIdController = (id_club: string | undefined) => {
  const { data: clubResponse } = useQuery<ClubResponse>({
    queryKey: ["club", id_club],
    queryFn: async () => {
      return await clubApi.getId(id_club!);
    },
    enabled: !!id_club,
  });

  const patch = async (id: string, club: Partial<Club>) => {
    await clubApi.patch(id, club);

    queryClient.invalidateQueries(["club"]);
  };

  const postMember = async (id_club: string, emailUser: string) => {
    await clubApi.postMember(id_club, emailUser);

    queryClient.invalidateQueries(["club"]);
  };

  const reactivateSubscription = async () => {
    await clubApi.reactivateSubscription(id_club!);

    queryClient.invalidateQueries(["club"]);
  };

  return {
    club: clubResponse?.club,
    stripe_subscription_link: clubResponse?.stripe_subscription_link,
    patch,
    postMember,
    status: clubResponse?.subscription?.status,
    trial_end: clubResponse?.subscription?.trial_end,
    reactivateSubscription,
  };
};

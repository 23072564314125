import {
  IconArrowRight,
  IconChevronDown,
  IconDownload,
} from "@tabler/icons-react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDataController } from "../../../../../controller/data-controller";
import { useI18n } from "../../../../../lang/i18n";
import { MemberAttendance } from "../../../../../model/member-attendance";

export default function DataMemberAttendance() {
  const { idClub } = useParams();
  const { t } = useI18n();

  const [showMemberAttendance, setShowMemberAttendance] = useState(true);

  const {
    memberAttendance,
    exportMemberAttendanceCsv,
    exportNonMemberTrainingsCsv,
  } = useDataController(idClub);

  const [sortColumn, setSortColumn] = useState<string>("");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const getSortIndicator = (column: string) => {
    if (sortColumn !== column) return "↕";
    return sortDirection === "asc" ? "↑" : "↓";
  };

  const sortedMemberAttendance = memberAttendance
    ? [...memberAttendance].sort((a, b) => {
        if (!sortColumn) return 0;

        const aValue = a[sortColumn as keyof MemberAttendance];
        const bValue = b[sortColumn as keyof MemberAttendance];

        if (aValue === bValue) return 0;
        if (aValue === null || aValue === undefined) return 1;
        if (bValue === null || bValue === undefined) return -1;

        const comparison = aValue < bValue ? -1 : 1;
        return sortDirection === "asc" ? comparison : -comparison;
      })
    : [];

  return (
    <div className="generic-card">
      <div className="generic-card-header">
        <h2 className="generic-card-title">{t("data.member-attendance")}</h2>
        <div className="flex items-center gap-2">
          <button
            onClick={exportMemberAttendanceCsv}
            className="p-2 rounded-full transition-colors hover:bg-gray-200/50 dark:hover:bg-gray-700/50"
            title={t("data.export-to-csv")}
          >
            <IconDownload className="h-5 w-5 sm:h-6 sm:w-6 text-gray-500" />
          </button>
          <button
            onClick={() => setShowMemberAttendance(!showMemberAttendance)}
            className="p-1 rounded-full transition-transform duration-200 hover:bg-gray-200/50 dark:hover:bg-gray-700/50"
            style={{
              transform: showMemberAttendance
                ? "rotate(0deg)"
                : "rotate(-180deg)",
            }}
          >
            <IconChevronDown className="h-5 w-5 sm:h-6 sm:w-6 text-gray-500" />
          </button>
        </div>
      </div>

      {showMemberAttendance && (
        <div className="generic-card-body">
          {memberAttendance && memberAttendance.length > 0 ? (
            <div className="space-y-4">
              <table className="min-w-full divide-y ">
                <thead>
                  <tr>
                    <th
                      className="px-3 sm:px-6 py-2 sm:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-800/50"
                      onClick={() => handleSort("email")}
                    >
                      {t("general.email")} {getSortIndicator("email")}
                    </th>
                    <th
                      className="px-3 sm:px-6 py-2 sm:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-800/50"
                      onClick={() => handleSort("first_name")}
                    >
                      {t("general.first-name")} {getSortIndicator("first_name")}
                    </th>
                    <th
                      className="px-3 sm:px-6 py-2 sm:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-800/50"
                      onClick={() => handleSort("last_name")}
                    >
                      {t("general.last-name")} {getSortIndicator("last_name")}
                    </th>
                    <th
                      className="px-3 sm:px-6 py-2 sm:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-800/50"
                      onClick={() => handleSort("attendance_count")}
                    >
                      {t("data.training-attendance")}{" "}
                      {getSortIndicator("attendance_count")}
                    </th>
                    <th className="px-3 sm:px-6 py-2 sm:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {t("data.member-type")}
                    </th>
                    <th className="px-3 sm:px-6 py-2 sm:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                  </tr>
                </thead>
                <tbody className="divide-y">
                  {sortedMemberAttendance.map((member) => (
                    <tr
                      key={member.key}
                      className="hover:bg-gray-50 dark:hover:bg-gray-800/50"
                    >
                      <td className="px-3 sm:px-6 py-2 sm:py-4 whitespace-nowrap text-sm">
                        {member.email}
                      </td>
                      <td className="px-3 sm:px-6 py-2 sm:py-4 whitespace-nowrap text-sm">
                        {member.first_name}
                      </td>
                      <td className="px-3 sm:px-6 py-2 sm:py-4 whitespace-nowrap text-sm">
                        {member.last_name}
                      </td>
                      <td className="px-3 sm:px-6 py-2 sm:py-4 whitespace-nowrap text-sm">
                        {member.attendance_count}
                      </td>
                      <td className="px-3 sm:px-6 py-2 sm:py-4 whitespace-nowrap text-sm">
                        {!member.id_club_member && (
                          <span className="bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded">
                            {t("trainings.external")}
                          </span>
                        )}
                      </td>
                      <td className="px-3 sm:px-6 py-2 sm:py-4 whitespace-nowrap text-sm">
                        {member.id_club_member ? (
                          <Link
                            to={`/club/${idClub}/data/${member.id_club_member}`}
                            className="p-1 rounded-full transition-colors hover:bg-gray-200/50 dark:hover:bg-gray-700/50 inline-block"
                            title={t("data.view-member-details")}
                          >
                            <IconArrowRight className="h-5 w-5 text-gray-500" />
                          </Link>
                        ) : (
                          <button
                            onClick={() => exportNonMemberTrainingsCsv(member)}
                            className="p-1 rounded-full transition-colors hover:bg-gray-200/50 dark:hover:bg-gray-700/50 inline-block"
                            title={t("general.export-to-csv")}
                          >
                            <IconDownload className="h-5 w-5 text-gray-500" />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="text-center text-gray-500 py-6 sm:py-8 text-sm sm:text-base">
              {t("data.no-member-attendance-data")}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

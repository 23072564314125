import { IconUpload } from "@tabler/icons-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMemberController } from "../../../../controller/member-controller";
import { useI18n } from "../../../../lang/i18n";
import { Member, UserRole } from "../../../../model/member";
import MemberList from "./component/member-list";

export default function ImportMembersPage() {
  const { idClub } = useParams();
  const { t } = useI18n();
  const memberController = useMemberController(idClub);
  const navigate = useNavigate();

  const [previewData, setPreviewData] = useState<Member[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const memberListFromCsv = async (file: File) => {
    const preview: Member[] = [];
    const reader = new FileReader();

    return new Promise<Member[]>((resolve, reject) => {
      reader.onload = (event) => {
        try {
          const csvData = event.target?.result as string;
          const rows = csvData.split("\n");
          const headers = rows[0].toLowerCase().split(",");

          for (let i = 1; i < rows.length; i++) {
            const row = rows[i].split(",");
            if (row.length < headers.length) continue;

            const member: Member = {
              id_club: idClub || "",
              email: row[headers.indexOf("email")]?.trim() || "",
              first_name: row[headers.indexOf("first_name")]?.trim() || "",
              last_name: row[headers.indexOf("last_name")]?.trim() || "",
              medical_exam_expiration_date:
                row[headers.indexOf("medical_exam_expiration_date")]?.trim() ||
                null,
              membership_expiration_date:
                row[headers.indexOf("membership_expiration_date")]?.trim() ||
                null,
              user_role_list: [UserRole.member],
            };

            if (member.email && member.first_name && member.last_name) {
              preview.push(member);
            }
          }
          resolve(preview);
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = () => {
        reject(reader.error);
      };

      reader.readAsText(file);
    });
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setIsLoading(true);
    try {
      const preview = await memberListFromCsv(file);
      setPreviewData(preview);
      setSelectedFile(file);
    } catch (error) {
      console.error("Error previewing CSV:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmImport = async () => {
    if (!selectedFile) return;

    setIsLoading(true);
    try {
      await memberController.addBulk(previewData);
      setPreviewData([]);
      setSelectedFile(null);

      navigate(`/club/${idClub}/members`);
    } catch (error) {
      console.error("Error importing CSV:", error);
      alert(t("members.error-importing-csv"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelImport = () => {
    setPreviewData([]);
    setSelectedFile(null);
  };

  const downloadTemplate = () => {
    const headers = [
      "email",
      "first_name",
      "last_name",
      "medical_exam_expiration_date",
      "membership_expiration_date",
    ];
    const csvContent = [
      headers.join(","),
      "player@email.com,John,Doe,2024-12-31,2024-12-31",
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "members_template.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="p-4 rounded-lg mb-6">
      <div className="flex items-center justify-between gap-2 mb-2">
        <h2 className="font-semibold">{t("members.bulk-import-title")}</h2>

        <div className="flex items-center gap-4">
          <button
            onClick={downloadTemplate}
            className="text-sm text-blue-600 hover:text-blue-800 flex items-center gap-1"
          >
            1. {t("members.download-template")}
          </button>
          <span className="text-gray-400">→</span>
          <input
            type="file"
            accept=".csv"
            onChange={handleFileUpload}
            className="hidden"
            id="csv-upload"
          />
          <label
            htmlFor="csv-upload"
            className="text-sm text-blue-600 hover:text-blue-800 cursor-pointer flex items-center gap-1"
          >
            2. {t("members.upload-filled-template")}
            <IconUpload size={16} />
          </label>
        </div>
      </div>

      <p className="text-sm text-gray-500 mb-3">
        {t("members.bulk-import-description")}
      </p>

      {previewData.length > 0 && (
        <>
          <div className="flex justify-between items-center mb-4">
            <h3 className="font-medium">{t("members.preview-title")}</h3>
            <div className="flex gap-3">
              <button
                onClick={handleConfirmImport}
                disabled={isLoading}
                className={`px-4 py-2 bg-blue-600 rounded hover:bg-blue-700 text-white text-sm ${
                  isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {isLoading ? (
                  <span className="flex items-center gap-2">
                    <svg className="animate-spin h-4 w-4" viewBox="0 0 24 24">
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                        fill="none"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    {t("general.saving")}
                  </span>
                ) : (
                  <>
                    {t("members.confirm-import")} ({previewData.length}{" "}
                    {t("members.members")})
                  </>
                )}
              </button>
              <button
                onClick={handleCancelImport}
                disabled={isLoading}
                className={`px-4 py-2 border rounded text-sm ${
                  isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {t("general.cancel")}
              </button>
            </div>
          </div>

          <MemberList filteredMembers={previewData ?? []} />
        </>
      )}
    </div>
  );
}

import { useI18n } from "../../../lang/i18n";

export default function Feature({ id }: { id: string }) {
  const { t } = useI18n();

  return (
    <section className="py-16" id={id}>
      <div className="container mx-auto px-6">
        <div className="flex flex-wrap justify-center gap-4 max-w-5xl mx-auto">
          <FeatureTag
            tag={t("landing.feature.tags.trainingStats.tag")}
            description={t("landing.feature.tags.trainingStats.description")}
          />
          <FeatureTag
            tag={t("landing.feature.tags.smartSchedule.tag")}
            description={t("landing.feature.tags.smartSchedule.description")}
          />
          <FeatureTag
            tag={t("landing.feature.tags.playerPortal.tag")}
            description={t("landing.feature.tags.playerPortal.description")}
          />
          <FeatureTag
            tag={t("landing.feature.tags.quickUpdates.tag")}
            description={t("landing.feature.tags.quickUpdates.description")}
          />
          <FeatureTag
            tag={t("landing.feature.tags.webBased.tag")}
            description={t("landing.feature.tags.webBased.description")}
          />
          <FeatureTag
            tag={t("landing.feature.tags.teamTools.tag")}
            description={t("landing.feature.tags.teamTools.description")}
          />
          <FeatureTag
            tag={t("landing.feature.tags.matchReports.tag")}
            description={t("landing.feature.tags.matchReports.description")}
          />
          <FeatureTag
            tag={t("landing.feature.tags.mobileReady.tag")}
            description={t("landing.feature.tags.mobileReady.description")}
          />
        </div>
      </div>

      {/* Feature Highlights */}
      <div className="mt-24 grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
        <FeatureCard
          title={t("landing.feature.cards.trainingHub.title")}
          description={t("landing.feature.cards.trainingHub.description")}
        />
        <FeatureCard
          title={t("landing.feature.cards.memberPortal.title")}
          description={t("landing.feature.cards.memberPortal.description")}
        />
        <FeatureCard
          title={t("landing.feature.cards.matchReports.title")}
          description={t("landing.feature.cards.matchReports.description")}
        />
        <FeatureCard
          title={t("landing.feature.cards.clubAdmins.title")}
          description={t("landing.feature.cards.clubAdmins.description")}
        />
        <FeatureCard
          title={t("landing.feature.cards.dataExport.title")}
          description={t("landing.feature.cards.dataExport.description")}
        />
        <FeatureCard
          title={t("landing.feature.cards.federationReady.title")}
          description={t("landing.feature.cards.federationReady.description")}
        />
      </div>
    </section>
  );
}

function FeatureTag({
  tag,
  description,
}: {
  tag: React.ReactNode;
  description: React.ReactNode;
}) {
  return (
    <div className="group relative">
      <div className="flex items-center px-4 py-2 rounded-full border  shadow-sm hover:shadow-md transition-all cursor-pointer">
        <span className="text-app-primary font-medium"># {tag}</span>
      </div>

      {/* Tooltip */}
      <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 text-sm rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all whitespace-nowrap">
        <div className="bg-gray-800 text-white">{description}</div>
        {/* Arrow */}
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-2 h-2 bg-gray-800"></div>
      </div>
    </div>
  );
}

function FeatureCard({
  title,
  description,
}: {
  title: React.ReactNode;
  description: React.ReactNode;
}) {
  return (
    <div className="p-6 rounded-lg shadow-sm border hover:shadow-md transition-shadow">
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      <p>{description}</p>
    </div>
  );
}

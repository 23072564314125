import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./app";
import "./index.css";
import Auth from "./routes/auth";
import Clubs from "./routes/dashboard/club-list";
import ClubLayout from "./routes/dashboard/club/dashboard-layout";
import DataPage from "./routes/dashboard/club/data/data";
import DataMemberPage from "./routes/dashboard/club/data/data-member";
import ImportMembersPage from "./routes/dashboard/club/members/import-members-page";
import MemberListPage from "./routes/dashboard/club/members/member-list-page";
import MemberPage from "./routes/dashboard/club/members/member-page";
import NewsListPage from "./routes/dashboard/club/news/news-list";
import PushInstructions from "./routes/dashboard/club/push-instructions";
import ReportListPage from "./routes/dashboard/club/reports/report-list-page";
import ReportPage from "./routes/dashboard/club/reports/report-page";
import ClubSettings from "./routes/dashboard/club/settings";
import TrainingEdit from "./routes/dashboard/club/trainings/training-edit";
import TrainingList from "./routes/dashboard/club/trainings/training-list-page";
import TrainingPage from "./routes/dashboard/club/trainings/training-page";
import Contact from "./routes/landing/contact";
import Landing from "./routes/landing/landing";
import LandingLayout from "./routes/landing/landing-layout";
import NotFound from "./routes/not-found";

const router = createBrowserRouter([
  {
    path: "/.well-known/acme-challenge/*",
    element: null,
  },
  {
    path: "/privacy-policy.html",
    element: <App />,
  },
  {
    path: "/:lang?",
    element: <App />,
    children: [
      {
        element: <LandingLayout />,
        children: [
          {
            index: true,
            element: <Landing />,
          },
          {
            path: "contact",
            element: <Contact />,
          },
        ],
      },
      {
        path: "auth",
        element: <Auth />,
      },
      {
        path: "club",
        children: [
          {
            index: true,
            element: <Clubs />,
          },
          {
            path: ":idClub",
            element: <ClubLayout />,
            children: [
              {
                path: "reports",
                children: [
                  {
                    index: true,
                    element: <ReportListPage />,
                  },
                  {
                    path: ":idReport",
                    element: <ReportPage />,
                  },
                ],
              },
              {
                path: "members",
                children: [
                  {
                    index: true,
                    element: <MemberListPage />,
                  },
                  {
                    path: ":idMember",
                    element: <MemberPage />,
                  },
                  {
                    path: "import",
                    element: <ImportMembersPage />,
                  },
                ],
              },
              {
                path: "trainings",
                children: [
                  {
                    index: true,
                    element: <TrainingList />,
                  },
                  {
                    path: ":idTraining",
                    element: <TrainingPage />,
                  },
                  {
                    path: ":idTraining/edit",
                    element: <TrainingEdit />,
                  },
                ],
              },
              {
                path: "news",
                element: <NewsListPage />,
              },
              {
                path: "data",
                children: [
                  {
                    index: true,
                    element: <DataPage />,
                  },
                  {
                    path: ":idMember",
                    element: <DataMemberPage />,
                  },
                ],
              },
              {
                path: "settings",
                element: <ClubSettings />,
              },
              {
                path: "push-instructions",
                element: <PushInstructions />,
              },
            ],
          },
        ],
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>
);

import { IconChevronDown, IconDownload } from "@tabler/icons-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDataController } from "../../../../../controller/data-controller";
import { useI18n } from "../../../../../lang/i18n";
import TrainingList from "../../trainings/training-list";

export default function DataTrainingList() {
  const { t } = useI18n();
  const { idClub } = useParams();
  const [showTrainingList, setShowTrainingList] = useState(true);

  const { trainingList, exportTrainingListCsv } = useDataController(idClub);

  return (
    <div className="generic-card">
      <div className="generic-card-header">
        <h2 className="generic-card-title">{t("data.training-list")}</h2>
        <div className="flex items-center gap-2">
          <button
            onClick={exportTrainingListCsv}
            className="p-2 rounded-full transition-colors hover:bg-gray-200/50 dark:hover:bg-gray-700/50"
            title={t("general.export-to-csv")}
          >
            <IconDownload className="h-5 w-5 sm:h-6 sm:w-6 text-gray-500" />
          </button>
          <button
            onClick={() => setShowTrainingList(!showTrainingList)}
            className="p-1 rounded-full transition-transform duration-200 hover:bg-gray-200/50 dark:hover:bg-gray-700/50"
            style={{
              transform: showTrainingList ? "rotate(0deg)" : "rotate(-180deg)",
            }}
          >
            <IconChevronDown className="h-5 w-5 sm:h-6 sm:w-6 text-gray-500" />
          </button>
        </div>
      </div>

      {showTrainingList && (
        <div className="generic-card-body">
          {trainingList && trainingList.length > 0 ? (
            <TrainingList idClub={idClub} trainingList={trainingList} />
          ) : (
            <div className="text-center text-gray-500 py-6 sm:py-8 text-sm sm:text-base">
              {t("data.no-trainings-found")}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

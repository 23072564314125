import { useParams } from "react-router-dom";
import { useDataController } from "../../../../controller/data-controller";
import DataMemberAttendance from "./_components/data-member-attendance";
import DataRegisteredMembers from "./_components/data-registered-members";
import DataTrainingList from "./_components/data-training-list";
import DateFilters from "./_components/date-filters";

export default function DataPage() {
  const { idClub } = useParams();

  const {
    startDate,
    endDate,
    setStartDateByDateString,
    setEndDateByDateString,
  } = useDataController(idClub);

  return (
    <div className="container mx-auto p-3 sm:p-6 max-w-4xl space-y-4 sm:space-y-6">
      <DateFilters
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={setStartDateByDateString}
        onEndDateChange={setEndDateByDateString}
      />

      <DataTrainingList />

      <DataMemberAttendance />

      <DataRegisteredMembers />
    </div>
  );
}

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMemberController } from "../../../../controller/member-controller";
import { useTrainingIdController } from "../../../../controller/training-id-controller";
import { useI18n } from "../../../../lang/i18n";
import { UserRole } from "../../../../model/member";
import { Training } from "../../../../model/training";
import { TrainingInputData } from "./training-input-data";

export default function TrainingEdit() {
  const { idClub, idTraining } = useParams();
  const navigate = useNavigate();
  const { t } = useI18n();
  const trainingIdController = useTrainingIdController(idClub, idTraining);
  const { currentMember } = useMemberController(idClub);
  const training = trainingIdController.training;

  const [trainingUpdate, setTrainingUpdate] = useState<Partial<Training>>(
    training || {}
  );

  const [periodicUpdate, setPeriodicUpdate] = useState(false);

  useEffect(() => {
    if (training) {
      setTrainingUpdate(training);
    }
  }, [training]);

  const isOwnerOrAdmin =
    currentMember?.user_role_list.includes(UserRole.owner) ||
    currentMember?.user_role_list.includes(UserRole.super_admin) ||
    currentMember?.user_role_list.includes(UserRole.admin);

  useEffect(() => {
    if (!isOwnerOrAdmin) {
      navigate(`/club/${idClub}/trainings/${idTraining}`);
    }
  }, [isOwnerOrAdmin, navigate, idClub, idTraining]);

  if (!training) {
    return <div>{t("general.loading")}</div>;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (periodicUpdate) {
        if (!training.periodic_reference) {
          alert(t("trainings.not-periodic"));
          return;
        }

        if (!confirm(t("trainings.confirm-update-all"))) {
          return;
        }

        await trainingIdController.saveAll(trainingUpdate);
      } else {
        await trainingIdController.save(trainingUpdate);
      }
      navigate(`/club/${idClub}/trainings/${idTraining}`);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 py-4 sm:py-6 max-w-4xl">
      <div className="rounded-lg shadow-sm border">
        <div className="p-4 sm:p-6 border-b">
          <h1 className="text-xl sm:text-2xl font-bold">
            {t("trainings.edit-training")}
          </h1>

          {training.periodic_reference && (
            <div className="mt-4 flex items-center gap-2">
              <input
                type="checkbox"
                id="updateAllLinked"
                checked={periodicUpdate}
                onChange={(e) => setPeriodicUpdate(e.target.checked)}
                className="rounded border-gray-300"
              />
              <label
                htmlFor="updateAllLinked"
                className="text-sm text-gray-700"
              >
                {t("trainings.update-all-linked")}
              </label>
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit}>
          <div className="p-4 sm:p-6 space-y-6">
            <TrainingInputData
              training={trainingUpdate}
              onChange={(training: Partial<Training>) =>
                setTrainingUpdate(training)
              }
              onAddMember={(email, firstName, lastName) => {
                trainingIdController.addMember(email, firstName, lastName);
              }}
              onRemoveMember={(trainingMember) => {
                trainingIdController.removeMember(trainingMember);
              }}
              periodicUpdate={periodicUpdate}
            />

            <div className="flex justify-end gap-4">
              <button
                type="button"
                onClick={() =>
                  navigate(`/club/${idClub}/trainings/${idTraining}`)
                }
                className="px-4 py-2 border rounded-md hover:bg-gray-50"
              >
                {t("general.cancel")}
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-app-primary rounded-md hover:bg-opacity-90"
              >
                {t("general.save")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

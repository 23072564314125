import { useI18n } from "../../../../../lang/i18n";
import {
  Match,
  MatchRelation,
  PlayerKey,
  Report,
  TeamKey,
  getSetKeyFromNumber,
  matchRelations,
} from "../../../../../model/report";
import Visible from "../../../component/visible";
import { haveDouble, matchCount } from "../_models/map";

export type MatchProps = {
  data: Report;
  setData: (data: Report) => void;
};

export const MatchesInput = ({ data, setData }: MatchProps) => {
  return (
    <div className="flex flex-col gap-4">
      <Visible when={haveDouble(data.type)}>
        <SingleMatchInput
          matchRelation={MatchRelation.DOUBLE}
          playerLeft1={
            data.teams[TeamKey.ABC]?.players[PlayerKey.DOUBLE_ABC_1]?.name
          }
          playerLeft2={
            data.teams[TeamKey.ABC]?.players[PlayerKey.DOUBLE_ABC_2]?.name
          }
          playerRight1={
            data.teams[TeamKey.XYZ]?.players[PlayerKey.DOUBLE_XYZ_1]?.name
          }
          playerRight2={
            data.teams[TeamKey.XYZ]?.players[PlayerKey.DOUBLE_XYZ_2]?.name
          }
          data={data}
          setData={setData}
        />
      </Visible>

      {Array.from({ length: matchCount(data.type) }).map((_, i) => {
        const matchRelation = matchRelations[i];

        const playerABC =
          data.matches[matchRelation.key]?.player_abc_override?.name ??
          data.teams[TeamKey.ABC]?.players[matchRelation.playerABC]?.name;

        const playerXYZ =
          data.matches[matchRelation.key]?.player_xyz_override?.name ??
          data.teams[TeamKey.XYZ]?.players[matchRelation.playerXYZ]?.name;

        return (
          <SingleMatchInput
            key={matchRelation.key}
            matchRelation={matchRelation.key}
            playerLeft1={playerABC}
            playerRight1={playerXYZ}
            data={data}
            setData={setData}
          />
        );
      })}
    </div>
  );
};

type SingleMatchInputProps = {
  matchRelation: MatchRelation;
  playerLeft1: string;
  playerLeft2?: string;
  playerRight1: string;
  playerRight2?: string;
  data: Report;
  setData: (data: Report) => void;
};

const SingleMatchInput = ({
  matchRelation,
  playerLeft1,
  playerRight1,
  data,
  setData,
}: SingleMatchInputProps) => {
  const { t } = useI18n();

  const displayName = (name: string) => name || t("reports.player");

  const isDoubleMatch = matchRelation === MatchRelation.DOUBLE;

  const handleDoublePlayerChange = (playerKey: PlayerKey, value: string) => {
    const team = playerKey.includes("ABC") ? TeamKey.ABC : TeamKey.XYZ;

    const updatedTeam = {
      ...data.teams[team],
      players: {
        ...data.teams[team]?.players,
        [playerKey]: { name: value },
      },
    };

    setData({
      ...data,
      teams: {
        ...data.teams,
        [team]: updatedTeam,
      },
    });
  };

  const handlePlayerOverride = (team: "abc" | "xyz", value: string) => {
    const match = data.matches[matchRelation] ?? new Match();

    const updatedMatch = {
      ...match,
      [`player_${team}_override`]: value.trim() ? { name: value } : undefined,
    };

    setData({
      ...data,
      matches: {
        ...data.matches,
        [matchRelation]: updatedMatch,
      },
    });
  };

  const playerABCOverride =
    data.matches[matchRelation]?.player_abc_override?.name;
  const playerXYZOverride =
    data.matches[matchRelation]?.player_xyz_override?.name;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row gap-2 items-center">
        {isDoubleMatch ? (
          <>
            <div className="flex flex-col w-full">
              <input
                type="text"
                className="border px-2 py-1 w-full"
                placeholder={t("reports.player")}
                value={
                  data.teams[TeamKey.ABC]?.players[PlayerKey.DOUBLE_ABC_1]
                    ?.name ?? ""
                }
                onChange={(e) =>
                  handleDoublePlayerChange(
                    PlayerKey.DOUBLE_ABC_1,
                    e.target.value
                  )
                }
              />
              <input
                type="text"
                className="border px-2 py-1 w-full"
                placeholder={t("reports.player")}
                value={
                  data.teams[TeamKey.ABC]?.players[PlayerKey.DOUBLE_ABC_2]
                    ?.name ?? ""
                }
                onChange={(e) =>
                  handleDoublePlayerChange(
                    PlayerKey.DOUBLE_ABC_2,
                    e.target.value
                  )
                }
              />
            </div>
            <span>vs</span>
            <div className="flex flex-col w-full">
              <input
                type="text"
                className="border px-2 py-1 w-full"
                placeholder={t("reports.player")}
                value={
                  data.teams[TeamKey.XYZ]?.players[PlayerKey.DOUBLE_XYZ_1]
                    ?.name ?? ""
                }
                onChange={(e) =>
                  handleDoublePlayerChange(
                    PlayerKey.DOUBLE_XYZ_1,
                    e.target.value
                  )
                }
              />
              <input
                type="text"
                className="border px-2 py-1 w-full"
                placeholder={t("reports.player")}
                value={
                  data.teams[TeamKey.XYZ]?.players[PlayerKey.DOUBLE_XYZ_2]
                    ?.name ?? ""
                }
                onChange={(e) =>
                  handleDoublePlayerChange(
                    PlayerKey.DOUBLE_XYZ_2,
                    e.target.value
                  )
                }
              />
            </div>
          </>
        ) : (
          <>
            <input
              type="text"
              className="border px-2 py-1 w-32"
              placeholder={displayName(playerLeft1)}
              value={playerABCOverride ?? ""}
              onChange={(e) => handlePlayerOverride("abc", e.target.value)}
            />
            <span>vs</span>
            <input
              type="text"
              className="border px-2 py-1 w-32"
              placeholder={displayName(playerRight1)}
              value={playerXYZOverride ?? ""}
              onChange={(e) => handlePlayerOverride("xyz", e.target.value)}
            />
          </>
        )}
      </div>

      <div className="flex flex-row gap-2">
        {Array.from({ length: 5 }).map((_, i) => {
          const setKey = getSetKeyFromNumber(i + 1);

          return (
            <div key={setKey} className="flex flex-row w-28">
              <input
                placeholder="0"
                className="border-r border-l-0 border-t-0 border-b-0 text-center px-0"
                value={data.matches[matchRelation]?.sets[setKey].points_abc}
                onChange={(e) => {
                  const match = data.matches[matchRelation] ?? new Match();
                  const set = match.sets[setKey];

                  try {
                    set.points_abc = e.target.value;
                  } catch (e) {
                    set.points_abc = "";
                  }

                  setData({
                    ...data,
                    matches: {
                      ...data.matches,
                      [matchRelation]: {
                        ...match,
                        sets: { ...match.sets, [setKey]: set },
                      },
                    },
                  });
                }}
              />

              <input
                placeholder="0"
                className="border-0 text-center px-0"
                value={data.matches[matchRelation]?.sets[setKey].points_xyz}
                onChange={(e) => {
                  const match = data.matches[matchRelation] ?? new Match();
                  const set = match.sets[setKey];

                  try {
                    set.points_xyz = e.target.value;
                  } catch (e) {
                    set.points_xyz = "";
                  }

                  setData({
                    ...data,
                    matches: {
                      ...data.matches,
                      [matchRelation]: {
                        ...match,
                        sets: { ...match.sets, [setKey]: set },
                      },
                    },
                  });
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

import { useQuery } from "react-query";
import { queryClient } from "../libs/react-query";
import { User } from "../model/user";
import { stripeApi, userApi } from "../services/client";

export const userQueryKey = "user-me";

export const getUser = ({
  ignoreException = false,
}: { ignoreException?: boolean } = {}) => {
  const { data: user } = useQuery({
    queryKey: userQueryKey,
    queryFn: () => userApi.getMe({ ignoreException }),
  });

  return user;
};

export const useUserController = () => {
  const user = getUser();

  const { data: connectAccountResponse } = useQuery({
    queryFn: () => stripeApi.account(),
    enabled: !!user?.id_stripe_connect,
    staleTime: 0,
    cacheTime: 0,
  });

  const patch = async (user: Partial<User>) => {
    await userApi.patch(user);
    queryClient.invalidateQueries(userQueryKey);
  };

  const newPicture = async (file: File) => {
    try {
      const formData = new FormData();

      formData.append("file", file);

      await userApi.uploadPicture(formData);

      queryClient.invalidateQueries(userQueryKey);
    } catch (error) {
      console.error(error);
      alert("Error uploading picture");
    }
  };

  const connect = async () => {
    const response = await stripeApi.connect();

    if (!!response.url) {
      window.location.href = response.url;
    }
  };

  return {
    user: user,
    patch: patch,
    newPicture: newPicture,
    account: connectAccountResponse?.account,
    createAccount: connect,
  };
};

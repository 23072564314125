import { atom, useAtom } from "jotai";
import { useQuery } from "react-query";
import { queryClient } from "../libs/react-query";
import { Training } from "../model/training";
import { trainingApi } from "../services/client";

const getTodayStartString = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today.toISOString();
};

const getTodayEndString = () => {
  const today = new Date();
  today.setHours(23, 59, 59, 999);
  return today.toISOString();
};

const filterStartDateAtom = atom<string>(getTodayStartString());
const filterEndDateAtom = atom<string>(getTodayEndString());

export const useTrainingController = (clubUid: string | undefined) => {
  const [filterStartDate, setFilterStartDate] = useAtom(filterStartDateAtom);
  const [filterEndDate, setFilterEndDate] = useAtom(filterEndDateAtom);

  const { data: trainingList } = useQuery({
    queryKey: ["training", clubUid, filterStartDate, filterEndDate],
    queryFn: async () => {
      return await trainingApi.get({
        id_club: clubUid!,
        startDate: filterStartDate,
        endDate: filterEndDate,
      });
    },
    enabled: !!clubUid,
  });

  const createTraining = async (training: Partial<Training>) => {
    await trainingApi.createTraining(clubUid!, training);
    queryClient.invalidateQueries(["training"]);
  };

  const deleteTraining = async (trainingId: string) => {
    await trainingApi.deleteTraining(clubUid!, trainingId);
    queryClient.invalidateQueries(["training"]);
  };

  const clearDateFilters = () => {
    setFilterStartDate(getTodayStartString());
    setFilterEndDate(getTodayEndString());
  };

  return {
    trainingList,
    createTraining,
    deleteTraining,
    filterStartDate,
    setFilterStartDate,
    filterEndDate,
    setFilterEndDate,
    clearDateFilters,
  };
};

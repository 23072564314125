import { addDays, format, isSameDay, startOfWeek } from "date-fns";
import { useEffect, useState } from "react";

interface HorizontalCalendarProps {
  selectedDate: Date | null;
  onDateSelect: (date: Date) => void;
}

export default function HorizontalCalendar({
  selectedDate,
  onDateSelect,
}: HorizontalCalendarProps) {
  const [weekStart, setWeekStart] = useState(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );

  // Generate array of dates for the current week
  const weekDates = Array.from({ length: 7 }, (_, i) => addDays(weekStart, i));

  const goToPreviousWeek = () => {
    setWeekStart((prev) => addDays(prev, -7));
  };

  const goToNextWeek = () => {
    setWeekStart((prev) => addDays(prev, 7));
  };

  // When selectedDate changes externally, update the week view
  useEffect(() => {
    if (selectedDate) {
      setWeekStart(startOfWeek(selectedDate, { weekStartsOn: 1 }));
    }
  }, [selectedDate]);

  const handleDateSelect = (date: Date) => {
    // Create a new date at midnight in local timezone
    const localDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    onDateSelect(localDate);
  };

  return (
    <div className="w-full max-w-2xl mx-auto px-2">
      <div className="flex items-center justify-between mb-4">
        <button
          onClick={goToPreviousWeek}
          className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full w-9 h-9 flex items-center justify-center transition-colors duration-200"
        >
          <span className="text-base text-gray-500 dark:text-gray-400">←</span>
        </button>
        <span className="font-medium text-base text-gray-700 dark:text-gray-200">
          {format(weekStart, "MMMM yyyy")}
        </span>
        <button
          onClick={goToNextWeek}
          className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full w-9 h-9 flex items-center justify-center transition-colors duration-200"
        >
          <span className="text-base text-gray-500 dark:text-gray-400">→</span>
        </button>
      </div>
      <div className="grid grid-cols-7 gap-1.5">
        {weekDates.map((date) => {
          const isSelected = selectedDate
            ? isSameDay(date, selectedDate)
            : false;
          return (
            <div
              key={date.toISOString()}
              className="text-center flex flex-col items-center"
            >
              <div
                className={`text-xs mb-1 font-medium
                  ${
                    isSelected
                      ? "text-blue-600 dark:text-blue-400"
                      : "text-gray-400 dark:text-gray-500"
                  }`}
              >
                {format(date, "EEE")}
              </div>
              <button
                onClick={() => handleDateSelect(date)}
                className={`w-8 h-8 rounded-lg flex items-center justify-center
                  transition-all duration-200 ease-in-out
                  ${
                    isSelected
                      ? "bg-blue-600 text-white shadow-md scale-105 hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600"
                      : "hover:bg-gray-50 dark:hover:bg-gray-700 text-gray-600 dark:text-gray-300"
                  }`}
              >
                <span
                  className={`text-sm ${
                    isSelected ? "font-semibold" : "font-medium"
                  }`}
                >
                  {format(date, "d")}
                </span>
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
}

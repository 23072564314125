import { atom } from "jotai";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../libs/react-query";
import { User } from "../model/user";
import { userApi } from "../services/client";

export const userAtom = atom<User | null>(null);

export const useAuth = () => {
  const navigate = useNavigate();

  const login = async (email: string, password: string) => {
    if (!email || !password) {
      throw new Error("Email and password are required");
    }

    try {
      const uri = `${process.env.PUBLIC_BACKEND_API_URL}/login`;

      const response = await fetch(uri, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to sign up");
      }

      queryClient.resetQueries();

      navigate("/club");
    } catch (e) {
      throw new Error("auth.login-error");
    }
  };

  const signup = async (email: string, password: string, otp: string) => {
    if (!email || !password || !otp) {
      throw new Error("Email, password and otp are required");
    }

    const uri = `${process.env.PUBLIC_BACKEND_API_URL}/signup`;

    const response = await fetch(uri, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password, otp }),
    });

    if (!response.ok) {
      throw new Error("Failed to sign up");
    }

    await login(email, password);
  };

  const resetPassword = async (email: string) => {
    try {
      const uri = `${process.env.PUBLIC_BACKEND_API_URL}/password/forgot`;

      const response = await fetch(uri, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error("Failed to send password reset email");
      }
    } catch (e) {
      alert("Error sending password reset email");
      throw e;
    }

    alert("Check your email for a reset link");
  };

  const sendEmailVerification = async (email: string) => {
    await userApi.sendEmailVerification(email);
  };

  const logout = async () => {
    await userApi.logout();
    queryClient.removeQueries();
  };

  const deleteAccount = async () => {
    await userApi.deleteAccount();
    await logout();
  };

  return {
    login,
    signup,
    logout,
    resetPassword,
    sendEmailVerification,
    deleteAccount,
  };
};

import { Report } from "../model/report";
import { Client } from "./client";

export class ReportApi {
  private client: Client;

  constructor(client: Client) {
    this.client = client;
  }

  async get(id_club: string): Promise<Report[]> {
    const response = await this.client.fetch(`/club/${id_club}/report`);

    const json = await response.json();

    return json.data as Report[];
  }

  async getById(id_club: string, id: string): Promise<Report> {
    const response = await this.client.fetch(`/club/${id_club}/report/${id}`);

    const json = await response.json();

    return json.data as Report;
  }

  async post(report: Report): Promise<string> {
    const response = await this.client.fetch(`/club/${report.id_club}/report`, {
      method: "POST",
      body: JSON.stringify({ report }),
    });

    const json = await response.json();

    if (json.error) {
      throw new Error(json.error);
    }

    return json.data.id;
  }

  async patch(
    id_club: string,
    id: string,
    report: Partial<Report>
  ): Promise<void> {
    await this.client.fetch(`/club/${id_club}/report/${id}`, {
      method: "PATCH",
      body: JSON.stringify({ report }),
    });
  }

  async delete(id_club: string, id: string): Promise<void> {
    await this.client.fetch(`/club/${id_club}/report/${id}`, {
      method: "DELETE",
    });
  }
}

import { IconLoader2, IconRefresh } from "@tabler/icons-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useMemberController } from "../../../../controller/member-controller";
import { useNewsController } from "../../../../controller/news-controller";
import { useI18n } from "../../../../lang/i18n";
import { DateUtils } from "../../../../libs/date";
import { queryClient } from "../../../../libs/react-query";
import { UserRole } from "../../../../model/member";

export default function NewsListPage() {
  const { t } = useI18n();
  const { idClub } = useParams();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const newsController = useNewsController(idClub);
  const memberController = useMemberController(idClub!);

  const currentMember = memberController.currentMember;
  const userRoleList = currentMember?.user_role_list ?? [];
  const isOwnerOrAdmin =
    userRoleList.includes(UserRole.owner) ||
    userRoleList.includes(UserRole.admin);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsCreating(true);
    try {
      await newsController.post(title, description);
      setTitle("");
      setDescription("");
      setShowForm(false);
    } finally {
      setIsCreating(false);
    }
  };

  const news = newsController.newsList ?? [];

  const handleDelete = async (newsId: string) => {
    if (window.confirm(t("news.delete-confirmation"))) {
      await newsController.deleteNews(newsId);

      setTitle("");
      setDescription("");
    }
  };

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  return (
    <div className="p-4 md:p-6 space-y-4 md:space-y-6 max-w-full overflow-x-hidden">
      <div className="flex justify-between items-center">
        <h1 className="text-xl md:text-2xl font-bold">{t("news.title")}</h1>

        <div id="actions" className="flex gap-4 items-center">
          <button
            onClick={() => queryClient.invalidateQueries(["news"])}
            className="p-1 hover:bg-gray-100 rounded-full"
          >
            <IconRefresh size={20} className="text-gray-600" />
          </button>

          {isOwnerOrAdmin && (
            <button
              onClick={toggleForm}
              className="bg-app-primary text-white px-4 py-2 rounded-md"
            >
              {showForm ? t("general.hide") : t("general.create")}
            </button>
          )}
        </div>
      </div>

      {isOwnerOrAdmin && showForm && (
        <form
          onSubmit={handleSubmit}
          className="space-y-3 md:space-y-4 mb-6 md:mb-8"
        >
          <div className="flex flex-col gap-1.5 md:gap-2">
            <label htmlFor="title" className="font-medium">
              {t("general.title")}
            </label>
            <input
              id="title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="border rounded-md p-2 w-full"
              required
            />
          </div>

          <div className="flex flex-col gap-1.5 md:gap-2">
            <label htmlFor="description" className="font-medium">
              {t("news.description")}
            </label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="border rounded-md p-2 h-32 w-full"
              required
            />
          </div>

          <button
            type="submit"
            disabled={isCreating}
            className="w-full md:w-auto bg-app-primary text-white px-4 py-2 rounded-md disabled:opacity-50"
          >
            {isCreating ? (
              <IconLoader2 className="animate-spin-progress" size={20} />
            ) : (
              t("general.create")
            )}
          </button>
        </form>
      )}

      <div className="overflow-x-auto -mx-4 md:mx-0">
        <div className="min-w-full px-4 md:px-0">
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="border p-2 md:p-3 text-left">
                  {t("news.title")}
                </th>
                <th className="border p-2 md:p-3 text-left">
                  {t("news.description")}
                </th>
                <th className="border p-2 md:p-3 text-left">
                  {t("general.created-at")}
                </th>
                {isOwnerOrAdmin && (
                  <th className="border p-2 md:p-3 text-left">
                    {t("general.actions")}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {news.map((item) => (
                <tr key={item._id}>
                  <td className="border p-2 md:p-3">{item.title}</td>
                  <td className="border p-2 md:p-3">
                    <div className="max-w-[200px] md:max-w-none break-words">
                      {item.description}
                    </div>
                  </td>
                  <td className="border p-2 md:p-3 whitespace-nowrap">
                    {DateUtils.dateStringToLocaleDateTimeString(
                      item.created_at
                    )}
                  </td>
                  {isOwnerOrAdmin && (
                    <td className="border p-2 md:p-3">
                      <button
                        onClick={() => handleDelete(item._id)}
                        className="bg-red-500 text-white px-2 py-1 md:px-3 md:py-1 rounded-md hover:bg-red-600"
                      >
                        {t("general.delete")}
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

import { useI18n } from "../../../lang/i18n";

export default function PushInstructions() {
  const { t } = useI18n();

  return (
    <div className="max-w-2xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6">
        {t("settings.push-notifications-not-working")}
      </h1>

      <div className="space-y-6">
        <section>
          <h2 className="text-xl font-semibold mb-4">
            {t("settings.push-instructions.ios.title")}
          </h2>
          <p className="mb-4">{t("settings.push-instructions.ios.intro")}</p>

          <ol className="list-decimal pl-6 space-y-6">
            <li>
              <p className="mb-2">
                {t("settings.push-instructions.ios.step1")}
              </p>
              <img
                src="/ios-push-0.jpg"
                alt="iOS Share Button"
                className="rounded-lg border shadow-sm max-w-sm"
              />
            </li>

            <li>
              <div className="space-y-2">
                <p>{t("settings.push-instructions.ios.step2")}</p>
                <img
                  src="/ios-push-1.jpg"
                  alt="Add to Home Screen"
                  className="rounded-lg border shadow-sm max-w-sm"
                />
              </div>
            </li>

            <li>
              <div className="space-y-2">
                <p>{t("settings.push-instructions.ios.step3")}</p>
                <img
                  src="/ios-push-2.jpg"
                  alt="Add to Home Screen"
                  className="rounded-lg border shadow-sm max-w-sm"
                />
              </div>
            </li>
          </ol>
        </section>

        <section className="p-4 rounded-lg">
          <h3 className="font-semibold mb-2">
            {t("settings.push-instructions.ios.note.title")}
          </h3>
          <p>{t("settings.push-instructions.ios.note.intro")}</p>
          <ul className="list-disc pl-6 mt-2">
            <li>{t("settings.push-instructions.ios.note.check1")}</li>
            <li>{t("settings.push-instructions.ios.note.check2")}</li>
          </ul>
        </section>

        <section className="border-t pt-4">
          <p className="text-gray-600">
            {t("settings.push-instructions.ios.support")}
          </p>
        </section>
      </div>
    </div>
  );
}

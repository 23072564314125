import {
  MatchRelation,
  PlayerKey,
  Report,
  ReportType,
  TeamKey,
  getSetKeyFromNumber,
  matchRelations,
} from "../../../../../model/report";

export enum SignatureFieldName {
  REFEREE_SIGN_1 = "referee_sign_1",
  DEFIB_BOSS_SIGN = "defib_boss_sign",
  DEFIB_REFEREE_SIGN = "defib_referee_sign",
  CAPTAIN_HOME_SIGN = "captain_home_sign",
  CAPTAIN_AWAY_SIGN = "captain_away_sign",
  TEAM_ABC_SIGN = "team_abc_sign",
  TEAM_XYZ_SIGN = "team_xyz_sign",
  REFEREE_SIGN_2 = "referee_sign_2",
  SECOND_REFEREE_SIGN_END = "second_referee_sign_end",
}

export const canDeuce = (type: string) => {
  switch (type) {
    case ReportType.swaythling:
      return false;
    case ReportType.miniSwaythling:
      return true;
    case ReportType.miniSwaythlingDoppio:
      return false;
    default:
      return false;
  }
};

export const matchCount = (type: string) => {
  switch (type) {
    case ReportType.swaythling:
      return 9;
    case ReportType.miniSwaythling:
      return 6;
    case ReportType.miniSwaythlingDoppio:
      return 6;
    default:
      return 9;
  }
};

export const haveDouble = (type: string) => {
  switch (type) {
    case ReportType.miniSwaythlingDoppio:
      return true;
    default:
      return false;
  }
};

export const signatureMapping = (report: Report) => {
  const map: Record<string, string | null> = {
    referee_sign: report.day_info[SignatureFieldName.REFEREE_SIGN_1],
    defib_boss_sign: report.day_info[SignatureFieldName.DEFIB_BOSS_SIGN],
    defib_referee_sign: report.day_info[SignatureFieldName.DEFIB_REFEREE_SIGN],
    captain_home_sign: report.day_info[SignatureFieldName.CAPTAIN_HOME_SIGN],
    captain_away_sign: report.day_info[SignatureFieldName.CAPTAIN_AWAY_SIGN],
    referee_sign_end: report.day_info[SignatureFieldName.REFEREE_SIGN_2],
    second_referee_sign_end:
      report.day_info[SignatureFieldName.SECOND_REFEREE_SIGN_END],
  } as Record<string, string | null>;

  if (report.team_home.name === report.teams[TeamKey.ABC]?.name) {
    map[SignatureFieldName.TEAM_ABC_SIGN] =
      report.day_info[SignatureFieldName.CAPTAIN_HOME_SIGN];
    map[SignatureFieldName.TEAM_XYZ_SIGN] =
      report.day_info[SignatureFieldName.CAPTAIN_AWAY_SIGN];
  } else {
    map[SignatureFieldName.TEAM_ABC_SIGN] =
      report.day_info[SignatureFieldName.CAPTAIN_AWAY_SIGN];
    map[SignatureFieldName.TEAM_XYZ_SIGN] =
      report.day_info[SignatureFieldName.CAPTAIN_HOME_SIGN];
  }

  return map;
};

export const textMapping = (report: Report) => {
  const map: Record<string, string | null> = {
    balls: report.day_info.balls,
    serie: report.day_info.serie,
    date: report.day_info.date,
    test: "",
    place: report.day_info.place,
    referee_role: report.day_info.referee_role,
    referee_name: report.day_info.referee_name,
    defib_operator_name: report.day_info.defib_operator_name,
    defib_yes: report.day_info.defib_yes,
    defib_no: report.day_info.defib_no,
    table: report.day_info.table,
    match_place: report.day_info.match_place,
    address: report.day_info.address,
    team_abc: report.team_home?.name,
    team_xyz: report.team_away?.name,
    team_abc_name: report.teams[TeamKey.ABC]?.name,
    team_abc_color: report.teams[TeamKey.ABC]?.color,
    team_abc_time: report.teams[TeamKey.ABC]?.time,
    team_xyz_name: report.teams[TeamKey.XYZ]?.name,
    team_xyz_color: report.teams[TeamKey.XYZ]?.color,
    team_xyz_time: report.teams[TeamKey.XYZ]?.time,
    player_a: report.teams[TeamKey.ABC]?.players[PlayerKey.P1]?.name,
    player_b: report.teams[TeamKey.ABC]?.players[PlayerKey.P2]?.name,
    player_c: report.teams[TeamKey.ABC]?.players[PlayerKey.P3]?.name,
    player_a_card: report.teams[TeamKey.ABC]?.players[PlayerKey.P1]?.card,
    player_b_card: report.teams[TeamKey.ABC]?.players[PlayerKey.P2]?.card,
    player_c_card: report.teams[TeamKey.ABC]?.players[PlayerKey.P3]?.card,
    player_abc_r1: report.teams[TeamKey.ABC]?.players[PlayerKey.R1]?.name,
    player_abc_r2: report.teams[TeamKey.ABC]?.players[PlayerKey.R2]?.name,
    player_abc_r1_card: report.teams[TeamKey.ABC]?.players[PlayerKey.R1]?.card,
    player_abc_r2_card: report.teams[TeamKey.ABC]?.players[PlayerKey.R2]?.card,
    team_abc_captain_name:
      report.teams[TeamKey.ABC]?.players[PlayerKey.CAPTAIN]?.name,
    team_abc_coach_name:
      report.teams[TeamKey.ABC]?.players[PlayerKey.COACH]?.name,
    team_abc_medic_name:
      report.teams[TeamKey.ABC]?.players[PlayerKey.MEDIC]?.name,
    team_abc_manager_name:
      report.teams[TeamKey.ABC]?.players[PlayerKey.MANAGER]?.name,
    team_abc_captain_card:
      report.teams[TeamKey.ABC]?.players[PlayerKey.CAPTAIN]?.card,
    team_abc_coach_card:
      report.teams[TeamKey.ABC]?.players[PlayerKey.COACH]?.card,
    team_abc_medic_card:
      report.teams[TeamKey.ABC]?.players[PlayerKey.MEDIC]?.card,
    team_abc_manager_card:
      report.teams[TeamKey.ABC]?.players[PlayerKey.MANAGER]?.card,
    player_x: report.teams[TeamKey.XYZ]?.players[PlayerKey.P1]?.name,
    player_y: report.teams[TeamKey.XYZ]?.players[PlayerKey.P2]?.name,
    player_z: report.teams[TeamKey.XYZ]?.players[PlayerKey.P3]?.name,
    player_x_card: report.teams[TeamKey.XYZ]?.players[PlayerKey.P1]?.card,
    player_y_card: report.teams[TeamKey.XYZ]?.players[PlayerKey.P2]?.card,
    player_z_card: report.teams[TeamKey.XYZ]?.players[PlayerKey.P3]?.card,
    player_xyz_r1: report.teams[TeamKey.XYZ]?.players[PlayerKey.R1]?.name,
    player_xyz_r2: report.teams[TeamKey.XYZ]?.players[PlayerKey.R2]?.name,
    player_xyz_r1_card: report.teams[TeamKey.XYZ]?.players[PlayerKey.R1]?.card,
    player_xyz_r2_card: report.teams[TeamKey.XYZ]?.players[PlayerKey.R2]?.card,
    team_xyz_captain_name:
      report.teams[TeamKey.XYZ]?.players[PlayerKey.CAPTAIN]?.name,
    team_xyz_coach_name:
      report.teams[TeamKey.XYZ]?.players[PlayerKey.COACH]?.name,
    team_xyz_medic_name:
      report.teams[TeamKey.XYZ]?.players[PlayerKey.MEDIC]?.name,
    team_xyz_manager_name:
      report.teams[TeamKey.XYZ]?.players[PlayerKey.MANAGER]?.name,
    team_xyz_captain_card:
      report.teams[TeamKey.XYZ]?.players[PlayerKey.CAPTAIN]?.card,
    team_xyz_coach_card:
      report.teams[TeamKey.XYZ]?.players[PlayerKey.COACH]?.card,
    team_xyz_medic_card:
      report.teams[TeamKey.XYZ]?.players[PlayerKey.MEDIC]?.card,
    team_xyz_manager_card:
      report.teams[TeamKey.XYZ]?.players[PlayerKey.MANAGER]?.card,
    match_start_time: report.day_info.match_start_time,
    match_end_time: report.day_info.match_end_time,
    match_win_by: report.day_info.match_win_by,
    match_win_score: report.day_info.match_win_score,
    referee_name_end: report.day_info.referee_name,
    second_referee_name_end: report.day_info.second_referee_name_end,
  } as Record<string, string | null>;

  let totalPointsAbc = 0;
  let totalPointsXyz = 0;
  let totalMatchWinAbc = 0;
  let totalMatchWinXyz = 0;

  if (haveDouble(report.type)) {
    const matchKey = MatchRelation.DOUBLE;

    let totalAbc: number = 0;
    let totalXyz: number = 0;

    const playerABC_1 =
      report.teams[TeamKey.ABC]?.players[PlayerKey.DOUBLE_ABC_1]?.name;
    const playerABC_2 =
      report.teams[TeamKey.ABC]?.players[PlayerKey.DOUBLE_ABC_2]?.name;

    const playerXYZ_1 =
      report.teams[TeamKey.XYZ]?.players[PlayerKey.DOUBLE_XYZ_1]?.name;
    const playerXYZ_2 =
      report.teams[TeamKey.XYZ]?.players[PlayerKey.DOUBLE_XYZ_2]?.name;

    map[`match_player_${matchKey}_1`] = playerABC_1;
    map[`match_player_${matchKey}_2`] = playerABC_2;
    map[`match_player_${matchKey}_3`] = playerXYZ_1;
    map[`match_player_${matchKey}_4`] = playerXYZ_2;

    let setsWinAbc: number = 0;
    let setsWinXyz: number = 0;

    for (let i = 1; i <= 5; i++) {
      const setKey = getSetKeyFromNumber(i);

      const pointsAbc: string =
        report.matches[MatchRelation.DOUBLE]?.sets[setKey].points_abc;
      const pointsXyz: string =
        report.matches[MatchRelation.DOUBLE]?.sets[setKey].points_xyz;

      const pointsAbcNum = Number(pointsAbc);
      const pointsXyzNum = Number(pointsXyz);

      if (pointsAbcNum > pointsXyzNum) {
        setsWinAbc++;
      } else if (pointsXyzNum > pointsAbcNum) {
        setsWinXyz++;
      }

      totalAbc += pointsAbcNum;
      totalXyz += pointsXyzNum;

      const isDeuce = pointsAbc === pointsXyz;
      if (isDeuce) {
        continue;
      }

      map[`${matchKey}_s${setKey}a`] = pointsAbc.toString();
      map[`${matchKey}_s${setKey}x`] = pointsXyz.toString();
      map[`${matchKey}_total_a`] = setsWinAbc.toString();
      map[`${matchKey}_total_x`] = setsWinXyz.toString();
    }

    if (setsWinAbc > setsWinXyz) {
      map[`${matchKey}_win_a`] = "1";
      map[`${matchKey}_win_x`] = "0";
      totalMatchWinAbc += 1;
    } else if (setsWinXyz > setsWinAbc) {
      map[`${matchKey}_win_a`] = "0";
      map[`${matchKey}_win_x`] = "1";
      totalMatchWinXyz += 1;
    }

    totalPointsAbc += totalAbc;
    totalPointsXyz += totalXyz;
  }

  for (const relation of matchRelations) {
    const relationToDocument = relation.key.replace(/_/g, "").toLowerCase();

    let totalAbc: number = 0;
    let totalXyz: number = 0;

    const playerABC =
      report.matches[relation.key]?.player_abc_override?.name ??
      report.teams[TeamKey.ABC]?.players[relation.playerABC]?.name;

    const playerXYZ =
      report.matches[relation.key]?.player_xyz_override?.name ??
      report.teams[TeamKey.XYZ]?.players[relation.playerXYZ]?.name;

    report.teams[TeamKey.ABC]?.players[PlayerKey.P1]?.name;
    const matchKey: string = relation.key.replace(/_/g, "").toLowerCase();

    const playerIndexAbc = relation.key.split("_")[0].toLowerCase();
    const playerIndexXyz = relation.key.split("_")[1].toLowerCase();

    map[`match_player_${matchKey}_${playerIndexAbc}`] = playerABC;
    map[`match_player_${matchKey}_${playerIndexXyz}`] = playerXYZ;

    let setsWinAbc: number = 0;
    let setsWinXyz: number = 0;

    for (let i = 1; i <= 5; i++) {
      const setKey = getSetKeyFromNumber(i);

      const pointsAbc: string =
        report.matches[relation.key]?.sets[setKey].points_abc;
      const pointsXyz: string =
        report.matches[relation.key]?.sets[setKey].points_xyz;

      const pointsAbcNum = Number(pointsAbc);
      const pointsXyzNum = Number(pointsXyz);

      if (pointsAbcNum > pointsXyzNum) {
        setsWinAbc++;
      } else if (pointsXyzNum > pointsAbcNum) {
        setsWinXyz++;
      }

      totalAbc += pointsAbcNum;
      totalXyz += pointsXyzNum;

      const isDeuce = pointsAbc === pointsXyz;
      if (isDeuce) {
        continue;
      }

      map[`${relationToDocument}_s${setKey}a`] = pointsAbc.toString();
      map[`${relationToDocument}_s${setKey}x`] = pointsXyz.toString();
      map[`${relationToDocument}_total_a`] = setsWinAbc.toString();
      map[`${relationToDocument}_total_x`] = setsWinXyz.toString();
    }

    const isDeuce = setsWinAbc === setsWinXyz;
    if (isDeuce) {
      continue;
    }

    if (setsWinAbc > setsWinXyz) {
      map[`${relationToDocument}_win_a`] = "1";
      map[`${relationToDocument}_win_x`] = "0";
      totalMatchWinAbc += 1;
    } else if (setsWinXyz > setsWinAbc) {
      map[`${relationToDocument}_win_a`] = "0";
      map[`${relationToDocument}_win_x`] = "1";
      totalMatchWinXyz += 1;
    }

    totalPointsAbc += totalAbc;
    totalPointsXyz += totalXyz;
  }

  map["match_total_points_abc"] = totalPointsAbc.toString();
  map["match_total_points_xyz"] = totalPointsXyz.toString();
  map["match_total_win_abc"] = totalMatchWinAbc.toString();
  map["match_total_win_xyz"] = totalMatchWinXyz.toString();

  if (totalMatchWinAbc > totalMatchWinXyz) {
    map["match_win_by"] = report.teams[TeamKey.ABC]?.name;
  } else if (totalMatchWinXyz > totalMatchWinAbc) {
    map["match_win_by"] = report.teams[TeamKey.XYZ]?.name;
  }

  if (canDeuce(report.type)) {
    const isDeuce = totalMatchWinAbc === totalMatchWinXyz;
    if (isDeuce) {
      map["match_deuce_points"] = `${totalMatchWinAbc} - ${totalMatchWinXyz}`;
    } else {
      map["match_win_score"] =
        totalMatchWinAbc.toString() + " - " + totalMatchWinXyz.toString();
    }
  } else {
    map["match_win_score"] =
      totalMatchWinAbc.toString() + " - " + totalMatchWinXyz.toString();
  }

  return map;
};

import { useQuery } from "react-query";
import { queryClient } from "../libs/react-query";
import { Report } from "../model/report";
import { reportApi } from "../services/client";

export const useReportController = (clubId: string | undefined) => {
  const { data: reportList } = useQuery<Report[]>({
    queryKey: ["report-list"],
    queryFn: async () => {
      return await reportApi.get(clubId!);
    },
    enabled: !!clubId,
  });

  const onNewClick = async (name: string, type: string): Promise<string> => {
    const report = new Report();

    report.name = name;
    report.type = type;
    report.id_club = clubId!;

    const newReportId = await reportApi.post(report);

    queryClient.invalidateQueries(["report-list"]);

    return newReportId;
  };
  return {
    onNewClick,
    reportList,
  };
};

export const miniSwaythlingJson = {
  type: "FRAME",
  name: "mini-swaythling",
  width: 2480,
  height: 3507,
  x: 535,
  y: 1979,
  fills: [],
  strokes: [],
  strokeWeight: 1,
  cornerRadius: 0,
  children: [
    {
      type: "GROUP",
      name: "Group 17",
      width: 2480,
      height: 3507,
      x: 0,
      y: 0,
      children: [
        {
          type: "RECTANGLE",
          name: "mini-swaythling 1",
          width: 2480,
          height: 3507,
          x: 0,
          y: 0,
          fills: [
            {
              type: "IMAGE",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              scaleMode: "FILL",
              imageTransform: [
                [1, 0, 0],
                [0, 1, 0],
              ],
              scalingFactor: 0.5,
              rotation: 0,
              filters: {
                exposure: 0,
                contrast: 0,
                saturation: 0,
                temperature: 0,
                tint: 0,
                highlights: 0,
                shadows: 0,
              },
              imageHash: "3263a554e8429e6d63ad754cb13eb7b4a3c5370d",
            },
          ],
          strokes: [],
          strokeWeight: 1,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc",
          width: 936,
          height: 46,
          x: 276,
          y: 620,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz",
          width: 936,
          height: 46,
          x: 1344,
          y: 621,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "balls",
          width: 431,
          height: 46,
          x: 1923,
          y: 548,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "serie",
          width: 408,
          height: 46,
          x: 1132,
          y: 475,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "date",
          width: 655,
          height: 46,
          x: 151,
          y: 383,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "place",
          width: 655,
          height: 46,
          x: 875,
          y: 382,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "referee_role",
          width: 408,
          height: 46,
          x: 613,
          y: 736,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "referee_name",
          width: 558,
          height: 46,
          x: 1431,
          y: 696,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "referee_sign",
          width: 603,
          height: 79,
          x: 1064,
          y: 773,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "defib_boss_sign",
          width: 603,
          height: 79,
          x: 1130,
          y: 1231,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "defib_operator_name",
          width: 603,
          height: 50,
          x: 573,
          y: 1185,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "defib_referee_sign",
          width: 603,
          height: 79,
          x: 505,
          y: 1042,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "captain_away_sign",
          width: 613,
          height: 71,
          x: 624,
          y: 861,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "captain_home_sign",
          width: 567,
          height: 71,
          x: 1822,
          y: 862,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "defib_yes",
          width: 82,
          height: 47,
          x: 1872,
          y: 998,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "defib_no",
          width: 82,
          height: 47,
          x: 1963,
          y: 998,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "table",
          width: 617,
          height: 46,
          x: 1121,
          y: 548,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_place",
          width: 617,
          height: 46,
          x: 1743,
          y: 475,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "address",
          width: 617,
          height: 46,
          x: 282,
          y: 548,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_name",
          width: 953,
          height: 34,
          x: 282,
          y: 1439,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_color",
          width: 925,
          height: 34,
          x: 310,
          y: 1485,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_time",
          width: 656,
          height: 34,
          x: 579,
          y: 1531,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_name",
          width: 883,
          height: 34,
          x: 1493,
          y: 1441,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_color",
          width: 883,
          height: 34,
          x: 1522,
          y: 1487,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_time",
          width: 609,
          height: 34,
          x: 1796,
          y: 1533,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_a",
          width: 811,
          height: 34,
          x: 155,
          y: 1633,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_a_card",
          width: 242,
          height: 34,
          x: 987,
          y: 1633,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_b_card",
          width: 242,
          height: 34,
          x: 987,
          y: 1684,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_c_card",
          width: 242,
          height: 34,
          x: 987,
          y: 1735,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_abc_r1_card",
          width: 242,
          height: 34,
          x: 987,
          y: 1784,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_abc_r2_card",
          width: 242,
          height: 34,
          x: 987,
          y: 1835,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_captain_card",
          width: 242,
          height: 34,
          x: 987,
          y: 1884,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_coach_card",
          width: 242,
          height: 34,
          x: 987,
          y: 1935,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_medic_card",
          width: 242,
          height: 34,
          x: 987,
          y: 1984,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_manager_card",
          width: 242,
          height: 34,
          x: 987,
          y: 2034,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_x_card",
          width: 262,
          height: 34,
          x: 2143,
          y: 1634,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_y_card",
          width: 262,
          height: 34,
          x: 2143,
          y: 1685,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_z_card",
          width: 262,
          height: 34,
          x: 2143,
          y: 1736,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_xyz_r1_card",
          width: 262,
          height: 34,
          x: 2143,
          y: 1785,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_xyz_r2_card",
          width: 262,
          height: 34,
          x: 2143,
          y: 1836,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_captain_card",
          width: 262,
          height: 34,
          x: 2143,
          y: 1885,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_coach_card",
          width: 262,
          height: 34,
          x: 2143,
          y: 1936,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_medic_card",
          width: 262,
          height: 34,
          x: 2143,
          y: 1985,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_manager_card",
          width: 262,
          height: 34,
          x: 2143,
          y: 2035,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_b",
          width: 811,
          height: 34,
          x: 155,
          y: 1684,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_c",
          width: 811,
          height: 34,
          x: 155,
          y: 1735,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_x",
          width: 767,
          height: 34,
          x: 1339,
          y: 1633,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_y",
          width: 767,
          height: 34,
          x: 1339,
          y: 1684,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_z",
          width: 767,
          height: 34,
          x: 1339,
          y: 1735,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_abc_r1",
          width: 690,
          height: 34,
          x: 276,
          y: 1785,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_abc_r2",
          width: 690,
          height: 34,
          x: 276,
          y: 1836,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_captain_name",
          width: 690,
          height: 34,
          x: 276,
          y: 1885,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_coach_name",
          width: 690,
          height: 34,
          x: 276,
          y: 1935,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_medic_name",
          width: 690,
          height: 34,
          x: 276,
          y: 1984,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_manager_name",
          width: 690,
          height: 34,
          x: 276,
          y: 2035,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_start_time",
          width: 357,
          height: 44,
          x: 500,
          y: 2112,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_ax_a",
          width: 504,
          height: 38,
          x: 126,
          y: 2280,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_by_b",
          width: 504,
          height: 38,
          x: 126,
          y: 2335,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_cz_c",
          width: 504,
          height: 38,
          x: 126,
          y: 2388,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_bx_b",
          width: 504,
          height: 38,
          x: 126,
          y: 2441,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_az_a",
          width: 504,
          height: 38,
          x: 126,
          y: 2491,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_cy_c",
          width: 504,
          height: 38,
          x: 126,
          y: 2544,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_end_time",
          width: 504,
          height: 46,
          x: 617,
          y: 2642,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_win_score",
          width: 475,
          height: 38,
          x: 1901,
          y: 2726,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_sign",
          width: 639,
          height: 80,
          x: 452,
          y: 2888,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_sign",
          width: 639,
          height: 80,
          x: 1701,
          y: 2888,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "second_referee_sign_end",
          width: 639,
          height: 80,
          x: 1684,
          y: 3081,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "second_referee_name_end",
          width: 639,
          height: 46,
          x: 639,
          y: 3107,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "referee_name_end",
          width: 639,
          height: 51,
          x: 638,
          y: 3007,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "referee_sign_end",
          width: 639,
          height: 80,
          x: 1671,
          y: 2984,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_win_by",
          width: 1295,
          height: 38,
          x: 464,
          y: 2726,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_deuce_points",
          width: 375,
          height: 49,
          x: 750,
          y: 2814,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_ax_x",
          width: 472,
          height: 38,
          x: 695,
          y: 2279,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s1a",
          width: 71,
          height: 38,
          x: 1187,
          y: 2280,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s1x",
          width: 71,
          height: 38,
          x: 1278,
          y: 2280,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s2a",
          width: 71,
          height: 38,
          x: 1367,
          y: 2280,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s4a",
          width: 71,
          height: 38,
          x: 1722,
          y: 2280,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s4x",
          width: 71,
          height: 38,
          x: 1811,
          y: 2280,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s5a",
          width: 71,
          height: 38,
          x: 1901,
          y: 2280,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s5x",
          width: 71,
          height: 38,
          x: 1987,
          y: 2280,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_total_a",
          width: 71,
          height: 38,
          x: 2077,
          y: 2280,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_total_points_abc",
          width: 71,
          height: 38,
          x: 2076,
          y: 2600,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_total_points_xyz",
          width: 71,
          height: 38,
          x: 2166,
          y: 2600,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_total_x",
          width: 71,
          height: 38,
          x: 2167,
          y: 2280,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_win_a",
          width: 72,
          height: 38,
          x: 2254,
          y: 2280,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_win_x",
          width: 71,
          height: 38,
          x: 2341,
          y: 2280,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_total_win_abc",
          width: 75,
          height: 38,
          x: 2252,
          y: 2600,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_total_win_xyz",
          width: 72,
          height: 38,
          x: 2341,
          y: 2600,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s3x",
          width: 71,
          height: 38,
          x: 1634,
          y: 2280,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s3a",
          width: 71,
          height: 38,
          x: 1545,
          y: 2280,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s2x",
          width: 71,
          height: 38,
          x: 1456,
          y: 2280,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s1a",
          width: 71,
          height: 38,
          x: 1187,
          y: 2334,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s1x",
          width: 71,
          height: 38,
          x: 1277,
          y: 2334,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s2a",
          width: 71,
          height: 38,
          x: 1367,
          y: 2334,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s4a",
          width: 71,
          height: 38,
          x: 1722,
          y: 2334,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s4x",
          width: 71,
          height: 38,
          x: 1811,
          y: 2334,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s5a",
          width: 71,
          height: 38,
          x: 1901,
          y: 2334,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s5x",
          width: 71,
          height: 38,
          x: 1987,
          y: 2334,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_total_a",
          width: 71,
          height: 38,
          x: 2077,
          y: 2334,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_total_x",
          width: 71,
          height: 38,
          x: 2167,
          y: 2334,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_win_a",
          width: 72,
          height: 38,
          x: 2254,
          y: 2334,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_win_x",
          width: 71,
          height: 38,
          x: 2341,
          y: 2334,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s3x",
          width: 71,
          height: 38,
          x: 1634,
          y: 2334,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s3a",
          width: 71,
          height: 38,
          x: 1545,
          y: 2334,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s2x",
          width: 71,
          height: 38,
          x: 1456,
          y: 2334,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s1a",
          width: 71,
          height: 38,
          x: 1188,
          y: 2386,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s1x",
          width: 71,
          height: 38,
          x: 1278,
          y: 2386,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s2a",
          width: 71,
          height: 38,
          x: 1368,
          y: 2386,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s4a",
          width: 71,
          height: 38,
          x: 1723,
          y: 2386,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s4x",
          width: 71,
          height: 38,
          x: 1812,
          y: 2386,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s5a",
          width: 71,
          height: 38,
          x: 1902,
          y: 2386,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s5x",
          width: 71,
          height: 38,
          x: 1988,
          y: 2386,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_total_a",
          width: 71,
          height: 38,
          x: 2078,
          y: 2386,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_total_x",
          width: 71,
          height: 38,
          x: 2168,
          y: 2386,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_win_a",
          width: 72,
          height: 38,
          x: 2255,
          y: 2386,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_win_x",
          width: 71,
          height: 38,
          x: 2342,
          y: 2386,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s3x",
          width: 71,
          height: 38,
          x: 1635,
          y: 2386,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s3a",
          width: 71,
          height: 38,
          x: 1546,
          y: 2386,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s2x",
          width: 71,
          height: 38,
          x: 1457,
          y: 2386,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s1a",
          width: 71,
          height: 38,
          x: 1188,
          y: 2440,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s1x",
          width: 71,
          height: 38,
          x: 1278,
          y: 2440,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s2a",
          width: 71,
          height: 38,
          x: 1368,
          y: 2440,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s4a",
          width: 71,
          height: 38,
          x: 1723,
          y: 2440,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s4x",
          width: 71,
          height: 38,
          x: 1812,
          y: 2440,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s5a",
          width: 71,
          height: 38,
          x: 1902,
          y: 2440,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s5x",
          width: 71,
          height: 38,
          x: 1988,
          y: 2440,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_total_a",
          width: 71,
          height: 38,
          x: 2078,
          y: 2440,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_total_x",
          width: 71,
          height: 38,
          x: 2168,
          y: 2440,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_win_a",
          width: 72,
          height: 38,
          x: 2255,
          y: 2440,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_win_x",
          width: 71,
          height: 38,
          x: 2342,
          y: 2440,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s3x",
          width: 71,
          height: 38,
          x: 1635,
          y: 2440,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s3a",
          width: 71,
          height: 38,
          x: 1546,
          y: 2440,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s2x",
          width: 71,
          height: 38,
          x: 1457,
          y: 2440,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s1a",
          width: 71,
          height: 38,
          x: 1188,
          y: 2490,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s1x",
          width: 71,
          height: 38,
          x: 1278,
          y: 2490,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s2a",
          width: 71,
          height: 38,
          x: 1368,
          y: 2490,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s4a",
          width: 71,
          height: 38,
          x: 1723,
          y: 2490,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s4x",
          width: 71,
          height: 38,
          x: 1812,
          y: 2490,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s5a",
          width: 71,
          height: 38,
          x: 1902,
          y: 2490,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s5x",
          width: 71,
          height: 38,
          x: 1988,
          y: 2490,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_total_a",
          width: 71,
          height: 38,
          x: 2078,
          y: 2490,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_total_x",
          width: 71,
          height: 38,
          x: 2168,
          y: 2490,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_win_a",
          width: 72,
          height: 38,
          x: 2255,
          y: 2490,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_win_x",
          width: 71,
          height: 38,
          x: 2342,
          y: 2490,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s3x",
          width: 71,
          height: 38,
          x: 1635,
          y: 2490,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s3a",
          width: 71,
          height: 38,
          x: 1546,
          y: 2490,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s2x",
          width: 71,
          height: 38,
          x: 1457,
          y: 2490,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s1a",
          width: 71,
          height: 38,
          x: 1188,
          y: 2544,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s1x",
          width: 71,
          height: 38,
          x: 1278,
          y: 2544,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s2a",
          width: 71,
          height: 38,
          x: 1368,
          y: 2544,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s4a",
          width: 71,
          height: 38,
          x: 1723,
          y: 2544,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s4x",
          width: 71,
          height: 38,
          x: 1812,
          y: 2544,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s5a",
          width: 71,
          height: 38,
          x: 1902,
          y: 2544,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s5x",
          width: 71,
          height: 38,
          x: 1988,
          y: 2544,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_total_a",
          width: 71,
          height: 38,
          x: 2078,
          y: 2544,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_total_x",
          width: 71,
          height: 38,
          x: 2168,
          y: 2544,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_win_a",
          width: 72,
          height: 38,
          x: 2255,
          y: 2544,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_win_x",
          width: 71,
          height: 38,
          x: 2342,
          y: 2544,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s3x",
          width: 71,
          height: 38,
          x: 1635,
          y: 2544,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s3a",
          width: 71,
          height: 38,
          x: 1546,
          y: 2544,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s2x",
          width: 71,
          height: 38,
          x: 1457,
          y: 2544,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_by_y",
          width: 472,
          height: 38,
          x: 695,
          y: 2334,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_cz_z",
          width: 472,
          height: 38,
          x: 695,
          y: 2387,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_bx_x",
          width: 472,
          height: 38,
          x: 695,
          y: 2440,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_az_z",
          width: 472,
          height: 38,
          x: 695,
          y: 2490,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_cy_y",
          width: 472,
          height: 38,
          x: 695,
          y: 2543,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_xyz_r1",
          width: 630,
          height: 34,
          x: 1476,
          y: 1784,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_xyz_r2",
          width: 630,
          height: 34,
          x: 1476,
          y: 1835,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_captain_name",
          width: 630,
          height: 34,
          x: 1476,
          y: 1884,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_coach_name",
          width: 630,
          height: 34,
          x: 1476,
          y: 1934,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_medic_name",
          width: 630,
          height: 34,
          x: 1476,
          y: 1983,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_manager_name",
          width: 630,
          height: 34,
          x: 1476,
          y: 2034,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
      ],
    },
  ],
  layoutMode: "NONE",
  primaryAxisSizingMode: "AUTO",
  counterAxisSizingMode: "FIXED",
  primaryAxisAlignItems: "MIN",
  counterAxisAlignItems: "MIN",
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: 0,
  itemSpacing: 0,
};

export const reportMiniSwaythlingKeys =
  miniSwaythlingJson.children[0].children.map((child) => child.name);

import React, { ReactNode } from "react";
import { useIntl } from "react-intl";

export const useI18n = () => {
  const intl = useIntl();

  const tNode = (
    id: string,
    params?: Record<string, ReactNode>
  ): string | ReactNode => {
    try {
      const messages = intl.messages as Record<string, any>;

      const result = id.split(".").reduce((acc, curr) => {
        return acc?.[curr];
      }, messages);

      if (typeof result === "string") {
        let translatedText: string = result;
        if (params) {
          const parts = translatedText.split(/(\{[^}]+\})/g);
          if (parts.length > 1) {
            return parts.map((part, index) => {
              const match = part.match(/^\{([^}]+)\}$/);
              if (match && params[match[1]]) {
                return React.createElement(
                  "span",
                  { key: index },
                  params[match[1]]
                );
              }
              return part;
            });
          }
        }
        return translatedText;
      }

      if (!id.startsWith("default.")) {
        const defaultResult = ("default." + id)
          .split(".")
          .reduce((acc, curr) => {
            return acc?.[curr];
          }, messages);

        if (typeof defaultResult === "string") {
          let translatedText: string = defaultResult;
          if (params) {
            Object.entries(params).forEach(([key, value]) => {
              translatedText = translatedText.replace(
                `{${key}}`,
                String(value)
              );
            });
          }
          return translatedText;
        }
      }

      const directMessage = messages[id];
      if (typeof directMessage === "string") {
        let translatedText: string = directMessage;
        if (params) {
          Object.entries(params).forEach(([key, value]) => {
            translatedText = translatedText.replace(`{${key}}`, String(value));
          });
        }
        return translatedText;
      }

      return id;
    } catch (error) {
      console.warn(`Translation key not found: ${id}`);
      return id;
    }
  };

  const t = (id: string, params?: Record<string, string>): string => {
    const result = tNode(id, params);
    if (typeof result === "string") {
      return result;
    }
    return React.isValidElement(result)
      ? result.props.children?.toString() || id
      : String(result);
  };

  return {
    t,
    tNode,
  };
};

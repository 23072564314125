import { Training, TrainingMember } from "../model/training";
import { Client } from "./client";

export class TrainingApi {
  private client: Client;

  constructor(client: Client) {
    this.client = client;
  }

  async get({
    id_club,
    startDate,
    endDate,
  }: {
    id_club: string;
    startDate?: string | null;
    endDate?: string | null;
  }): Promise<Training[]> {
    const uri = new URL(this.client.baseUrl);

    uri.pathname = `/club/${id_club}/training`;

    if (startDate) uri.searchParams.set("start_date", startDate);

    if (endDate) uri.searchParams.set("end_date", endDate);

    const pathname = uri.pathname + "?" + uri.searchParams.toString();

    const response = await this.client.fetch(pathname);

    const json = await response.json();
    return json.data as Training[];
  }

  async createTraining(
    id_club: string,
    training: Partial<Training>
  ): Promise<Training> {
    const response = await this.client.fetch(`/club/${id_club}/training`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        training,
        until_date: training.until_date,
      }),
    });
    const json = await response.json();
    return json.data as Training;
  }

  async deleteTraining(id_club: string, id_training: string): Promise<void> {
    await this.client.fetch(`/club/${id_club}/training/${id_training}`, {
      method: "DELETE",
    });
  }

  async getById(id_club: string, id: string): Promise<Training> {
    const response = await this.client.fetch(`/club/${id_club}/training/${id}`);
    const json = await response.json();
    return json.data as Training;
  }

  async patchById(
    id_club: string,
    id: string,
    training: Partial<Training>
  ): Promise<Training> {
    const response = await this.client.fetch(
      `/club/${id_club}/training/${id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(training),
      }
    );
    const json = await response.json();
    return json.data as Training;
  }

  async deleteById(id_club: string, id: string): Promise<void> {
    await this.client.fetch(`/club/${id_club}/training/${id}`, {
      method: "DELETE",
    });
  }

  async addMember(
    id_club: string,
    id_training: string,
    trainingMember: TrainingMember
  ): Promise<void> {
    await this.client.fetch(`/club/${id_club}/training/${id_training}/member`, {
      method: "POST",
      body: JSON.stringify(trainingMember),
    });
  }

  async removeMember(
    id_club: string,
    id_training: string,
    trainingMember: TrainingMember
  ): Promise<void> {
    await this.client.fetch(`/club/${id_club}/training/${id_training}/member`, {
      method: "DELETE",
      body: JSON.stringify(trainingMember),
    });
  }

  async updatePeriodicTraining(
    idClub: string,
    idTraining: string,
    training: Partial<Training>
  ): Promise<void> {
    await this.client.fetch(`/club/${idClub}/training/${idTraining}/periodic`, {
      method: "PATCH",
      body: JSON.stringify(training),
    });
  }
}

import { useQuery } from "react-query";
import { queryClient } from "../libs/react-query";
import { newsApi } from "../services/client";

export function useNewsController(clubId: string | undefined) {
  const { data: newsList } = useQuery({
    queryKey: ["news", clubId],
    queryFn: () => newsApi.getByClubId(clubId!),
    enabled: !!clubId,
  });

  const post = async (title: string, description: string) => {
    await newsApi.post(title, description, clubId!);
    queryClient.invalidateQueries({ queryKey: ["news", clubId] });
  };

  const deleteNews = async (newsId: string) => {
    try {
      await newsApi.deleteNews(clubId!, newsId);
      queryClient.invalidateQueries({ queryKey: ["news", clubId] });
    } catch (error) {
      console.error("Failed to delete news:", error);
    }
  };

  return {
    newsList,
    post,
    deleteNews,
  };
}

export const miniSwaythlingDoubleJson = {
  type: "FRAME",
  name: "Frame 16",
  width: 2480,
  height: 3508,
  x: 3138,
  y: 1978,
  fills: [],
  strokes: [],
  strokeWeight: 1,
  cornerRadius: 0,
  children: [
    {
      type: "GROUP",
      name: "Group 18",
      width: 2480,
      height: 3508,
      x: 0,
      y: 0,
      children: [
        {
          type: "RECTANGLE",
          name: "mini-swaythling-doppio 1",
          width: 2480,
          height: 3508,
          x: 0,
          y: 0,
          fills: [
            {
              type: "IMAGE",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              scaleMode: "FILL",
              imageTransform: [
                [1, 0, 0],
                [0, 1, 0],
              ],
              scalingFactor: 0.5,
              rotation: 0,
              filters: {
                exposure: 0,
                contrast: 0,
                saturation: 0,
                temperature: 0,
                tint: 0,
                highlights: 0,
                shadows: 0,
              },
              imageHash: "61e6c7e3495e9cd0f1b1199bee3270bd294e8a57",
            },
          ],
          strokes: [],
          strokeWeight: 1,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc",
          width: 936,
          height: 46,
          x: 276,
          y: 500,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz",
          width: 936,
          height: 46,
          x: 1344,
          y: 501,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "balls",
          width: 431,
          height: 46,
          x: 1823,
          y: 428,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "serie",
          width: 408,
          height: 46,
          x: 1199,
          y: 327,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "date",
          width: 655,
          height: 46,
          x: 151,
          y: 236,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "place",
          width: 655,
          height: 46,
          x: 875,
          y: 235,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "referee_role",
          width: 408,
          height: 46,
          x: 1084,
          y: 616,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "referee_name",
          width: 558,
          height: 46,
          x: 1635,
          y: 567,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "referee_sign",
          width: 603,
          height: 79,
          x: 569,
          y: 938,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "defib_boss_sign",
          width: 603,
          height: 79,
          x: 1255,
          y: 1120,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "defib_operator_name",
          width: 603,
          height: 50,
          x: 942,
          y: 1072,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "captain_away_sign",
          width: 562,
          height: 71,
          x: 679,
          y: 709,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "captain_home_sign",
          width: 542,
          height: 71,
          x: 1848,
          y: 709,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "defib_yes",
          width: 63,
          height: 47,
          x: 295,
          y: 897,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "defib_no",
          width: 56,
          height: 47,
          x: 410,
          y: 899,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "table",
          width: 546,
          height: 46,
          x: 1121,
          y: 428,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_place",
          width: 433,
          height: 46,
          x: 1943,
          y: 327,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "address",
          width: 617,
          height: 46,
          x: 282,
          y: 428,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_name",
          width: 870,
          height: 34,
          x: 358,
          y: 1328,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_color",
          width: 834,
          height: 34,
          x: 394,
          y: 1385,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_time",
          width: 564,
          height: 34,
          x: 664,
          y: 1448,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_name",
          width: 860,
          height: 34,
          x: 1466,
          y: 1327,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_color",
          width: 825,
          height: 34,
          x: 1501,
          y: 1385,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_time",
          width: 567,
          height: 34,
          x: 1759,
          y: 1450,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_a",
          width: 753,
          height: 34,
          x: 214,
          y: 1551,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_a_card",
          width: 242,
          height: 34,
          x: 987,
          y: 1551,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_b_card",
          width: 242,
          height: 34,
          x: 987,
          y: 1602,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_c_card",
          width: 242,
          height: 34,
          x: 987,
          y: 1653,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_abc_r1_card",
          width: 242,
          height: 34,
          x: 987,
          y: 1702,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_abc_r2_card",
          width: 242,
          height: 34,
          x: 987,
          y: 1753,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_captain_card",
          width: 242,
          height: 34,
          x: 987,
          y: 1802,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_coach_card",
          width: 242,
          height: 34,
          x: 987,
          y: 1853,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_medic_card",
          width: 242,
          height: 34,
          x: 987,
          y: 1902,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_manager_card",
          width: 242,
          height: 34,
          x: 987,
          y: 1952,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_x_card",
          width: 216,
          height: 34,
          x: 2110,
          y: 1550,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_y_card",
          width: 216,
          height: 34,
          x: 2110,
          y: 1601,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_z_card",
          width: 216,
          height: 34,
          x: 2110,
          y: 1652,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_xyz_r1_card",
          width: 216,
          height: 34,
          x: 2110,
          y: 1701,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_xyz_r2_card",
          width: 216,
          height: 34,
          x: 2110,
          y: 1752,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_captain_card",
          width: 216,
          height: 34,
          x: 2110,
          y: 1801,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_coach_card",
          width: 216,
          height: 34,
          x: 2110,
          y: 1852,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_medic_card",
          width: 216,
          height: 34,
          x: 2110,
          y: 1901,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_manager_card",
          width: 216,
          height: 34,
          x: 2110,
          y: 1951,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_b",
          width: 753,
          height: 34,
          x: 214,
          y: 1602,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_c",
          width: 753,
          height: 34,
          x: 214,
          y: 1653,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_x",
          width: 751,
          height: 34,
          x: 1330,
          y: 1551,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_y",
          width: 751,
          height: 34,
          x: 1330,
          y: 1602,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_z",
          width: 751,
          height: 34,
          x: 1330,
          y: 1653,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_abc_r1",
          width: 573,
          height: 34,
          x: 394,
          y: 1703,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_abc_r2",
          width: 573,
          height: 34,
          x: 394,
          y: 1754,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_captain_name",
          width: 573,
          height: 34,
          x: 394,
          y: 1803,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_coach_name",
          width: 573,
          height: 34,
          x: 394,
          y: 1853,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_medic_name",
          width: 573,
          height: 34,
          x: 394,
          y: 1902,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_manager_name",
          width: 573,
          height: 34,
          x: 394,
          y: 1953,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_start_time",
          width: 357,
          height: 44,
          x: 574,
          y: 2029,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_ax_a",
          width: 305,
          height: 38,
          x: 264,
          y: 2349,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "double_win_x",
          width: 71,
          height: 38,
          x: 2241,
          y: 2238,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "double_win_a",
          width: 71,
          height: 38,
          x: 2122,
          y: 2238,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "double_total_x",
          width: 71,
          height: 38,
          x: 2020,
          y: 2237,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "double_total_a",
          width: 71,
          height: 38,
          x: 1936,
          y: 2237,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "double_s5x",
          width: 71,
          height: 38,
          x: 1852,
          y: 2237,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "double_s5a",
          width: 71,
          height: 38,
          x: 1762,
          y: 2237,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "double_s4x",
          width: 71,
          height: 38,
          x: 1675,
          y: 2237,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "double_s4a",
          width: 71,
          height: 38,
          x: 1585,
          y: 2237,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "double_s3x",
          width: 71,
          height: 38,
          x: 1498,
          y: 2237,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "double_s3a",
          width: 71,
          height: 38,
          x: 1408,
          y: 2237,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "double_s2x",
          width: 71,
          height: 38,
          x: 1321,
          y: 2237,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "double_s2a",
          width: 71,
          height: 38,
          x: 1231,
          y: 2237,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "double_s1x",
          width: 71,
          height: 38,
          x: 1141,
          y: 2237,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "double_s1a",
          width: 71,
          height: 38,
          x: 1051,
          y: 2237,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_double_4",
          width: 338,
          height: 38,
          x: 695,
          y: 2275,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_double_3",
          width: 338,
          height: 38,
          x: 695,
          y: 2207,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_double_2",
          width: 305,
          height: 38,
          x: 264,
          y: 2276,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_double_1",
          width: 305,
          height: 38,
          x: 264,
          y: 2207,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_by_b",
          width: 305,
          height: 38,
          x: 265,
          y: 2419,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_cz_c",
          width: 305,
          height: 38,
          x: 264,
          y: 2488,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_bx_b",
          width: 305,
          height: 38,
          x: 265,
          y: 2554,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_az_a",
          width: 305,
          height: 38,
          x: 265,
          y: 2627,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_cy_c",
          width: 305,
          height: 38,
          x: 264,
          y: 2700,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_end_time",
          width: 437,
          height: 46,
          x: 630,
          y: 2819,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_win_score",
          width: 417,
          height: 38,
          x: 1909,
          y: 2903,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_abc_sign",
          width: 639,
          height: 80,
          x: 464,
          y: 3033,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_sign",
          width: 639,
          height: 80,
          x: 1688,
          y: 3042,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "referee_name_end",
          width: 639,
          height: 51,
          x: 533,
          y: 3151,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "referee_sign_end",
          width: 639,
          height: 80,
          x: 1620,
          y: 3138,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_win_by",
          width: 1295,
          height: 38,
          x: 455,
          y: 2903,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_ax_x",
          width: 338,
          height: 38,
          x: 695,
          y: 2349,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s1a",
          width: 71,
          height: 38,
          x: 1051,
          y: 2349,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s1x",
          width: 71,
          height: 38,
          x: 1142,
          y: 2349,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s2a",
          width: 71,
          height: 38,
          x: 1231,
          y: 2349,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s4a",
          width: 71,
          height: 38,
          x: 1586,
          y: 2349,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s4x",
          width: 71,
          height: 38,
          x: 1675,
          y: 2349,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s5a",
          width: 71,
          height: 38,
          x: 1765,
          y: 2349,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s5x",
          width: 71,
          height: 38,
          x: 1851,
          y: 2349,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_total_a",
          width: 71,
          height: 38,
          x: 1936,
          y: 2349,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_total_points_abc",
          width: 68,
          height: 38,
          x: 1939,
          y: 2770,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_total_points_xyz",
          width: 71,
          height: 38,
          x: 2020,
          y: 2770,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_total_x",
          width: 71,
          height: 38,
          x: 2021,
          y: 2349,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_win_a",
          width: 72,
          height: 38,
          x: 2124,
          y: 2349,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_win_x",
          width: 71,
          height: 38,
          x: 2241,
          y: 2349,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_total_win_abc",
          width: 75,
          height: 38,
          x: 2122,
          y: 2770,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_total_win_xyz",
          width: 72,
          height: 38,
          x: 2241,
          y: 2770,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s3x",
          width: 71,
          height: 38,
          x: 1498,
          y: 2349,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s3a",
          width: 71,
          height: 38,
          x: 1409,
          y: 2349,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "ax_s2x",
          width: 71,
          height: 38,
          x: 1320,
          y: 2349,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s1a",
          width: 71,
          height: 38,
          x: 1051,
          y: 2419,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s1x",
          width: 71,
          height: 38,
          x: 1141,
          y: 2419,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s2a",
          width: 71,
          height: 38,
          x: 1231,
          y: 2419,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s4a",
          width: 71,
          height: 38,
          x: 1586,
          y: 2419,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s4x",
          width: 71,
          height: 38,
          x: 1675,
          y: 2419,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s5a",
          width: 71,
          height: 38,
          x: 1765,
          y: 2419,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s5x",
          width: 71,
          height: 38,
          x: 1851,
          y: 2419,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_total_a",
          width: 71,
          height: 38,
          x: 1936,
          y: 2419,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_total_x",
          width: 71,
          height: 38,
          x: 2021,
          y: 2419,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_win_a",
          width: 72,
          height: 38,
          x: 2124,
          y: 2419,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_win_x",
          width: 71,
          height: 38,
          x: 2241,
          y: 2419,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s3x",
          width: 71,
          height: 38,
          x: 1498,
          y: 2419,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s3a",
          width: 71,
          height: 38,
          x: 1409,
          y: 2419,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "by_s2x",
          width: 71,
          height: 38,
          x: 1320,
          y: 2419,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s1a",
          width: 71,
          height: 38,
          x: 1051,
          y: 2489,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s1x",
          width: 71,
          height: 38,
          x: 1141,
          y: 2489,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s2a",
          width: 71,
          height: 38,
          x: 1231,
          y: 2489,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s4a",
          width: 71,
          height: 38,
          x: 1586,
          y: 2489,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s4x",
          width: 71,
          height: 38,
          x: 1675,
          y: 2489,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s5a",
          width: 71,
          height: 38,
          x: 1765,
          y: 2489,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s5x",
          width: 71,
          height: 38,
          x: 1851,
          y: 2489,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_total_a",
          width: 71,
          height: 38,
          x: 1936,
          y: 2489,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_total_x",
          width: 71,
          height: 38,
          x: 2021,
          y: 2489,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_win_a",
          width: 72,
          height: 38,
          x: 2124,
          y: 2489,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_win_x",
          width: 71,
          height: 38,
          x: 2241,
          y: 2489,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s3x",
          width: 71,
          height: 38,
          x: 1498,
          y: 2489,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s3a",
          width: 71,
          height: 38,
          x: 1409,
          y: 2489,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cz_s2x",
          width: 71,
          height: 38,
          x: 1320,
          y: 2489,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s1a",
          width: 71,
          height: 38,
          x: 1051,
          y: 2554,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s1x",
          width: 71,
          height: 38,
          x: 1141,
          y: 2554,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s2a",
          width: 71,
          height: 38,
          x: 1231,
          y: 2554,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s4a",
          width: 71,
          height: 38,
          x: 1586,
          y: 2554,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s4x",
          width: 71,
          height: 38,
          x: 1675,
          y: 2554,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s5a",
          width: 71,
          height: 38,
          x: 1765,
          y: 2554,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s5x",
          width: 71,
          height: 38,
          x: 1851,
          y: 2554,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_total_a",
          width: 71,
          height: 38,
          x: 1936,
          y: 2554,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_total_x",
          width: 71,
          height: 38,
          x: 2021,
          y: 2554,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_win_a",
          width: 72,
          height: 38,
          x: 2124,
          y: 2554,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_win_x",
          width: 71,
          height: 38,
          x: 2241,
          y: 2554,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s3x",
          width: 71,
          height: 38,
          x: 1498,
          y: 2554,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s3a",
          width: 71,
          height: 38,
          x: 1409,
          y: 2554,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "bx_s2x",
          width: 71,
          height: 38,
          x: 1320,
          y: 2554,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s1a",
          width: 71,
          height: 38,
          x: 1051,
          y: 2627,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s1x",
          width: 71,
          height: 38,
          x: 1141,
          y: 2627,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s2a",
          width: 71,
          height: 38,
          x: 1231,
          y: 2627,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s4a",
          width: 71,
          height: 38,
          x: 1586,
          y: 2627,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s4x",
          width: 71,
          height: 38,
          x: 1675,
          y: 2627,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s5a",
          width: 71,
          height: 38,
          x: 1765,
          y: 2627,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s5x",
          width: 71,
          height: 38,
          x: 1851,
          y: 2627,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_total_a",
          width: 71,
          height: 38,
          x: 1936,
          y: 2627,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_total_x",
          width: 71,
          height: 38,
          x: 2021,
          y: 2627,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_win_a",
          width: 72,
          height: 38,
          x: 2124,
          y: 2627,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_win_x",
          width: 71,
          height: 38,
          x: 2241,
          y: 2627,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s3x",
          width: 71,
          height: 38,
          x: 1498,
          y: 2627,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s3a",
          width: 71,
          height: 38,
          x: 1409,
          y: 2627,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "az_s2x",
          width: 71,
          height: 38,
          x: 1320,
          y: 2627,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s1a",
          width: 71,
          height: 38,
          x: 1051,
          y: 2700,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s1x",
          width: 71,
          height: 38,
          x: 1141,
          y: 2700,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s2a",
          width: 71,
          height: 38,
          x: 1231,
          y: 2700,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s4a",
          width: 71,
          height: 38,
          x: 1586,
          y: 2700,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s4x",
          width: 71,
          height: 38,
          x: 1675,
          y: 2700,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s5a",
          width: 71,
          height: 38,
          x: 1765,
          y: 2700,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s5x",
          width: 71,
          height: 38,
          x: 1851,
          y: 2700,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_total_a",
          width: 71,
          height: 38,
          x: 1936,
          y: 2700,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_total_x",
          width: 71,
          height: 38,
          x: 2021,
          y: 2700,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_win_a",
          width: 72,
          height: 38,
          x: 2124,
          y: 2700,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_win_x",
          width: 71,
          height: 38,
          x: 2241,
          y: 2700,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s3x",
          width: 71,
          height: 38,
          x: 1498,
          y: 2700,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s3a",
          width: 71,
          height: 38,
          x: 1409,
          y: 2700,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "cy_s2x",
          width: 71,
          height: 38,
          x: 1320,
          y: 2700,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_by_y",
          width: 338,
          height: 38,
          x: 695,
          y: 2419,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_cz_z",
          width: 338,
          height: 38,
          x: 695,
          y: 2488,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_bx_x",
          width: 338,
          height: 38,
          x: 695,
          y: 2554,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_az_z",
          width: 338,
          height: 38,
          x: 695,
          y: 2627,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "match_player_cy_y",
          width: 338,
          height: 38,
          x: 695,
          y: 2700,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_xyz_r1",
          width: 582,
          height: 34,
          x: 1499,
          y: 1701,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "player_xyz_r2",
          width: 582,
          height: 34,
          x: 1499,
          y: 1752,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_captain_name",
          width: 582,
          height: 34,
          x: 1499,
          y: 1801,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_coach_name",
          width: 582,
          height: 34,
          x: 1499,
          y: 1851,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_medic_name",
          width: 582,
          height: 34,
          x: 1499,
          y: 1900,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
        {
          type: "VECTOR",
          name: "team_xyz_manager_name",
          width: 582,
          height: 34,
          x: 1499,
          y: 1951,
          fills: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 1,
                g: 1,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokes: [
            {
              type: "SOLID",
              visible: true,
              opacity: 1,
              blendMode: "NORMAL",
              color: {
                r: 0,
                g: 0,
                b: 0,
              },
              boundVariables: {},
            },
          ],
          strokeWeight: 2,
          cornerRadius: 0,
        },
      ],
    },
  ],
  layoutMode: "NONE",
  primaryAxisSizingMode: "AUTO",
  counterAxisSizingMode: "FIXED",
  primaryAxisAlignItems: "MIN",
  counterAxisAlignItems: "MIN",
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: 0,
  itemSpacing: 0,
};

export const reportMiniSwaythlingDoubleKeys =
  miniSwaythlingDoubleJson.children[0].children.map((child) => child.name);

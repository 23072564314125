export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number
) {
  let timeoutId: number | null = null;

  const debouncedFunc = (...args: Parameters<T>) => {
    if (timeoutId) {
      window.clearTimeout(timeoutId);
    }

    timeoutId = window.setTimeout(() => {
      func.apply(null, args);
      timeoutId = null;
    }, wait);
  };

  debouncedFunc.cancel = () => {
    if (timeoutId) {
      window.clearTimeout(timeoutId);
      timeoutId = null;
    }
  };

  return debouncedFunc;
}

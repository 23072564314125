import { useNavigate } from "react-router-dom";
import { useI18n } from "../../../../lang/i18n";
import { DateUtils } from "../../../../libs/date";
import { Training } from "../../../../model/training";

export default function TrainingList({
  idClub,
  trainingList,
  isOwnerOrAdmin,
  handleDelete,
}: {
  idClub: string | undefined;
  trainingList: Training[];
  isOwnerOrAdmin?: boolean;
  handleDelete?: (trainingId: string) => void;
}) {
  const { t } = useI18n();
  const navigate = useNavigate();

  return (
    <div className="space-y-4">
      <table className="min-w-full divide-y ">
        <thead>
          <tr>
            <th className="px-3 sm:px-6 py-2 sm:py-3 text-left text-xs font-medium uppercase tracking-wider">
              {t("data.name")}
            </th>
            <th className="px-3 sm:px-6 py-2 sm:py-3 text-left text-xs font-medium uppercase tracking-wider">
              {t("data.date")}
            </th>
            <th className="hidden sm:table-cell px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
              {t("data.location")}
            </th>
            <th className="px-3 sm:px-6 py-2 sm:py-3 text-left text-xs font-medium uppercase tracking-wider">
              {t("data.participants")}
            </th>
            {isOwnerOrAdmin && (
              <th className="px-4 py-4 text-right text-sm font-medium"></th>
            )}
          </tr>
        </thead>
        <tbody className=" divide-y ">
          {trainingList.map((training) => {
            const participantsCount = training.joined_member_list?.length ?? 0;

            return (
              <tr
                key={training._id}
                className="hover:cursor-pointer"
                onClick={() =>
                  navigate(`/club/${idClub}/trainings/${training._id}`)
                }
              >
                <td className="px-3 sm:px-6 py-2 sm:py-4 text-sm w-[180px] sm:w-auto">
                  <div className="break-words whitespace-normal">
                    {training.name}
                  </div>
                </td>
                <td className="px-3 sm:px-6 py-2 sm:py-4 whitespace-nowrap text-sm">
                  <div>
                    {DateUtils.dateStringToLocaleDateString(
                      training.start_date
                    )}
                  </div>
                  <div className="text-gray-500">
                    {DateUtils.dateStringToLocaleTimeString(
                      training.start_date
                    )}
                    {" - "}
                    {DateUtils.dateStringToLocaleTimeString(training.end_date)}
                  </div>
                </td>
                <td className="hidden sm:table-cell px-6 py-4 whitespace-nowrap text-sm">
                  {training.place}
                </td>
                <td className="px-3 sm:px-6 py-2 sm:py-4 whitespace-nowrap text-sm">
                  {participantsCount}
                  {training.max_athletes && ` / ${training.max_athletes}`}
                </td>
                {isOwnerOrAdmin && (
                  <td className="px-4 py-4 text-right text-sm font-medium">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete!(training._id!);
                      }}
                      className="text-red-600 hover:text-red-900 p-2"
                    >
                      {t("general.delete")}
                    </button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

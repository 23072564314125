import { useLocation, useNavigate } from "react-router-dom";
import { useI18n } from "../../../lang/i18n";

function NavLinkSection({
  sectionId,
  children,
}: {
  sectionId: string;
  children: React.ReactNode;
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();

    // Check if we're on the root path or a language root path (e.g., /it, /en)
    const isRootPath =
      location.pathname === "/" || /^\/[a-z]{2}$/.test(location.pathname);

    if (isRootPath) {
      // If already on landing page or language root, just scroll
      const element = document.getElementById(sectionId);
      element?.scrollIntoView({ behavior: "smooth" });
    } else {
      // If on another page, navigate first then scroll
      navigate("/");
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        element?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };

  return (
    <a
      href={`#${sectionId}`}
      onClick={handleClick}
      className="text-gray-600 hover:text-primary-600"
    >
      {children}
    </a>
  );
}

export default function Footer() {
  const { t } = useI18n();

  return (
    <footer className="bg-gray-50 border-t border-gray-200">
      <div className="container mx-auto px-6 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Company Info */}
          <div className="col-span-1">
            <h3 className="text-xl font-bold mb-4">TT Club Manager</h3>
            <p className="text-gray-600">{t("landing.footer.description")}</p>
          </div>

          {/* Quick Links */}
          <div className="col-span-1">
            <h4 className="font-semibold mb-4">
              {t("landing.footer.quickLinks")}
            </h4>
            <div className="flex flex-col gap-2">
              <NavLinkSection sectionId="features">
                {t("landing.footer.features")}
              </NavLinkSection>
              <NavLinkSection sectionId="benefits">
                {t("landing.footer.benefits")}
              </NavLinkSection>
              <NavLinkSection sectionId="pricing">
                {t("landing.footer.pricing")}
              </NavLinkSection>
            </div>
          </div>

          {/* Legal */}
          <div className="col-span-1">
            <h4 className="font-semibold mb-4">{t("landing.footer.legal")}</h4>
            <div className="flex flex-col gap-2">
              <a
                href="/privacy"
                className="text-gray-600 hover:text-primary-600"
              >
                {t("landing.footer.privacy")}
              </a>
              <a href="/terms" className="text-gray-600 hover:text-primary-600">
                {t("landing.footer.terms")}
              </a>
            </div>
          </div>

          {/* Contact */}
          <div className="col-span-1">
            <h4 className="font-semibold mb-4">
              {t("landing.footer.contact")}
            </h4>
            <div className="flex flex-col gap-2">
              <a
                href="/contact"
                className="text-gray-600 hover:text-primary-600"
              >
                {t("landing.footer.contactUs")}
              </a>
              <NavLinkSection sectionId="faq">
                {t("landing.footer.faq")}
              </NavLinkSection>
            </div>
          </div>
        </div>

        <div className="mt-12 pt-8 border-t border-gray-200 text-center text-gray-600">
          <p>
            &copy; {new Date().getFullYear()} TT Club Manager.{" "}
            {t("landing.footer.rights")}
          </p>
        </div>
      </div>
    </footer>
  );
}

import { IconLoader2, IconRefresh, IconUpload } from "@tabler/icons-react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useClubIdController } from "../../../../controller/club-id-controller";
import { useMemberController } from "../../../../controller/member-controller";
import { useI18n } from "../../../../lang/i18n";
import { queryClient } from "../../../../libs/react-query";
import { UserRole } from "../../../../model/member";
import Visible from "../../component/visible";
import MemberList from "./component/member-list";
import ImportMembersPage from "./import-members-page";

export default function MemberListPage() {
  const { idClub } = useParams();
  const { t } = useI18n();

  const [showForm, setShowForm] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newMedicalExam, setNewMedicalExam] = useState("");
  const [newMembership, setNewMembership] = useState("");
  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [showImport, setShowImport] = useState(false);
  const [isAddingMember, setIsAddingMember] = useState(false);

  const memberController = useMemberController(idClub);
  const clubController = useClubIdController(idClub);

  const filteredMembers = memberController.memberList?.filter(
    (member) =>
      member.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (member.first_name?.toLowerCase() || "").includes(
        searchTerm.toLowerCase()
      ) ||
      (member.last_name?.toLowerCase() || "").includes(searchTerm.toLowerCase())
  );

  const isOwner = memberController.currentMember?.user_role_list.includes(
    UserRole.owner
  );

  const hasMedicalExams = memberController.memberList?.some(
    (member) => member.medical_exam_expiration_date
  );

  const hasMemberships = memberController.memberList?.some(
    (member) => member.membership_expiration_date
  );

  const showMedicalColumn = !!clubController.club?.enable_medical_expiration;
  const showMembershipColumn =
    !!clubController.club?.enable_membership_expiration;

  return (
    <div className="p-4 md:p-6 space-y-4 md:space-y-6 max-w-full overflow-x-hidden">
      <div className="flex justify-between items-center mb-4 md:mb-6">
        <h1 className="text-xl md:text-2xl font-bold">{t("members.title")}</h1>

        <Visible when={!!isOwner}>
          <div className="flex gap-4 items-center">
            <Link
              to={`/club/${idClub}/members/import`}
              onClick={() => {
                setShowImport(!showImport);
                if (showForm) setShowForm(false);
              }}
              className="bg-app-primary px-4 py-2 rounded-md flex items-center gap-2"
            >
              <IconUpload size={20} />
              {showImport ? t("general.cancel") : t("members.import")}
            </Link>

            <button
              onClick={() => queryClient.invalidateQueries(["memberList"])}
              className="p-1 hover:bg-gray-100 rounded-full"
            >
              <IconRefresh size={20} className="text-gray-600" />
            </button>

            <button
              onClick={() => {
                setShowForm(!showForm);
                if (showImport) setShowImport(false);
              }}
              className="bg-app-primary px-4 py-2 rounded-md"
            >
              {showForm ? t("general.cancel") : t("general.add")}
            </button>
          </div>
        </Visible>
      </div>

      <Visible when={!!isOwner && showImport}>
        <ImportMembersPage />
      </Visible>

      {/* Wrap the form in a conditional render */}
      {showForm && (
        <form className="space-y-3 md:space-y-4 mb-6 md:mb-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col gap-1.5 md:gap-2 col-span-2">
              <label htmlFor="email" className="font-medium">
                {t("general.email")} <span className="text-red-500">*</span>
              </label>
              <input
                id="email"
                type="email"
                value={newUserEmail}
                onChange={(e) => setNewUserEmail(e.target.value)}
                className="border rounded-md p-2 w-full"
                required
              />
            </div>

            <div className="flex flex-col gap-1.5 md:gap-2">
              <label htmlFor="name" className="font-medium">
                {t("members.name")}{" "}
                <span className="text-gray-500 text-sm">
                  ({t("general.optional-field")})
                </span>
              </label>
              <input
                id="name"
                type="text"
                value={newFirstName}
                onChange={(e) => setNewFirstName(e.target.value)}
                className="border rounded-md p-2 w-full"
              />
            </div>

            <div className="flex flex-col gap-1.5 md:gap-2">
              <label htmlFor="last-name" className="font-medium">
                {t("general.last-name")}{" "}
                <span className="text-gray-500 text-sm">
                  ({t("general.optional-field")})
                </span>
              </label>
              <input
                id="last-name"
                type="text"
                value={newLastName}
                onChange={(e) => setNewLastName(e.target.value)}
                className="border rounded-md p-2 w-full"
              />
            </div>

            <Visible when={showMedicalColumn}>
              <div className="flex flex-col gap-1.5 md:gap-2">
                <label
                  htmlFor="medical"
                  className="font-medium flex items-center gap-1"
                >
                  {t("members.medical-exam-expiration")}{" "}
                  <span className="text-gray-500 text-sm">
                    ({t("general.optional-field")})
                  </span>
                </label>
                <input
                  id="medical"
                  type="date"
                  value={newMedicalExam}
                  onChange={(e) => setNewMedicalExam(e.target.value)}
                  className="border rounded-md p-2 w-full"
                />
                <span className="text-gray-500 text-sm">
                  {t("members.medical-exam-helper")}
                </span>
              </div>
            </Visible>

            <Visible when={showMembershipColumn}>
              <div className="flex flex-col gap-1.5 md:gap-2">
                <label
                  htmlFor="membership"
                  className="font-medium flex items-center gap-1"
                >
                  {t("members.membership-expiration")}{" "}
                  <span className="text-gray-500 text-sm">
                    ({t("general.optional-field")})
                  </span>
                </label>
                <input
                  id="membership"
                  type="date"
                  value={newMembership}
                  onChange={(e) => setNewMembership(e.target.value)}
                  className="border rounded-md p-2 w-full"
                />
                <span className="text-gray-500 text-sm">
                  {t("members.membership-helper")}
                </span>
              </div>
            </Visible>
          </div>

          <button
            type="button"
            disabled={isAddingMember}
            onClick={async () => {
              try {
                setIsAddingMember(true);

                await memberController.post({
                  id_club: idClub,
                  email: newUserEmail,
                  first_name: newFirstName,
                  last_name: newLastName,
                  medical_exam_expiration_date: newMedicalExam,
                  membership_expiration_date: newMembership,
                });

                setNewUserEmail("");
                setNewFirstName("");
                setNewLastName("");
                setNewMedicalExam("");
                setNewMembership("");
                setShowForm(false);
              } finally {
                setIsAddingMember(false);
              }
            }}
            className="w-full md:w-auto bg-app-primary px-4 py-2 rounded-md flex items-center justify-center gap-2 disabled:opacity-70"
          >
            {isAddingMember ? (
              <IconLoader2 className="animate-spin-progress" size={20} />
            ) : (
              t("general.add")
            )}
          </button>

          <hr className="my-4" />
        </form>
      )}

      <div className="mb-4">
        <input
          type="text"
          placeholder={t("members.search")}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border rounded-md p-2 w-full md:w-64"
        />
      </div>

      <MemberList
        filteredMembers={filteredMembers ?? []}
        showMedicalColumn={hasMedicalExams}
        showMembershipColumn={hasMemberships}
      />
    </div>
  );
}

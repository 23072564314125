import { format, parseISO } from "date-fns";

export class DateUtils {
  static formatDateFromMs = (ms: number) => {
    try {
      const date = new Date(ms * 1000);

      const options = {
        day: "2-digit",
        month: "long",
        year: "numeric",
      } as const;

      return new Intl.DateTimeFormat(undefined, options).format(date);
    } catch (error) {
      return "error";
    }
  };

  static dateToLocaleDateString = (date: Date) => {
    const options = {
      day: "2-digit",
      month: "long",
      year: "numeric",
    } as const;

    return new Intl.DateTimeFormat(undefined, options).format(date);
  };

  static dateStringToLocaleDateString = (
    dateString: string | undefined | null
  ) => {
    try {
      if (!dateString) return "";

      const date = new Date(dateString);

      const options = {
        day: "2-digit",
        month: "long",
        year: "numeric",
      } as const;

      return new Intl.DateTimeFormat(undefined, options).format(date);
    } catch (error) {
      console.error(error);
      return dateString;
    }
  };

  static dateStringToLocaleDateTimeString = (
    dateString: string | undefined
  ) => {
    try {
      if (!dateString) return "";

      const date = new Date(dateString);

      const options = {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      } as const;

      return new Intl.DateTimeFormat(undefined, options).format(date);
    } catch (error) {
      console.error(error);
      return dateString;
    }
  };

  static dateStringToLocaleTimeString = (dateString: string | undefined) => {
    try {
      if (!dateString) return "";

      const date = new Date(dateString);

      const options = {
        hour: "2-digit",
        minute: "2-digit",
      } as const;

      return new Intl.DateTimeFormat(undefined, options).format(date);
    } catch (error) {
      console.error(error);
      return dateString;
    }
  };

  static dateForInput = (isoString: string | undefined | null) => {
    if (!isoString) {
      return "";
    }

    return format(parseISO(isoString), "yyyy-MM-dd");
  };
}

import { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { QueryClientProvider, useQuery } from "react-query";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import { userQueryKey } from "./controller/user-controller";
import { initOneSignal } from "./libs/onesignal";
import { queryClient } from "./libs/react-query";
import { userApi } from "./services/client";

const validLanguages = ["it", "en"];

function getBrowserLanguage() {
  const browserLang = navigator.language.split("-")[0];
  return validLanguages.includes(browserLang) ? browserLang : "en";
}

function isPrivacyPolicyPath(pathname: string) {
  return pathname === "/privacy-policy.html";
}

function isAcme(pathname: string) {
  return pathname.startsWith("/.well-known/acme-challenge/");
}

async function loadMessages(locale: string) {
  switch (locale) {
    case "it":
      return (await import("./lang/it")).default;
    case "en":
      return (await import("./lang/en")).default;
    default:
      return (await import("./lang/en")).default;
  }
}

const App: React.FC = () => {
  const { lang } = useParams<{ lang: string }>();
  const location = useLocation();

  const [messages, setMessages] = useState<any>();
  const [loading, setLoading] = useState(true);
  const defaultLang = getBrowserLanguage();

  useEffect(() => {
    const loadLocaleMessages = async () => {
      setLoading(true);
      try {
        const msgs = await loadMessages(lang || defaultLang);
        setMessages(msgs);
      } catch (error) {
        console.error("Failed to load messages:", error);
        const defaultMsgs = await loadMessages(defaultLang);
        setMessages(defaultMsgs);
      } finally {
        setLoading(false);
      }
    };

    loadLocaleMessages();
  }, [lang, defaultLang]);

  const anchor = location.hash.split("#")[1];
  const pathname = location.pathname;

  if (isAcme(pathname)) {
    return null;
  }

  if (isPrivacyPolicyPath(pathname)) {
    return <Outlet />;
  }

  if (!lang) {
    const newPath = `/${defaultLang}${pathname === "/" ? "" : pathname}${
      anchor ? `#${anchor}` : ""
    }`;
    return <Navigate to={newPath} replace />;
  }

  if (!validLanguages.includes(lang)) {
    const newPath = `/${defaultLang}${pathname.substring(lang.length + 1)}${
      anchor ? `#${anchor}` : ""
    }`;
    return <Navigate to={newPath} replace />;
  }

  if (loading) {
    return <div className="p-4"></div>;
  }

  return (
    <IntlProvider messages={messages} locale={lang}>
      <QueryClientProvider client={queryClient}>
        <OneSignalContainer>
          <div className="min-h-screen">
            <Outlet />
          </div>
        </OneSignalContainer>
      </QueryClientProvider>
    </IntlProvider>
  );
};

const OneSignalContainer = ({ children }: { children: React.ReactNode }) => {
  const { data: user } = useQuery({
    queryKey: userQueryKey,
    queryFn: () => userApi.getMe({ ignoreException: true }),
  });

  useEffect(() => {
    if (user?._id) {
      initOneSignal();
    }
  }, [user?._id]);

  return children;
};

export default App;

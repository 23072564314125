import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { config } from "../../config";
import { useI18n } from "../../lang/i18n";
import Benefit from "./components/benefit";
import Faq from "./components/faq";
import Feature from "./components/feature";
import Hero from "./components/hero";
import Pricing from "./components/pricing";
import SliderPictures from "./components/slider-pictures";

export default function Landing() {
  const { t } = useI18n();
  const params = useParams();

  let canonical;
  if (params.lang !== "en") {
    canonical = `https://${config.domainName}/${params.lang}`;
  } else {
    canonical = `https://${config.domainName}`;
  }

  return (
    <div className="container mx-auto px-6 pt-[120px] pb-24">
      <Helmet>
        <title>{t("landing.meta.title")}</title>
        <meta name="robots" content="index, follow" />
        <meta name="description" content={t("landing.meta.description")} />
        <meta name="keywords" content={t("landing.meta.keywords")} />
        <link rel="canonical" href={canonical} />
        {/* <meta property="og:title" content={i18n("landing.meta.og.title")} />
        <meta property="og:description" content={i18n("landing.meta.og.description")} />
        <meta property="og:image" content={i18n("landing.meta.og.image")} />
        <meta property="og:image:alt" content={i18n("landing.meta.og.image_alt")} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en" />
        <meta property="og:site_name" content="TT Club Manager" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" /> */}
      </Helmet>
      <Hero id="hero" />
      <Feature id="features" />
      <SliderPictures id="slider-pictures" />
      <Benefit id="benefits" />
      {/* <Case id="cases" /> */}
      {/* <Testimonial id="testimonials" /> */}
      {/* <Integration /> */}
      <Pricing id="pricing" />
      <Faq id="faq" />
    </div>
  );
}

import Logo from "./logo";

export default function Navbar() {
  return (
    <nav>
      <div className="container mx-auto py-4 px-4">
        <div className="flex items-center justify-between">
          <Logo />
        </div>
      </div>
    </nav>
  );
}

import { useEffect, useState } from "react";
import SignaturePad from "signature_pad";

type SignatureModalProps = {
  field: string;
  onSign: (field: string, url: string) => void;
  onRemove: (field: string) => void;
};

export const SignatureModal = ({
  field,
  onSign,
  onRemove,
}: SignatureModalProps) => {
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
  const [signaturePad, setSignaturePad] = useState<SignaturePad | null>(null);

  useEffect(() => {
    if (!canvas && !signaturePad) {
      const newCanvas = document.getElementById(
        "signature-pad"
      ) as HTMLCanvasElement;

      newCanvas.height = 100;

      const pad = new SignaturePad(newCanvas);

      setSignaturePad(pad);
      setCanvas(newCanvas);
    } else {
      signaturePad?.clear();
    }
  }, [signaturePad, canvas]);

  return (
    <dialog id="my_modal_2" className="modal">
      <div className="modal-box max-w-[642px] pt-0 pb-2">
        <div className="flex flex-col w-full gap-4 items-center">
          <canvas id="signature-pad" className="border" width={640}></canvas>

          <div className="flex flex-row gap-4 pb-2">
            <button
              onClick={() => {
                signaturePad?.clear();
              }}
            >
              Clear
            </button>

            <button
              onClick={() => {
                signaturePad?.clear();
                onRemove(field);
                (document.getElementById("my_modal_2") as any).close();
              }}
            >
              Remove
            </button>

            <button
              onClick={() => {
                const url = signaturePad?.toDataURL();

                onSign(field, url!);

                signaturePad?.clear();

                (document.getElementById("my_modal_2") as any).close();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
};

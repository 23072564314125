import { useState } from "react";
import { useQuery } from "react-query";
import { queryClient } from "../libs/react-query";
import { Report } from "../model/report";
import { reportApi } from "../services/client";

export const useReportIdController = (
  id_club: string | undefined,
  id: string | undefined
) => {
  const [updatedAt, setUpdatedAt] = useState(new Date());

  const { data: report } = useQuery<Report>({
    queryKey: ["report", id],
    queryFn: async () => {
      return await reportApi.getById(id_club!, id!);
    },
    onSuccess(data) {
      setUpdatedAt(new Date(data.updated_at));
    },
    enabled: !!id_club && !!id,
  });

  const save = async (report: Partial<Report>) => {
    await reportApi.patch(id_club!, id!, report);
    setUpdatedAt(new Date());

    queryClient.resetQueries(["report-list"]);
  };

  const delete_ = async () => {
    await reportApi.delete(id_club!, id!);
    setUpdatedAt(new Date());

    queryClient.resetQueries(["report-list"]);
  };

  return {
    save,
    report,
    updatedAt,
    delete: delete_,
  };
};

import { News } from "../model/news";
import { Client } from "./client";

export class NewsApi {
  private client: Client;

  constructor(client: Client) {
    this.client = client;
  }

  async post(
    title: string,
    description: string,
    id_club: string
  ): Promise<News> {
    const response = await this.client.fetch(`/club/${id_club}/news`, {
      method: "POST",
      body: JSON.stringify({ title, description }),
    });

    const json = await response.json();

    return json.data as News;
  }

  async getByClubId(id_club: string): Promise<News[]> {
    const response = await this.client.fetch(`/club/${id_club}/news`);

    const json = await response.json();

    return json.data as News[];
  }

  async deleteNews(id_club: string, id_news: string): Promise<void> {
    await this.client.fetch(`/club/${id_club}/news/${id_news}`, {
      method: "DELETE",
    });
  }
}
